import React, { FC } from 'react';
import { Stack, Text, Heading, useColorMode, Box } from '@chakra-ui/react';

type Props = {
	icon: any;
	title: string;
	text: string;
};

const OfferCard: FC<Props> = ({ icon, title, text }) => {
	const { colorMode } = useColorMode();
	return (
		<Stack
			spacing="20px"
			w="100%"
			p={{
				base: '35px',
				sm: '35px 20px 20px 30px',
				md: '35px 30px 35px 50px',
				lg: '30px 20px 20px 35px',
			}}
			minH={{ base: '285px', sm: '328px', md: '348px' }}
			borderRadius="30px"
			fontFamily="bold"
			transition="0.3s ease"
			_hover={{
				boxShadow: '0px 4px 50px rgba(0, 0, 0, 0.15)',
			}}
			bgColor={colorMode === 'light' ? '#FCF3FC' : 'darkBg'}
		>
			<Box>{icon}</Box>

			<Heading as="h4" fontSize="20px" fontWeight="700">
				{title}
			</Heading>
			<Text
				lineHeight="24px"
				maxW="268px"
				color={colorMode === 'light' ? 'black' : 'darkGray'}
			>
				{text}
			</Text>
		</Stack>
	);
};

export default OfferCard;
