import { FC } from 'react';
import { Box, Container, Flex, Heading, useColorMode } from '@chakra-ui/react';
import ReachOutImg from '../../assets/image/about/reach-out.webp';
import darkNewsletterImg from '../../assets/image/home/darkmode/dark-newsletter.webp';
import CommonBtn from 'components/common/CommonBtn';
import { Link } from 'react-router-dom';

const ReachOut: FC = () => {
	const { colorMode } = useColorMode();
	return (
		<Box bgColor={colorMode === 'light' ? 'none' : '#000'}>
			<Container
				maxW="1440px"
				px={{ base: '20px', sm: '50px', md: '72px' }}
				pt={{ base: '35px', sm: '50px', md: '74px' }}
			>
				<Flex
					flexDir="column"
					justify="center"
					align="center"
					borderRadius="40px"
					minHeight={{ base: '450px', md: '591px' }}
					textAlign="center"
					fontFamily="bold"
					px="20px"
					bgImage={colorMode === 'light' ? ReachOutImg : darkNewsletterImg}
					bgPos="center"
					bgSize="cover"
					bgRepeat="no-repeat"
				>
					<Heading
						fontSize={{ base: '14px', sm: '16px' }}
						fontWeight="700"
						letterSpacing="4px"
						color="#7ED8FF"
					>
						REACH OUT
					</Heading>
					<Heading
						fontSize={{ base: '24px', sm: '32px', md: '40px', lg: '48px' }}
						fontWeight="700"
						textAlign="center"
						lineHeight={{ base: '40px', md: '52px', lg: '60px' }}
						maxW="636px"
						color="white"
						pt={{ base: '33px', md: '50px' }}
						pb={{ base: '70px', md: '50px' }}
					>
						Are you interested in learning more about who we are and what we do?
					</Heading>
					<Box>
						<Link to="/contact">
							<CommonBtn
								text={'Contact Us'}
								width={{ base: '167px', sm: '168px' }}
								height={'60px'}
								color={'#6C4CDF'}
								bg={'white'}
							/>
						</Link>
					</Box>
				</Flex>
			</Container>
		</Box>
	);
};

export default ReachOut;
