import { FC, useState } from 'react';
import { VStack, Text, Image, Heading, useColorMode } from '@chakra-ui/react';

type Props = {
	icon: string;
	title: string;
	text: string;
	h: any;
	mb?: Record<string, string>;
	isActive: boolean;
};

const ValuesCard: FC<Props> = ({ icon, title, text, mb, h, isActive }) => {
	const { colorMode } = useColorMode();

	const [hover, setHover] = useState(false);

	const handleMouseEnter = () => {
		setHover(true);
	};
	const handleMouseLeave = () => {
		setHover(false);
	};
	return (
		<VStack
			spacing="27px"
			w="100%"
			p="35px"
			mb={mb}
			// minH={{ base: '396px', md: '492px' }}
			borderRadius="30px"
			textAlign="center"
			fontFamily="bold"
			transition="0.5s ease"
			_hover={{
				transform: 'scale(1.02)',
				boxShadow: '0px 4px 50px rgba(0, 0, 0, 0.15)',
				minH: isActive ? h : '100%',
			}}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			bgColor={colorMode === 'light' ? '#FCF3FC' : 'darkBg'}
		>
			<Image src={icon} alt="logo" w="100px" />

			<Heading
				as="h4"
				fontSize={{ base: '24px', lg: '32px' }}
				fontWeight="700"
				textAlign="center"
			>
				{title}
			</Heading>
			<Text
				fontSize={{ base: '16px', lg: '20px' }}
				lineHeight={{ base: '25px', lg: '30px' }}
				maxW={{ base: '320px', md: '268px' }}
				textAlign="center"
				color={colorMode === 'light' ? 'black' : 'darkGray'}
				overflow={hover ? 'unset' : 'hidden'}
				textOverflow={hover ? 'unset' : 'ellipsis'}
				sx={{
					display: '-webkit-box',
					['WebkitBoxOrient' as any]: 'vertical',
					['WebkitLineClamp' as any]: hover ? 'none' : '4',
				}}
			>
				{text}
			</Text>
		</VStack>
	);
};

export default ValuesCard;
