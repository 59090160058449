/* eslint-disable prettier/prettier */

import { Box, Heading, Image, useColorMode, Flex } from '@chakra-ui/react';
import React, { FC } from 'react';

interface Props {
	title: string;
	img: string;
	desc: string;
	bg: any;
}

const PortfolioCard: FC<Props> = ({ title, bg, img, desc }) => {
	const { colorMode } = useColorMode();
	return (
		<Box>
			<Flex
				flexDir="column"
				justify="center"
				align="center"
				bgColor={bg}
				w="100%"
				minH={{ base: '20rem', md: '31rem' }}
				pos="relative"
				borderRadius={'20px'}
				p="20px"
				mb={'30px'}
			>
				<Heading
					as="h2"
					mt={{ base: '0', md: '10px' }}
					fontWeight={700}
					textAlign={{ base: 'center', md: 'unset' }}
					fontSize={{ base: '18px', sm: '24px' }}
					letterSpacing={'3px'}
					color="white"
				>
					{title}
				</Heading>
				<Box mx="auto">
					<Image src={img} w="550px" objectFit="cover" alt="client" />
				</Box>
			</Flex>

			<Box
				color={colorMode === 'light' ? 'gray' : 'white'}
				maxW={'650px'}
				fontFamily="bold"
				lineHeight="24px"
				fontWeight="400"
				pb={{ base: '30px', md: '0' }}
				dangerouslySetInnerHTML={{
					__html: desc,
				}}
			></Box>
		</Box>
	);
};

export default PortfolioCard;
