import {
	Box,
	Container,
	Flex,
	HStack,
	Icon,
	Image,
	Link,
	Stack,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	useColorMode,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import overviewImg from '../../assets/image/programme/overview.webp';
import { BiCheckDouble } from 'react-icons/bi';
import avatar from '../../assets/image/programme/mark.webp';
import { LinkedIn } from 'assets/svgs/programme';

const Overview: FC = () => {
	const { colorMode } = useColorMode();
	return (
		<Box bgColor={colorMode === 'light' ? 'white' : '#000'} position="relative">
			<Container
				maxW="1440px"
				px={{ base: '20px', sm: '50px', md: '69px' }}
				pt={{ base: '35px', sm: '48px', md: '78px' }}
				pb={{ base: '0', md: '64px', lg: '92px' }}
				fontFamily="bold"
			>
				<Box pb={{ base: '61.5px', md: '97px' }}>
					<Image
						src={overviewImg}
						alt="programme"
						borderRadius={{ base: '30px', md: '40px' }}
						objectFit="cover"
						h={{ base: '255px', sm: '296px', md: '458px' }}
					/>
				</Box>
				<Stack
					px={{ base: '0', md: '55px', lg: '113px' }}
					alignItems="flex-start"
					justify="flex-start"
				>
					<Text
						fontFamily="bold"
						fontWeight="500"
						fontSize={{ base: '20px', md: '24px' }}
						color={colorMode === 'light' ? 'black' : 'white'}
						pb={{ base: '30px', md: '25px' }}
					>
						Programme Overview
					</Text>
					<Text
						fontFamily="bold"
						lineHeight={{ base: '33px', md: '40px', lg: '43.31px' }}
						fontWeight="700"
						fontSize={{ base: '23px', md: '32px', lg: '36px' }}
						color={colorMode === 'light' ? 'black' : 'white'}
						width="100%"
						// maxWidth={{ base: "390px", md: "1078px" }}
						pb={{ base: '41px', lg: '72px' }}
					>
						<Text
							lineHeight={{ base: '33px', md: '40px', lg: '43.31px' }}
							as="span"
							color="purple"
							fontWeight="700"
							fontSize={{ base: '23px', md: '32px', lg: '36px' }}
						>
							TechElite {''}
						</Text>
						is a software engineering programme designed for experienced
						software engineers who are interested in advancing their skills and
						knowledge in the field.
					</Text>
					<Flex
						justifyContent={{ base: 'center', md: 'space-between' }}
						gap={{ base: '0', md: '60px', lg: '85px' }}
						pb={{ base: '61px', md: '135px' }}
						flexDir={{ base: 'column', md: 'row' }}
					>
						<Box w={{ base: '100%', md: '50%' }} fontFamily="bold">
							<Text
								lineHeight="30px"
								fontSize={{ base: '16px', md: '20px' }}
								color={colorMode === 'light' ? 'black' : 'darkGray'}
								width="100%"
								maxWidth={{ base: '100%', md: '490px' }}
							>
								The programme covers a wide range of topics, including
								Functional Programming, Domain-Driven Design (DDD), software
								architecture and patterns, Behaviour-Driven Development (BDD),
								Test-Driven Development (TDD), Walking Skeletons, software
								design, and more.
							</Text>
							<br />

							<Text
								lineHeight="30px"
								fontSize={{ base: '16px', md: '20px' }}
								color={colorMode === 'light' ? 'black' : 'darkGray'}
								width="100%"
								maxWidth={{ base: '100%', md: '490px' }}
							>
								{' '}
								Throughout the programme, participants will engage in hands-on
								exercises and real-world projects to apply the concepts they are
								learning, gearing them up to be able to tackle international
								projects. The programme also includes a final project, which
								allows participants to showcase the skills and knowledge they
								have gained throughout the programme.
							</Text>
						</Box>

						<Box w={{ base: '100%', md: '50%' }} fontFamily="bold">
							<Text
								lineHeight="30px"
								fontSize={{ base: '16px', md: '20px' }}
								color={colorMode === 'light' ? 'black' : 'darkGray'}
								width="100%"
								maxWidth={{ base: '100%', md: '490px' }}
							>
								The programme is designed to be both comprehensive and
								practical, ensuring that participants gain a deep understanding
								of the topics covered. The instructors are experienced software
								engineers and supported by industry experts who are passionate
								about sharing their knowledge and experience. They will provide
								guidance and support throughout the programme to help
								participants achieve their goals.
							</Text>
							<br />
							<Text
								lineHeight="30px"
								fontSize={{ base: '16px', md: '20px' }}
								color={colorMode === 'light' ? 'black' : 'darkGray'}
								width="100%"
								maxWidth={{ base: '100%', md: '490px' }}
							>
								{' '}
								Overall, TechElite is a great opportunity for experienced
								software engineers to expand their skills and knowledge in the
								field. The programme's focus on practical application and the
								support provided by experienced instructors make it an ideal
								choice for anyone looking to take their software engineering
								skills to the next level.
							</Text>
						</Box>
					</Flex>
				</Stack>

				<Tabs
					px={{ base: '0', md: '35px', lg: '113px' }}
					pb={{ base: '47px', md: '81px', lg: '124px' }}
					defaultIndex={0}
					isLazy
				>
					<TabList color="#CBD5E1">
						<Tab
							fontSize={{ base: '14px', md: '22px' }}
							pb="24px"
							px={{ base: '3', md: '10' }}
							color={colorMode === 'light' ? 'gray' : 'white'}
							_selected={{ borderBottom: '3px solid #6C4CDF' }}
						>
							What's Included
						</Tab>
						<Tab
							fontSize={{ base: '14px', md: '22px' }}
							pb="24px"
							px={{ base: '3', md: '10' }}
							color={colorMode === 'light' ? 'gray' : 'white'}
							_selected={{ borderBottom: '3px solid #6C4CDF' }}
						>
							What You'll Learn
						</Tab>
						<Tab
							fontSize={{ base: '14px', md: '22px' }}
							pb="24px"
							px={{ base: '3', md: '10' }}
							color={colorMode === 'light' ? 'gray' : 'white'}
							_selected={{ borderBottom: '3px solid #6C4CDF' }}
						>
							Prerequisities
						</Tab>
					</TabList>

					<TabPanels>
						<TabPanel pt={{ base: '37px', md: '50px' }}>
							<Text
								fontFamily="bold"
								lineHeight="1.5"
								fontWeight="400"
								pb="18px"
								fontSize="16px"
								maxWidth="490px"
							>
								<Text as="span" color="purple" fontWeight={600}>
									{' '}
									TechElite{' '}
								</Text>
								isn't just another training, this has the potential to open the
								doors to further benefits too:
							</Text>
							<Stack>
								<Flex gap="20px">
									<Icon w="8" as={BiCheckDouble} color="purple" />
									<Text maxWidth="684px">
										Group sessions with recruiters, HR and engineers in the UK
									</Text>
								</Flex>
								<Flex gap="20px">
									<Icon w="8" as={BiCheckDouble} color="purple" />
									<Text maxWidth="684px">
										Career advice to get jobs with UK organisations
									</Text>
								</Flex>
								<Flex gap="20px">
									<Icon w="8" as={BiCheckDouble} color="purple" />
									<Text maxWidth="684px">
										Potential 1-month internship in the UK
									</Text>
								</Flex>
								<Flex gap="20px">
									<Icon w="8" as={BiCheckDouble} color="purple" />
									<Text maxWidth="684px">
										{' '}
										Assistance with your UK relocation
									</Text>
								</Flex>
								<Flex gap="20px">
									<Icon w="8" as={BiCheckDouble} color="purple" />
									<Text maxWidth="684px">
										{' '}
										Discounts on future workshops and courses
									</Text>
								</Flex>
							</Stack>
						</TabPanel>

						<TabPanel pt="50px">
							<Text
								fontFamily="bold"
								lineHeight="1.5"
								fontWeight="400"
								pb="18px"
								fontSize="16px"
								maxWidth="684px"
							>
								The programme is designed to be comprehensive and practical,
								ensuring that participants gain a deep understanding of the
								topics covered and how to apply them in real-world scenarios.
								<br /> Some of the compelling parts of the programme include:
							</Text>
							<Stack>
								<Flex gap="20px">
									<Icon w="8" as={BiCheckDouble} color="purple" />
									<Text maxWidth="684px">
										Domain-Driven Design (DDD): Participants will learn how to
										use DDD to model complex business domains and to create
										software that is aligned with the business needs.
									</Text>
								</Flex>
								<Flex gap="20px">
									<Icon w="8" as={BiCheckDouble} color="purple" />
									<Text maxWidth="684px">
										Software Architecture and Patterns: Participants will learn
										about the different software architecture patterns such as
										Model-View-Controller (MVC), Pipe and Filter, and
										Microservices, and how to use them to design and build
										maintainable and scalable software systems.
									</Text>
								</Flex>
								<Flex gap="20px">
									<Icon w="8" as={BiCheckDouble} color="purple" />
									<Text maxWidth="684px">
										Behavior-Driven Development (BDD) and Test-Driven
										Development (TDD): Participants will learn how to use BDD
										and TDD to design and test software in a way that is aligned
										with the business requirements and that ensures that the
										software works as expected.
									</Text>
								</Flex>
								<Text maxWidth="684px">
									By the end, you will also learn how to use Agile collaboration
									and communication tools and practices such as pair programming
									and code review to improve the efficiency and quality of the
									software development process.
								</Text>
							</Stack>
						</TabPanel>

						<TabPanel pt="50px">
							<Stack>
								<Flex gap="20px">
									<Icon w="8" as={BiCheckDouble} color="purple" />
									<Text maxWidth="684px">
										{' '}
										Participants must have a functioning laptop to participate.
									</Text>
								</Flex>
								<Flex gap="20px">
									<Icon w="8" as={BiCheckDouble} color="purple" />
									<Text maxWidth="684px">
										{' '}
										Participants should have a GitHub Account.
									</Text>
								</Flex>
								<Flex gap="20px">
									<Icon w="8" as={BiCheckDouble} color="purple" />
									<Text maxWidth="684px">
										{' '}
										Participants should ensure they can attend 3 hours of
										lectures each week.
									</Text>
								</Flex>
								<Flex gap="20px">
									<Icon w="8" as={BiCheckDouble} color="purple" />
									<Text maxWidth="684px">
										{' '}
										We will provide further instructions.
									</Text>
								</Flex>
							</Stack>
						</TabPanel>
					</TabPanels>
				</Tabs>

				<Stack px={{ base: '0', md: '35px', lg: '113px' }}>
					<Text
						fontFamily="bold"
						lineHeight="1.5"
						fontWeight="700"
						fontSize="24px"
						pb={{ base: '37px', md: '55px' }}
						color={colorMode === 'light' ? 'black' : 'white'}
					>
						About the Tutor
					</Text>
					<Stack
						direction="row"
						justify="flex-start"
						align="center"
						spacing="10px"
						pb="35px"
					>
						<Image src={avatar} w="74px" alt="mark" />
						<Text
							fontFamily="bold"
							fontWeight="400"
							color={colorMode === 'light' ? 'black' : 'white'}
						>
							Mark Gray
						</Text>
					</Stack>
					<Flex
						flexDir={{ base: 'column', md: 'row' }}
						justify={{ base: 'flex-start', md: 'space-between' }}
					>
						<Text
							fontFamily="bold"
							lineHeight="24px"
							fontWeight="400"
							pb={{ base: '30px', md: '0' }}
							maxW="779px"
							color={colorMode === 'light' ? 'black' : 'white'}
						>
							Mark Gray has been in the software industry for over 20 years and
							is the Director of Phasecurve. <br />
							He has worked in various sectors producing software, from media
							companies to foreign exchange brokers. <br />
							He enjoys training and mentoring professionals in the field and
							tutors university students in the UK.
						</Text>

						<HStack
							bgColor="#F8FAFC"
							borderRadius="100px"
							p="15px"
							maxW="149px"
							h="60px"
						>
							<Link isExternal href="https://www.linkedin.com/in/markgray79/">
								<Flex
									align="center"
									p="18px"
									_hover={{
										textDecorationLine: '0px',
										boxShadow: '0px 92px 40px -59px rgba(108, 76, 223, 0.15)',
										transform: 'scale(1.08)',
									}}
									transition="0.5 ease"
									color="purple"
								>
									<Box mr="10px">{LinkedIn}</Box>
									<Text fontFamily="bold" lineHeight="24px" fontWeight="700">
										Linkedin
									</Text>
								</Flex>
							</Link>
						</HStack>
					</Flex>
				</Stack>
			</Container>
		</Box>
	);
};

export default Overview;
