/* eslint-disable prettier/prettier */

import { Box } from "@chakra-ui/react";
import Intro from "components/careers/Intro";
import Newsletter from "components/careers/Newsletter";
import NoVacancy from "components/careers/NoVacancy";
import Overview from "components/careers/Overview";
// import Vacancies from "components/careers/Vacancies";
import React from "react";


const Careers = () => {

  return (
    <Box>
     <Intro/>
     <Overview/>
     {/* <Vacancies /> */}
     <NoVacancy/>
     <Newsletter/>
    </Box>
  )
}

export default Careers;