import {
	Box,
	Container,
	Flex,
	Text,
	Heading,
	useColorMode,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import hero from '../../assets/image/home/hero.png';
import { Stribe } from './../../assets/svgs/community';
import ExploreEvents from './ExploreEvents';
import { Bounce } from 'react-reveal';

const Intro: FC = () => {
	const { colorMode } = useColorMode();
	return (
		<Flex
			flexDir="column"
			justify="center"
			align="center"
			bgColor={colorMode === 'light' ? 'none' : '#000'}
			bgImage={colorMode === 'light' ? hero : 'none'}
			bgPos={{ base: 'bottom', md: 'inherit' }}
			w="full"
			h="full"
			pos="relative"
		>
			<Container maxW="1440px" pt="91px" pb={{ base: '50gpx', lg: '95px' }}>
				<Flex
					flexDir="column"
					align="center"
					h="full"
					justify="center"
					fontFamily="bold"
				>
					<Text
						as="p"
						pb={{ base: '30px', sm: '41px' }}
						fontWeight="700"
						letterSpacing="3px"
						textAlign="center"
						color={colorMode === 'light' ? '#94A3B8' : 'darkGray'}
					>
						COMMUNITY
					</Text>
					<Bounce duration="2500">
						<Heading
							textAlign="center"
							maxW="604px"
							as="h2"
							fontSize={{ base: '32px', md: '48px' }}
							lineHeight={{ base: '42px', md: '60.54px' }}
							fontWeight="700"
							pb="29px"
							color={colorMode === 'light' ? '#000' : 'white'}
						>
							Our ever-growing Phasecurve community.
						</Heading>
					</Bounce>

					<Text
						fontWeight="400"
						fontSize={{ base: '16px', md: '1.1rem' }}
						maxW="405px"
						textAlign="center"
						color={colorMode === 'light' ? 'gray' : 'darkGray'}
					>
						Equipping tech enthusiasts to become highly skilled people that
						profoundly impact the digital space.
					</Text>
				</Flex>
				<Box
					display={{ base: 'none', md: 'unset' }}
					position="absolute"
					top="0px"
					left={0}
				>
					{Stribe}
				</Box>
			</Container>
			<Box>
				<ExploreEvents />
			</Box>
		</Flex>
	);
};

export default Intro;
