import { Button, HStack, Icon, Text, ButtonProps } from '@chakra-ui/react';
import { FC } from 'react';
import { RxCaretRight } from 'react-icons/rx';

type Props = {
	text: string;
	height: string;
	color: string;
	bg: string;
	type?: string;
} & ButtonProps;
const CustomBtn: FC<Props> = ({
	text,
	width,
	height,
	color,
	bg,
	type,
	...rest
}) => {
	return (
		<Button
			type={type}
			bg={bg}
			borderRadius="100px"
			fontSize={{ base: '12px', md: '14px', lg: '16px' }}
			fontFamily="light"
			color={color}
			zIndex={5}
			h={height}
			_hover={{
				cursor: 'pointer',
				boxShadow: '0px 92px 40px -59px rgba(108, 76, 223, 0.15)',
				transform: 'scale(1.03)',
			}}
			{...rest}
		>
			<HStack spacing="10px">
				<Text>{text}</Text>
				<Icon as={RxCaretRight} boxSize={5} />
			</HStack>
		</Button>
	);
};

export default CustomBtn;
