import React, { FC } from 'react';
import { Box, Container, Grid, GridItem, useColorMode } from '@chakra-ui/react';
import TrainingCard from './TrainingCard';

const SignUp: FC = () => {
	const { colorMode } = useColorMode();
	return (
		<Box bgColor={colorMode === 'light' ? 'white' : '#000'} position="relative">
			<Container
				maxW="1440px"
				p={{
					base: '36px 20px 54px',
					sm: '56px 50px 64px',
					md: '96px 101px 114px',
					lg: '102px 182px 134px',
				}}
			>
				<Grid
					templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
					alignItems="center"
					justifyContent="center"
					gap={15}
				>
					<GridItem
						w="100%"
						display={{ base: 'flex', md: 'grid' }}
						alignItems="center"
						justifyContent="center"
					>
						<TrainingCard
							title={'16 Hrs'}
							trainingColor={'gray'}
							height={'573px'}
							bgColor={'#F8FAFC'}
							hrsColor={'black'}
							courseColor={'black'}
							iconColor={'purple'}
							btnBg={'purple'}
							btnColor={'white'}
							borderRadius={'40px'}
							duration="16"
						/>
					</GridItem>
					<GridItem
						w="100%"
						display={{ base: 'flex', md: 'grid' }}
						alignItems="center"
						justifyContent="center"
					>
						<TrainingCard
							title={'60 Hrs'}
							trainingColor={'white'}
							height={'623px'}
							bgGradient={'linear(337.31deg, #2F314E 14.54%, #6C4CDF 46.47%)'}
							hrsColor={'white'}
							courseColor={'white'}
							iconColor={'sky'}
							btnBg={'white'}
							btnColor={'purple'}
							borderRadius={'20px'}
							duration="60"
						/>
					</GridItem>
					<GridItem
						w="100%"
						display={{ base: 'flex', md: 'grid' }}
						alignItems="center"
						justifyContent="center"
					>
						<TrainingCard
							title={'Bespoke'}
							trainingColor={'gray'}
							height={'573px'}
							bgColor={'#F8FAFC'}
							hrsColor={'black'}
							courseColor={'black'}
							iconColor={'purple'}
							btnBg={'purple'}
							btnColor={'white'}
							borderRadius={'40px'}
							duration="bespoke"
						/>
					</GridItem>
				</Grid>
			</Container>
		</Box>
	);
};

export default SignUp;
