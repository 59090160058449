import { extendTheme, type ThemeConfig } from '@chakra-ui/react';
import styles from './global';

const config: ThemeConfig = {
	initialColorMode: 'light',
	useSystemColorMode: false,
};
const breakpoints = { sm: '608px', md: '998px', lg: '1200px', xl: '1520px' };
export default extendTheme({
	styles,
	colors: {
		transparent: 'transparent',
		black: '#000',
		white: '#fff',
		gray: '#64748B',
		darkGray: '#A3A3A3',
		darkBg: '#222222',
		purple: '#6C4CDF',
		sky: '#7ED8FF',
	},
	fonts: {
		bold: `'bold', sans-serif`,
		boldIt: `'boldIt', sans-serif`,
		thin: `'thin', sans-serif`,
		light: `'light', sans-serif`,
		regular: `'regular', sans-serif`,
		blak: `'blak', sans-serif`,
		medium: `'medium', sans-serif`,
	},
	config,
	breakpoints,
});
