import card1 from '../assets/image/events/card1.webp';
import card2 from '../assets/image/events/card2.webp';
import card3 from '../assets/image/events/card3.webp';
import card4 from '../assets/image/events/card4.webp';
import singleEvent from '../assets/image/events/singleEventsCard.webp';

export const EventList = [
	{
		id: '1',
		title: 'TechElite',
		bgImg: card1,
		heroBanner: singleEvent,
		summary1:
			'TechElite is a 5-month advanced software engineering programme designed for experienced software engineers looking to improve their skills and knowledge in software development. It offers a unique opportunity of a 1-month internship in the UK, with a possible fully-funded relocation, including visa assistance, accommodation, flight, and job. The programme covers a wide range of topics that will help participants develop their skills and knowledge in software development. It includes Domain-Driven Design (DDD) which will help participants to model complex business domains and create software that is aligned with business needs.',
		summary2:
			'Participants will also learn about software Architecture and patterns, which will help them to design and build maintainable and scalable software systems. Additionally, they will learn about Behavior-Driven Development (BDD) and Test-Driven Development (TDD) which will help them to design and test software in a way that is aligned with the business requirements and ensure that the software works as expected.',
		summary3:
			'The programme also includes a final project, where participants will have the opportunity to apply the skills and knowledge gained through the program to a real-world software development project and receive feedback and guidance from experienced software engineers.',
		summary4:
			' Overall, TechElite offers a unique opportunity for experienced software engineers to improve their skills and knowledge in software development, and to gain valuable international experience in the UK.',
		cardTextColor: 'white',
		date: 'Mon 19 Jun 2023',
		titleColor: 'sky',
		desc: 'An Advanced Software Engineering Programme',
		descColor: 'white',
		linkText: 'Learn More',
	},
	{
		id: '2',
		title: 'Your Passport to Tech',
		bgImg: card2,
		cardTextColor: 'black',
		date: 'Tues 30 May 2023',
		titleColor: 'purple',
		desc: 'Start your own journey into the world of tech',
		descColor: 'black',
		linkText: 'Learn More',
		summary1: 'Join us for an incredible start to your tech journey!',
		summary2: `<p>Explore the fascinating realms of web development and data science, and embark on a path towards limitless opportunities with our beginner-friendly tech training program.</p><br />
<p>From HTML to CSS to data analysis and Python, our seasoned instructors <strong>Fortune Ellah</strong> and <strong>Rofiat Hassan</strong> have got you covered!</p>`,
		summary3: `<p>There are only 30 seats available, so be sure to sign up and don't miss out. There would be room for networking, not to mention internship opportunities. What a way to kick-start your career!</p><br />
<p>Refreshments will be provided during the event.</p><br />
<p>To secure your place, please make a commitment fee payment of <strong>₦2500</strong>.<br /><br />You can complete your payment by clicking on the button below:</p><br />
<p><a target="_blank" href="https://paystack.com/pay/phasecurve-event" style="margin: 10px auto;color: white; padding: 7px 15px 7px; background-color:#6C4CDF; border-radius: 7px;max-width: max-content; display: block;">Save Your Spot</a></p>`,
		summary4: `<em>Note: Once you have made the payment, please save your spot on <a style="text-decoration: underline; color: #6c4cdf; font-weight: bold" href="https://www.meetup.com/phasecurve-tech-community/events/293488152/" target="_blank">Meetup.com</a>. This will guarantee your attendance at the event.</em>`,
	},
	{
		id: '3',
		title: 'Your Passport to Tech',
		bgImg: card3,
		cardTextColor: 'black',
		date: 'Tues 27 Jun 2023',
		titleColor: 'purple',
		desc: 'Continue your own journey into the world of tech',
		descColor: 'black',
		linkText: 'Learn More',
		summary1:
			'If you want to know how to get on the tech train, come and join us for eventful sessions exposing you to life as a web dev or data scientist.',
		summary2:
			'Have a chance to learn how you can get one foot into tech with sessions by Fortune Ellah on front end web development and data science by Rofiat Hassan.',
		summary3:
			"This is limited to 30 spots so make sure you sign up as early as possible so you don't miss out.",
		summary4:
			'Join now and get early access to other opportunities we make available only on the day.',
	},
	{
		id: '4',
		title: 'TechElite',
		bgImg: card4,
		heroBanner: singleEvent,
		summary1:
			'TechElite is a 5-month advanced software engineering programme designed for experienced software engineers looking to improve their skills and knowledge in software development. It offers a unique opportunity of a 1-month internship in the UK, with a possible fully-funded relocation, including visa assistance, accommodation, flight, and job. The programme covers a wide range of topics that will help participants develop their skills and knowledge in software development. It includes Domain-Driven Design (DDD) which will help participants to model complex business domains and create software that is aligned with business needs.',
		summary2:
			'Participants will also learn about software Architecture and patterns, which will help them to design and build maintainable and scalable software systems. Additionally, they will learn about Behavior-Driven Development (BDD) and Test-Driven Development (TDD) which will help them to design and test software in a way that is aligned with the business requirements and ensure that the software works as expected.',
		summary3:
			'The programme also includes a final project, where participants will have the opportunity to apply the skills and knowledge gained through the program to a real-world software development project and receive feedback and guidance from experienced software engineers.',
		summary4:
			' Overall, TechElite offers a unique opportunity for experienced software engineers to improve their skills and knowledge in software development, and to gain valuable international experience in the UK.',
		cardTextColor: 'black',
		date: 'Mon 2 Oct 2023',
		titleColor: 'sky',
		desc: 'An Advanced Software Engineering Programme',
		descColor: 'black',
		linkText: 'Learn More',
	},
];
