import {
	Box,
	Container,
	Flex,
	Heading,
	Stack,
	Text,
	useColorMode,
} from '@chakra-ui/react';
import CommonBtn from 'components/common/CommonBtn';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

const Wuc: FC = () => {
	const { colorMode } = useColorMode();
	return (
		<Box>
			<Container maxW="1440px" px={{ base: '20px', sm: '50px', md: '72px' }}>
				<Flex
					flexDir={{ base: 'column', sm: 'row' }}
					borderRadius="40px"
					boxShadow="0px 92px 40px -59px rgba(108, 76, 223, 0.15)"
					align={{ base: 'flex-start', sm: 'center' }}
					justify="space-between"
					p={{
						base: '47px 25px 29px 32px',
						sm: '47px 25px 29px 32px',
						md: '0px 45px 0px 57px',
						lg: '0px 105px 0px 87px',
					}}
					minHeight="260px"
					fontFamily="bold"
					position="relative"
					w="full"
					bgGradient={
						colorMode === 'light'
							? 'linear(92.83deg, #7ED8FF 2.07%, #6C4CDF 38.41%)'
							: 'unset'
					}
					bgColor={colorMode === 'dark' ? 'darkBg' : 'unset'}
				>
					{/* <Flex flexDir="column" align="flex-end" display={{ base: 'none', md: 'flex' }}>
                  <Image src={contactImg} alt="contact image" w="154px" />
               </Flex> */}
					<Stack color="white" spacing="15px" mb={{ base: '25px', sm: '0px' }}>
						<Heading
							fontWeight="700"
							fontSize={{ base: '24px', md: '32px' }}
							maxW="428px"
						>
							Ready to take your business to the next level?
						</Heading>
						<Text maxW="660px" fontSize={{ base: '16px' }}>
							Contact us today to schedule a consultation. We'll discuss your
							unique needs and develop a custom solution that meets your
							requirements. Don't wait let us help you transform your business
							with our services.
						</Text>
					</Stack>
					<Link to="/contact">
						<CommonBtn
							text={'Contact Us'}
							width={{ base: '165px', sm: '166px' }}
							height={'60px'}
							color={'black'}
							bg={'white'}
						/>
					</Link>
				</Flex>
			</Container>
		</Box>
	);
};

export default Wuc;
