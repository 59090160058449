import { FC } from 'react';
import {
	Box,
	Container,
	Flex,
	Grid,
	GridItem,
	Heading,
	Stack,
	Text,
	useColorMode,
} from '@chakra-ui/react';
// import { phasecurveIcon } from "assets/svgs/programme";
import { Stribe } from './../../../assets/svgs/community';
import { EventList } from 'data/EventList';
import EventCard from '../EventCard';
import { halfLogoDot, logoDot } from 'assets/svgs/about';
import { Link } from 'react-router-dom';

const EventPage: FC = () => {
	const { colorMode } = useColorMode();
	return (
		<Box bgColor={colorMode === 'light' ? 'white' : '#000'} position="relative">
			<Container
				maxW="1440px"
				px={{ base: '20px', sm: '50px', md: '72px' }}
				pt="91px"
				pb={{ base: '83px', md: '103px' }}
			>
				<Flex flexDir="column" fontFamily="bold">
					<Stack
						spacing={{ base: '30px', md: '48px' }}
						pb="161px"
						px={{ md: '31px', lg: '110px' }}
					>
						<Text
							as="p"
							fontWeight="700"
							letterSpacing="4px"
							color={colorMode === 'light' ? '#94A3B8' : 'darkGray'}
						>
							OUR EVENTS
						</Text>

						<Heading
							maxW={{ base: '100%', md: '694px' }}
							as="h2"
							fontSize={{ base: '36px', md: '40px' }}
							lineHeight={{ base: '44px', md: '50.54px', lg: '60px' }}
							fontWeight="700"
						>
							Be our guest as we go through exciting programs in software
							development and assist with technical support with professionals
							from all
							<Heading
								as="span"
								color={'purple'}
								fontSize={{ base: '36px', md: '40px' }}
								lineHeight={{ base: '44px', md: '50.54px', lg: '60px' }}
								fontWeight="700"
							>
								{' '}
								over the world.
							</Heading>
						</Heading>

						<Box
							display={{ base: 'none', md: 'block' }}
							pos="absolute"
							top={117}
							right="15%"
							zIndex={3}
						>
							{logoDot}
						</Box>
						<Box
							pos="absolute"
							display={{ base: 'block', md: 'none' }}
							top={{ base: '15%', sm: '17%' }}
							right={0}
							zIndex={3}
						>
							{halfLogoDot}
						</Box>
					</Stack>

					<Grid
						templateColumns={{
							base: 'repeat(1, 1fr)',
							sm: 'repeat(2, 1fr)',
							md: 'repeat(3, 1fr)',
						}}
						gap="30px"
					>
						{EventList.map((item) => (
							<GridItem w="100%" key={item.id}>
								<Link to={`/community/event/${item.id}`}>
									<EventCard
										id={item.id}
										title={item.title}
										linkText={item.linkText}
										bgImg={item.bgImg}
										date={item.date}
										titleColor={item.titleColor}
										desc={item.desc}
										cardTextColor={item.cardTextColor}
										descColor={item.descColor}
									/>
								</Link>
							</GridItem>
						))}
					</Grid>
				</Flex>
				<Box
					pos="absolute"
					top={0}
					left={0}
					display={{ base: 'none', md: 'block' }}
				>
					{Stribe}
				</Box>
			</Container>
		</Box>
	);
};

export default EventPage;
