import {
	FormControl,
	FormLabel,
	Select,
	useColorMode,
	FormErrorMessage,
	SelectProps,
} from '@chakra-ui/react';
import { Controller } from 'react-hook-form';

type Props = {
	control: any;
	options: any;
	label: string;
	name: string;
	placeholder?: string;
} & SelectProps;

function CustomSelectField({
	control,
	options,
	label,
	name,
	placeholder,
	...rest
}: Props) {
	const { colorMode } = useColorMode();

	return (
		<Controller
			control={control}
			name={name}
			rules={{ required: 'This is required' }}
			render={({ field, fieldState: { invalid, error } }: any) => {
				return (
					<FormControl isInvalid={invalid}>
						<FormLabel
							display={'inline-block'}
							p="1px 3px"
							borderRadius="5px"
							fontSize="12px"
							color={colorMode === 'light' ? 'gray' : 'darkGray'}
							bg={colorMode === 'light' ? 'white' : 'black'}
							pos="absolute"
							zIndex={5}
							top="-10px"
							left={3}
						>
							{label}
						</FormLabel>
						<Select
							fontSize="14px"
							borderRadius="10px"
							width={'302px'}
							height={'73px'}
							_hover={{ border: '2px solid #6C4CDF' }}
							focusBorderColor="#6C4CDF"
							border="1px solid #94A3B8"
							transition="0.7 ease-in-out"
							placeholder={placeholder ? placeholder : 'Select an option'}
							{...field}
							{...rest}
						>
							{options.map((o: any) => (
								<option value={o.value} key={o.value}>
									{o.label}
								</option>
							))}
						</Select>

						{error && (
							<FormErrorMessage mt="1.5" color="red.600" fontSize="sm">
								{error.message}
							</FormErrorMessage>
						)}
					</FormControl>
				);
			}}
		/>
	);
}

export default CustomSelectField;
