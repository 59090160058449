import {
	Flex,
	Box,
	Heading,
	HStack,
	Icon,
	Stack,
	Image,
	Text,
	useColorMode,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { HiStar } from 'react-icons/hi';

type Props = {
	text: string;
	title: string;
	logoText: string;
	bgColor: {};
	logo: any;
};
const ClientsCard: FC<Props> = ({ title, logo, logoText, bgColor, text }) => {
	const { colorMode } = useColorMode();
	return (
		<Stack
			spacing="40px"
			w="100%"
			p="35px"
			borderRadius="40px"
			align={{ base: 'flex-start', sm: 'center', md: 'flex-start' }}
			minH="350px"
			fontFamily="bold"
			bgColor={bgColor}
		>
			<HStack spacing="10px" fontSize="24px">
				<Icon as={HiStar} color="#FFA500" />
				<Icon as={HiStar} color="#FFA500" />
				<Icon as={HiStar} color="#FFA500" />
				<Icon as={HiStar} color="#FFA500" />
				<Icon as={HiStar} color="#FFA500" />
			</HStack>

			<Heading
				as="h3"
				maxW={{ base: '320px', sm: '342px' }}
				fontSize={{ base: '16px', sm: '20px' }}
			>
				{text}
			</Heading>
			<Flex align="center">
				<Box mr="14px">
					<Image src={logo} alt="logo" />
				</Box>
				<Stack spacing="5px" maxW="342px">
					<Heading as="h4" fontSize={'20px'} fontWeight="700">
						{title}
					</Heading>
					<Text color={colorMode === 'light' ? 'gray' : 'darkgray'}>
						{logoText}
					</Text>
				</Stack>
			</Flex>
		</Stack>
	);
};

export default ClientsCard;
