import React, { FC } from 'react';
import { Bounce } from 'react-reveal';
import trainingBanner from '../../assets/image/services/training-banner.webp';
import {
	Box,
	Container,
	Flex,
	Grid,
	GridItem,
	Heading,
	Link,
	Stack,
	Text,
	useColorMode,
	VStack,
} from '@chakra-ui/react';

import OfferCard from './OfferCard';
import {
	qualityService,
	extensiveTraining,
	learningSource,
	careerSupport,
} from './../../assets/svgs/service';

const Offer: FC = () => {
	const { colorMode } = useColorMode();
	return (
		<Box bgColor={colorMode === 'light' ? 'white' : '#000'}>
			<Container
				maxW="1440px"
				p={{
					base: '32px 20px 18px',
					sm: '42px 50px 36px',
					md: '57px 103px 102px',
					lg: '57px 69px 102px',
				}}
			>
				<Flex flexDir="column" fontFamily="bold">
					<VStack
						spacing={{ base: '24px', sm: '35px', md: '49px' }}
						textAlign="center"
						pb={{ base: '64px', md: '146px', lg: '186px' }}
					>
						<Bounce duration="3500">
							<Heading
								as="h2"
								fontSize={{ base: '32px', sm: '40px', md: '48px' }}
								fontWeight="700"
								maxW={{ base: '356px', md: '100%' }}
								color={colorMode === 'light' ? 'black' : 'white'}
							>
								Why Choose {''}
								<Heading
									as="span"
									fontSize={{ base: '32px', sm: '40px', md: '48px' }}
									fontWeight="700"
									color="purple"
								>
									Phasecurve
								</Heading>
							</Heading>
						</Bounce>
						<Text
							fontSize={{ base: '16px', md: '20px' }}
							lineHeight="30px"
							textAlign="center"
							color={colorMode === 'light' ? 'gray' : 'darkGray'}
							maxW="846px"
						>
							Our sole mission is to build a world where great ideas and
							opportunities are executed and harnessed to enhance the personal
							growth of the tech community.
						</Text>
						<Text
							fontSize={{ base: '16px', md: '20px' }}
							lineHeight="20px"
							color={colorMode === 'light' ? 'black' : 'darkGray'}
							maxW="846px"
						>
							<b>What we guarantee:</b>
							<br />
							<br />
							<br />
							<Flex
								flexDir={{ base: 'column', md: 'row' }}
								gap={{ base: '20px', md: '50px' }}
								align={{ base: 'flex-start', md: 'center' }}
								justify="space-between"
								maxW="846px"
							>
								<Text
									fontWeight="700"
									color="purple"
									w={{ base: '100%', md: '30%' }}
									textAlign="left"
								>
									Expertise:
								</Text>
								<Text
									w={{ base: '100%', md: '70%' }}
									textAlign="left"
									fontSize={{ base: '16px' }}
									maxW="600px"
									color={colorMode === 'light' ? 'gray' : 'darkGray'}
								>
									{' '}
									Our team includes highly skilled developers, designers,
									project managers, and trainers who have years of experience in
									the industry.
								</Text>
							</Flex>
							<br />
							<Flex
								flexDir={{ base: 'column', md: 'row' }}
								gap={{ base: '20px', md: '50px' }}
								align={{ base: 'flex-start', md: 'center' }}
								justify="space-between"
								maxW="846px"
							>
								<Text
									fontWeight="700"
									color="purple"
									w={{ base: '100%', md: '30%' }}
									textAlign="left"
								>
									Quality:
								</Text>
								<Text
									w={{ base: '100%', md: '70%' }}
									textAlign="left"
									maxW="600px"
									fontSize={{ base: '16px' }}
									color={colorMode === 'light' ? 'gray' : 'darkGray'}
								>
									{' '}
									We pride ourselves on delivering high-quality work that
									exceeds our client's expectations. We never cut corners or
									compromise on quality.
								</Text>
							</Flex>
							<br />
							<Flex
								flexDir={{ base: 'column', md: 'row' }}
								gap={{ base: '20px', md: '50px' }}
								align={{ base: 'flex-start', md: 'center' }}
								justify="space-between"
								maxW="846px"
							>
								<Text
									fontWeight="700"
									color="purple"
									w={{ base: '100%', md: '30%' }}
									textAlign="left"
								>
									Communication:
								</Text>
								<Text
									w={{ base: '100%', md: '70%' }}
									textAlign="left"
									fontSize={{ base: '16px' }}
									color={colorMode === 'light' ? 'gray' : 'darkGray'}
								>
									{' '}
									We believe that communication is key to a successful project.
									We keep you informed every step of the way and are always
									available to answer your questions.
								</Text>
							</Flex>
							<br />
							<Flex
								flexDir={{ base: 'column', md: 'row' }}
								gap={{ base: '20px', md: '50px' }}
								align={{ base: 'flex-start', md: 'center' }}
								justify="space-between"
								maxW="846px"
							>
								<Text
									fontWeight="700"
									color="purple"
									w={{ base: '100%', md: '30%' }}
									textAlign="left"
								>
									Custom Solutions:
								</Text>
								<Text
									w={{ base: '100%', md: '70%' }}
									textAlign="left"
									maxW="600px"
									fontSize={{ base: '16px' }}
									color={colorMode === 'light' ? 'gray' : 'darkGray'}
								>
									{' '}
									We don't believe in one-size-fits-all solutions. We work with
									you to understand your unique needs and develop custom
									solutions that meet your requirements.
								</Text>
							</Flex>
						</Text>
					</VStack>

					<Flex
						flexDir={{ base: 'column', md: 'row' }}
						justify={{ base: 'center', md: 'space-between' }}
						px={{ base: '0', lg: '113px' }}
						pb={{ base: '56px', md: '86px', lg: '105px' }}
						gap={5}
						align="center"
					>
						<Stack spacing={{ base: '26px', sm: '35px', md: '42px' }}>
							<Heading
								textAlign={{ base: 'center', md: 'left' }}
								fontSize={{ base: '32px', sm: '40px', lg: '48px' }}
								fontWeight="700"
								maxW={{ base: '356px', sm: '386px' }}
							>
								We offer quality{' '}
								<Heading
									as="span"
									fontWeight="700"
									fontSize={{ base: '32px', sm: '40px', lg: '48px' }}
									color="purple"
								>
									training services
								</Heading>
							</Heading>
							<Text
								maxW="406px"
								fontSize={{ base: '16px', md: '20px' }}
								lineHeight="30px"
								textAlign={{ base: 'center', md: 'left' }}
								color={colorMode === 'light' ? 'black' : 'darkGray'}
							>
								Cost and availability of learning resources are no longer
								barriers to upskill. We offer immense opportunities for
								professional growth.
							</Text>
						</Stack>
						<Box
							bgImage={trainingBanner}
							backgroundRepeat="no-repeat"
							borderRadius={20}
							w={{ base: '100%', sm: '550px' }}
							h="220px"
							py={{ base: '59px', lg: '62px' }}
							px={{ base: '20px', sm: '49px' }}
						>
							<Heading
								maxW="248px "
								fontSize={{ base: '30px', sm: '32px' }}
								fontWeight="700"
								color="white"
							>
								Read our propectus to
								<br />
							</Heading>
							<Box transition="0.5s ease" _hover={{ transform: 'scale(1.02)' }}>
								<Heading
									as="span"
									fontSize={{ base: '24px', sm: '30px' }}
									color="white"
									transition="0.5s ease"
									textDecor="underline"
								>
									<Link
										href="https://drive.google.com/file/d/12rqaqTXWxOi9qaZf-V-0looCXZTR0myn/view"
										isExternal
									>
										learn more
									</Link>
								</Heading>
							</Box>
						</Box>
					</Flex>

					<Grid
						templateColumns={{
							base: 'repeat(1, 1fr)',
							sm: 'repeat(2, 1fr)',
							lg: 'repeat(4, 1fr)',
						}}
						gap={29}
					>
						<GridItem w="100%">
							<OfferCard
								icon={qualityService}
								title={'Software Development'}
								text={
									"Our team of experts provides custom software development services to meet your business's unique needs and goals."
								}
							/>
						</GridItem>
						<GridItem w="100%">
							<OfferCard
								icon={extensiveTraining}
								title={'Website Creation'}
								text={
									'Make a lasting impression with a beautiful, responsive website that showcases your brand and drives engagement.'
								}
							/>
						</GridItem>
						<GridItem w="100%">
							<OfferCard
								icon={careerSupport}
								title={'Product Management'}
								text={
									'Our product management services help you optimise your product development processes and bring your products to market faster. '
								}
							/>
						</GridItem>
						<GridItem w="100%">
							<OfferCard
								icon={learningSource}
								title={'Training'}
								text={
									' Our comprehensive programs cover a wide range of topics, from software development to project management to website design for individuals and corporate teams.'
								}
							/>
						</GridItem>
					</Grid>
				</Flex>
			</Container>
		</Box>
	);
};

export default Offer;
