import invention from '../assets/image/about/integrity.png';
import excellence from '../assets/image/about/empathy.png';
import sustainability from '../assets/image/about/quality.png';

export const ValueList = [
	{
		id: 0,
		title: 'Integrity',
		icon: invention,
		h: '550px',
		text: 'We believe in being honest and transparent in all of our dealings, both with our clients and with each other. Our team is committed to upholding the highest standards of integrity, and we believe this is the foundation of trust and long-lasting relationships.',
	},
	{
		id: 1,
		title: 'Empathy',
		icon: excellence,
		h: '650px',
		text: "We are dedicated to ensuring that our clients are always heard, and we approach every project with empathy, putting ourselves in our clients' shoes to create solutions that truly meet their needs. We believe that understanding and acknowledging the needs of our clients is the key to providing them with the best possible service.",
	},
	{
		id: 2,
		title: 'Quality',
		icon: sustainability,
		h: '600px',
		text: "Quality is non-negotiable for us. We believe in providing the best possible service to our clients, and we're committed to delivering high-quality solutions that exceed their expectations. Our team is made up of experienced professionals who take pride in their work and are dedicated to delivering top-notch results.",
	},
];
