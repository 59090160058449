import React, { useState, FC } from 'react';
import {
	Box,
	Heading,
	Input,
	Flex,
	Container,
	useColorMode,
} from '@chakra-ui/react';
import CommonBtn from 'components/common/CommonBtn';
import NewsletterImg from '../../assets/image/home/newsletter-img.webp';
import darkNewsletterImg from '../../assets/image/home/darkmode/dark-newsletter.webp';
import api from 'lib/axios';
import toast from 'components/common/toast';

const Newsletter: FC = () => {
	const { colorMode } = useColorMode();
	const [email, setEmail] = useState('');
	const [loading, setLoading] = useState(false);

	const handleSubmit = () => {
		setLoading(true);
		if (!email) {
			setLoading(false);
			return toast('Please provide email').error();
		}
		api
			.post('/newsletter', { emailAddress: email })
			.then(() => {
				toast('Subscribed successfully').success();
				setLoading(false);
				setEmail('');
			})
			.catch(() => {
				toast('Something went wrong').success();
				setLoading(false);
			});
	};

	return (
		<Box bgColor={colorMode === 'light' ? 'none' : '#000'}>
			<Container
				maxW="1440px"
				px={{ base: '20px', sm: '50px', md: '72px' }}
				pt={{ base: '31px', md: '77px' }}
			>
				<Flex
					flexDir="column"
					justify="center"
					align="center"
					borderRadius="40px"
					minHeight={{ base: '447px', md: '591px' }}
					textAlign="center"
					fontFamily="bold"
					px="20px"
					bgImage={colorMode === 'light' ? NewsletterImg : darkNewsletterImg}
					bgSize="cover"
					bgPos="center"
					bgRepeat="no-repeat"
				>
					<Heading
						maxW="840px"
						pb="50px"
						textAlign="center"
						lineHeight={{ base: '30px', md: '44px' }}
						color="white"
						fontWeight="700"
						fontSize={{ base: '24px', md: '40px' }}
					>
						Stay ahead of the curve.
						<br />
						Sign up for our newsletter.
					</Heading>

					<Flex
						flexDir={{ base: 'column', md: 'row' }}
						justify="center"
						align="center"
					>
						<Flex direction="column" position="relative">
							<Input
								color="black"
								h="60px"
								focusBorderColor="#6c4cdf"
								fontWeight="700"
								fontSize={{ base: '16px', md: '20px' }}
								borderRadius="100px"
								maxW="286px"
								bgColor="white"
								placeholder="Email Address"
								_placeholder={{ color: 'black', fontWeight: '700' }}
								size="lg"
								type="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							></Input>
						</Flex>
						<Box
							pt={{ base: '15px', md: '0px' }}
							pl={{ base: '0px', md: '7px' }}
						>
							<CommonBtn
								text={'Apply Now'}
								width={{ base: '286px', md: '166px' }}
								height={'60px'}
								color={'white'}
								bg={'#6c4cdf'}
								isLoading={loading}
								onClick={handleSubmit}
							/>
						</Box>
					</Flex>
				</Flex>
			</Container>
		</Box>
	);
};

export default Newsletter;
