import React, { FC } from 'react';
import { Box, useColorMode } from '@chakra-ui/react';
import hero from '../../assets/image/home/hero.png';
import RefineIdeas from './RefineIdeas';

const Intro: FC = () => {
	const { colorMode } = useColorMode();
	return (
		<Box
			bgImage={colorMode === 'light' ? hero : 'none'}
			bgPos="bottom"
			bgColor={colorMode === 'light' ? 'none' : '#000'}
			w="full"
			h="full"
		>
			<RefineIdeas />
		</Box>
	);
};

export default Intro;
