import { FC } from 'react';
import {
	Box,
	Container,
	Heading,
	ListItem,
	Stack,
	Text,
	UnorderedList,
	useColorMode,
} from '@chakra-ui/react';

import { Hstribe } from 'assets/svgs/programme';

import { logoDot, logoDotWhite } from 'assets/svgs/about';
import { Link } from 'react-router-dom';

const PrivacyPolicy: FC = () => {
	const { colorMode } = useColorMode();
	return (
		<Box bgColor={colorMode === 'light' ? 'white' : '#000'} position="relative">
			<Container
				maxW="1440px"
				pt={{ base: '45px', sm: '55px', md: '91px' }}
				px={{ base: '20px', sm: '50px', md: '103px', lg: '182px' }}
			>
				<Stack spacing={{ base: '20px', sm: '30px', md: '50px' }}>
					<Stack pb={{ base: '10px', md: '30px' }}>
						<Heading
							as="h2"
							fontSize={{ base: '34px', md: '54px' }}
							color={colorMode === 'light' ? 'navy' : 'sky'}
						>
							Privacy Policy
						</Heading>
					</Stack>

					<Stack>
						<Heading as="h2" fontSize="24px" pb="20px">
							Overview
						</Heading>

						<Text
							color={colorMode === 'light' ? 'gray' : 'darkGray'}
							lineHeight="30px"
							maxW="750px"
							fontSize="16px"
						>
							Phasecurve Technologies Limited ('Phasecurve', 'we', 'us', 'our'
							'Company'), recognizes your privacy rights as guaranteed under the
							1999 Constitution of the Federal Republic of Nigeria; the Nigerian
							Data Protection Regulation 2019 issued by the National Information
							Technology Development Agency (NITDA), and the privacy provisions
							in other relevant laws applicable to our business in Nigeria.
							<br />
							<br />
							Thus, it is important to us as a law-abiding organisation that
							your Personal Data is managed, processed and protected in
							accordance with the provisions of the applicable laws.
							<br />
							<br />
							In the course of our business and/or your engagement with us and
							third parties through our Platforms (this includes but are not
							limited to our websites, digital platforms, mobile applications,
							physical operations/offices, amongst others), we may process your
							Personal Data, subject however to the terms of this
							<br />
							<br />
							This Privacy Policy ('Policy') therefore explains our privacy
							practices with respect to how we process your Personal Data and
							describes your rights as a user of any of our services and
							Platforms.
							<br />
							<br />
							This Policy applies to all our Applications, Platforms, and all
							related sites, services and tools regardless of how they are
							accessed or used.
						</Text>
					</Stack>

					<Stack>
						<Heading as="h2" fontSize="24px" pb="20px">
							Definitions
						</Heading>

						<Text
							color={colorMode === 'light' ? 'gray' : 'darkGray'}
							maxW="750px"
							fontSize="16px"
							lineHeight="30px"
						>
							For the purpose of this Policy:
							<br />'
							<Text
								as="span"
								color={colorMode === 'light' ? 'black' : 'darkGray'}
								fontWeight={700}
							>
								Data
							</Text>
							' and/or '
							<Text
								as="span"
								color={colorMode === 'light' ? 'black' : 'darkGray'}
								fontWeight={700}
							>
								Information
							</Text>
							'means any information relating to you, including your name,
							identification number, location data, online identifier address,
							photo, email address, pins, passwords, bank details, posts on our
							Platforms, religion, date of birth, health, race/tribe,
							nationality, ethnicity, your location, trades union membership,
							criminal records, medical information, and other unique
							identifiers such as but not limited to MAC address, IP address,
							Bank Verification Number, and others. It also includes factors
							specific to your physical, physiological, genetic, mental,
							economic, cultural or social identity.
							<br />
							<br />'
							<Text
								as="span"
								color={colorMode === 'light' ? 'black' : 'darkGray'}
								fontWeight={700}
							>
								Process
							</Text>
							' and/or '
							<Text
								as="span"
								color={colorMode === 'light' ? 'black' : 'darkGray'}
								fontWeight={700}
							>
								Processing
							</Text>
							' {''} means any operation or set of operations which is performed
							on your Personal Data or sets of Personal Data, whether or not by
							automated means, such as collection, recording, organisation,
							structuring, storage, adaptation or alteration, retrieval,
							consultation, use, disclosure by transmission, dissemination or
							otherwise making available, alignment or combination, restriction,
							erasure or destruction.
						</Text>
					</Stack>

					<Stack>
						<Heading as="h2" fontSize="24px" pb="20px">
							Consent
						</Heading>

						<Text
							color={colorMode === 'light' ? 'gray' : 'darkGray'}
							maxW="750px"
							fontSize="16px"
							lineHeight="30px"
						>
							We kindly request that you carefully read through this Policy and
							click on the ‘Agree’ icon below, if you consent to the terms of
							this Policy, particularly with respect to the processing of your
							Personal Data. That notwithstanding, if you proceed to use any of
							our Platforms and services, it will deemed that you have provided
							your express consent to the terms of this Policy.
						</Text>
					</Stack>

					<Stack>
						<Heading as="h2" fontSize="24px" pb="20px">
							Age
						</Heading>

						<Text
							color={colorMode === 'light' ? 'gray' : 'darkGray'}
							maxW="750px"
							fontSize="16px"
							lineHeight="30px"
						>
							You must be at least 18 years old to use our services or any of
							our Platforms. Individuals under the age of 18, or applicable age
							of maturity, may utilize our Platforms services only with
							involvement of a parent or legal guardian, under such person’s
							account. Regardless, all Personal Data which we may process shall
							be in accordance with this Policy and other applicable laws.
						</Text>
					</Stack>

					<Stack>
						<Heading as="h2" fontSize="24px" pb="20px">
							What Information Do We Collect?
						</Heading>

						<Text
							color={colorMode === 'light' ? 'gray' : 'darkGray'}
							maxW="750px"
							fontSize="16px"
							lineHeight="30px"
						>
							We collect information from you when you visit our service,
							register, place an order, respond to a survey or fill out a form.
							<UnorderedList
								color={colorMode === 'light' ? 'black' : 'darkGray'}
								pl="30px"
							>
								<ListItem>Email Address</ListItem>
								<ListItem>Name / Username</ListItem>
								<ListItem>Physical Address</ListItem>
								<ListItem>Phone Number</ListItem>
							</UnorderedList>
						</Text>
					</Stack>

					<Stack>
						<Heading as="h2" fontSize="24px" pb="20px">
							How we Use Your Personal Information
						</Heading>

						<Text
							color={colorMode === 'light' ? 'gray' : 'darkGray'}
							maxW="750px"
							fontSize="16px"
							lineHeight="30px"
						>
							In the course of your engagements with us or through our
							Platforms, we collect personal information for various legal
							reasons, largely to enable us to personalize your experience and
							to provide a more efficient service to you. Some of the reasons we
							collect Information are:
							<UnorderedList
								color={colorMode === 'light' ? 'black' : 'darkGray'}
								pl="30px"
							>
								<ListItem>To help us verify your identity</ListItem>
								<ListItem>
									To help us process transaction, and verify receipt of payment
									for Services.
								</ListItem>
								<ListItem>
									To help us identify accounts and services which you could have
									from us from time to time.
								</ListItem>
								<ListItem>
									Enable us to operate, protect, improve, and optimize
									Phasecurve by carrying out marketing analysis and customer
									profiling (including transactional information), conduct
									research, including creating statistical and testing
									information.
								</ListItem>
								<ListItem>
									Personalize or otherwise customize your experience by, among
									other things, ranking search results or showing ads based on
									your search, booking history, and preferences.
								</ListItem>
								<ListItem>Provide an excellence customer service</ListItem>
								<ListItem>
									Send you service or support messages, such as updates,
									security alerts, and account notifications.
								</ListItem>
								<ListItem>
									Verify or authenticate information or identifications provided
									by you (such as to verify your address or compare your
									identification photo to another photo you provide).
								</ListItem>
								<ListItem>
									To help us to administer and to contact you about improved
									administration of any accounts, listings and booking services
									we have provided in the past.
								</ListItem>
								<ListItem>
									Investigate and respond to your complaints or enquiries;
								</ListItem>
								<ListItem>
									Conduct background checks, compare information for accuracy
									and verify same with third parties;
								</ListItem>
								<ListItem>Conduct investigations and risk assessments</ListItem>
								<ListItem>
									Comply with legal, contractual and regulatory obligations;
								</ListItem>
								<ListItem>
									To allow us to contact you in any way (including e-mail,
									telephone, visit, and text or multimedia messages) about
									services offered by us unless you have previously asked us not
									to do so.
								</ListItem>
							</UnorderedList>
							We may monitor and record communications with you (including phone
							conversations and emails) for quality assurance and compliance
							purposes. We will not disclose your personal data to any third
							party except in accordance with this privacy policy and the
							relevant laws.
						</Text>
					</Stack>

					<Stack>
						<Heading as="h2" fontSize="24px" pb="20px">
							How Do We Use Your Email Address?
						</Heading>

						<Text
							color={colorMode === 'light' ? 'gray' : 'darkGray'}
							maxW="750px"
							fontSize="16px"
							lineHeight="30px"
						>
							By submitting your email address on this website , you agree to
							receive emails from us. You can cancel your participation in any
							of these email lists at any time by clicking on the opt-out link
							or other unsubscribe option that is included in the respective
							email. We only send emails to people who have authorized us to
							contact them, either directly, or through a third party. We do not
							send unsolicited commercial emails, because we hate spam as much
							as you do. By submitting your email address, you also agree to
							allow us to use your email address for customer audience targeting
							on sites like Facebook, where we display custom advertising to
							specific people who have opted-in to receive communications from
							us.
							<br />
							<br />
							Email addresses submitted only through the order processing page
							will be used for the sole purpose of sending you information and
							updates pertaining to your order. If, however, you have provided
							the same email to us through another method, we may use it for any
							of the purposes stated in this Policy.
							<br />
							<br />
							<Text
								as="span"
								fontWeight={700}
								color={colorMode === 'light' ? 'black' : 'darkGray'}
							>
								Note:{' '}
							</Text>
							If at any time you would like to unsubscribe from receiving future
							emails, we include detailed unsubscribe instructions at the bottom
							of each email.
						</Text>
					</Stack>

					<Stack>
						<Heading as="h2" fontSize="24px" pb="20px">
							Storage and Protection of Your Data
						</Heading>

						<Text
							color={colorMode === 'light' ? 'gray' : 'darkGray'}
							maxW="750px"
							fontSize="16px"
							lineHeight="30px"
						>
							We protect your personal Information using physical, technical,
							and administrative security measures to reduce the risks of loss,
							misuse, unauthorized access, disclosure and alteration. Some of
							our safeguards include ensuring that sensitive data are encrypted
							and stored in a secure manner with only authorised users having
							access to this information. Access to our various platforms is
							restricted to authorized users only. Your Information is also
							stored on our secure servers as well as secure physical locations
							and cloud infrastructure. All information are stored in data
							centers which is collocated out our office environment, this is to
							ensure security of data and also availability of data when
							required.
							<br />
							<br />
							We will take all necessary measure to ensure that your Personal
							Data is safe, however, you are also required to ensure that access
							codes, PINs, passwords, usernames, and all other information or
							hints that may enable third party access to your accounts on our
							Platforms are secure. We therefore strongly advise you to keep
							such information secure and confidential. If you use a third
							party’s device (laptops, phones, public internet, etc.) to access
							your account, kindly ensure that you always log out. Kindly note
							however that certain devices are programmed to save passwords or
							usernames, as such, we therefore advise that you use third party
							devices with extreme caution. If you believe that an unauthorized
							person has accessed your information, please contact us
							immediately.
						</Text>
					</Stack>

					<Stack>
						<Heading as="h2" fontSize="24px" pb="20px">
							Processing Your Information
						</Heading>

						<Text
							color={colorMode === 'light' ? 'gray' : 'darkGray'}
							maxW="750px"
							fontSize="16px"
							lineHeight="30px"
						>
							In order to execute our obligations to you or process your
							transactions, we may be required to process your Information, such
							as your name, contact details, or other information needed to
							complete the transaction. We also work with third parties,
							including financial institutions, service providers who at one
							point or the other facilitate transactions executed on our
							Platforms. For completeness, in the course of your engagement with
							us or use of our services and Platforms, we may share your
							information with different stakeholders, including but not limited
							to Financial institutions; service providers; and collection
							agencies to report account or credit information; Phasecurve
							Limited; Regulatory or judicial authorities; or other third
							parties pursuant to a subpoena, court order, or other legal
							process or requirement applicable to Phasecurve. Please note that
							the parties mentioned above may be within or outside Nigeria.
							<br />
							<br />
							We may also process your Information when we believe, in our sole
							discretion, that the disclosure of your Information is necessary
							to comply with applicable laws and judicial/regulatory orders;
							conduct investigations; manage existing or imminent risks, prevent
							fraud, crime or financial loss, or for public safety or to report
							suspected illegal activity or to investigate violations of our
							Terms and Conditions.
							<br />
							<br />
							In all cases, we will ensure that your Information is safe, and
							notify the receiving party of the confidential nature of your
							Information, particularly the need to maintain the confidentiality
							of same and prevent unlawful or unauthorized usage.
						</Text>
					</Stack>

					<Stack>
						<Heading as="h2" fontSize="24px" pb="20px">
							Your Rights
						</Heading>

						<Text
							color={colorMode === 'light' ? 'gray' : 'darkGray'}
							maxW="750px"
							fontSize="16px"
							lineHeight="30px"
						>
							You have the following rights regarding your personal information
							collected by us:
							<UnorderedList
								color={colorMode === 'light' ? 'black' : 'darkGray'}
								pl="30px"
							>
								<ListItem>
									Right to access your personal information being held by us.
									Request for such information may be sent to
									<Link to={'www.phasecurve.com'} target="_blank">
										{' '}
										www.phasecurve.com
									</Link>
								</ListItem>
								<ListItem>
									Right to request that your personal data be made available to
									you in an electronic format or that it should be sent to a
									third party (Kindly note that we have the right to decline
									such request if it is too frequent, unreasonable, and likely
									to cause substantial cost to us. In any event, we will
									communicate the reason for our refusal);
								</ListItem>
								<ListItem>
									Right to rectify any inaccurate, incomplete information. As
									such, if you discover any inaccuracy in your personal
									information, kindly notify us promptly and provide us with
									documentary evidence to enable us to update the requisite
									changes;
								</ListItem>
								<ListItem>
									Right to withdraw consent for the processing of your
									information, provided that such withdrawal shall not
									invalidate any processing hitherto done based on the consent
									previously given by you;
								</ListItem>
								<ListItem>
									Restrict or object to the processing of your personal data if
									we may be compelled to process your data where required under
									law, regulatory authorities, or court of law;
								</ListItem>
								<ListItem>
									Right to request that your personal data be deleted. We may
									however continue to retain the information where required
									under law, contract, regulatory authorities, or court of law;
								</ListItem>
								<ListItem>Right to data portability;</ListItem>
								<ListItem>
									Right to lodge complaints with relevant authorities;
								</ListItem>
							</UnorderedList>
						</Text>
					</Stack>

					<Stack>
						<Heading as="h2" fontSize="24px" pb="20px">
							Disclosure
						</Heading>

						<UnorderedList
							color={colorMode === 'light' ? 'black' : 'darkGray'}
							pl="30px"
							maxW="750px"
						>
							<ListItem>
								Phasecurve will not sell, publish, disclose, or rent to third
								parties your personally identifiable information collected at
								our web site, through our servers or otherwise obtained by us,
								other than to provide our services and as set forth in this
								privacy policy.
							</ListItem>
							<ListItem>
								The information you provide during any transaction such as your
								billing, Receiver’s details, and pay-out information shall be
								considered confidential information. We will not disclose this
								information to outside parties without the written consent of
								all the parties to any transaction.
							</ListItem>
							<ListItem>
								We may share your information with other companies such as your
								banking institution and with the other party to your
								transaction. We may also request information about you from
								third parties to provide our services.
							</ListItem>
							<ListItem>
								We will notify you as soon as we become aware of a harmful data
								breach which may result in a risk of your rights and freedom.
							</ListItem>
							<ListItem>
								You have the right to request an erasure of your data at any
								time.
							</ListItem>
							<ListItem>
								We will notify you if we are transferring your data.
							</ListItem>
							<ListItem>
								You may request at any time that we halt further dissemination
								of your data or cease to use your data.
							</ListItem>
							<ListItem>
								If you submit content in a public forum or social media post, or
								use a similar feature on
								<Link to={'www.phasecurve.com'} target="_blank">
									{' '}
									www.phasecurve.com{' '}
								</Link>
								that content is publicly visible.
							</ListItem>
							<ListItem>
								Required by Law We may disclose Personally Identifiable
								Information if required to do so by law or in the good faith
								belief that such action is necessary to (a) conform with the
								requirements of the law or comply with legal process served on
								us, or (b) act in urgent circumstances to protect the personal
								safety of users of our service or members of the public.
							</ListItem>
						</UnorderedList>
						<Text
							color={colorMode === 'light' ? 'gray' : 'darkGray'}
							maxW="750px"
							fontSize="16px"
							lineHeight="30px"
						>
							To the extent practicable and legally permitted, we will attempt
							to advise you prior to any such disclosure, so that you may seek a
							protective order or other relief limiting such disclosure.
						</Text>
					</Stack>

					<Stack>
						<Heading as="h2" fontSize="24px" pb="20px">
							Communications from Our Site
						</Heading>

						<Text
							color={colorMode === 'light' ? 'gray' : 'darkGray'}
							maxW="750px"
							fontSize="16px"
							lineHeight="30px"
						>
							Upon registering on our site, you will receive emails that confirm
							specific actions you requested. You will receive notifications
							confirming your registration, the successful verification of email
							addresses and bank accounts, if required, and confirmation of
							successful transactions. You will also receive progress updates on
							the status of your transactions. These are transactional
							notifications that you cannot opt out of receiving, as they are in
							place to protect the security of your account and your personal
							information. We may also send you responses to emails you send us,
							if appropriate or applicable
							<br />
							<br />
							From time to time, we will also send user surveys, requests for
							user feedback regarding user experience and site operations or
							marketing offers from us or from us on behalf of our marketing
							partners. The completion of these surveys or requests for feedback
							or acceptance of any offer is strictly voluntary. If you do not
							wish to receive these offers, surveys or user feedback emails,
							please opt out in any offer email you receive from us.
						</Text>
					</Stack>

					<Stack>
						<Heading as="h2" fontSize="24px" pb="20px">
							Cookies policy
						</Heading>

						<Text
							color={colorMode === 'light' ? 'gray' : 'darkGray'}
							maxW="750px"
							fontSize="16px"
							lineHeight="30px"
						>
							Cookies are small pieces of data, stored in text files, that are
							stored on your computer or other device when websites are loaded
							in a browser, and which collects information about user actions on
							a site.
							<br />
							<br />
							When you access our Platforms, we may place small data files on
							your computer or other device. These data files may be cookies,
							pixel tags, flash cookies, or other local storage provided by your
							browser or associated applications (collectively referred to as
							cookies'). These technologies are used to recognize users as
							customers; customize services, content, and advertising; measure
							promotional effectiveness; help ensure that account security is
							not compromised; mitigate risk and prevent fraud; and to promote
							trust and safety on our Platform. We also use cookies to collect
							and use data as part of our services, recognize your browser or
							device, learn more about your interests, and provide you with
							essential features and services.
							<br />
							<br />
							This Cookies Policy apply to our websites, Applications and
							Platforms alone. We do not exercise control over the sites
							displayed or linked from within our various services. These other
							sites may place their own cookies or other files on your computer,
							collect data or solicit personal information from you.
						</Text>
					</Stack>

					<Stack>
						<Heading as="h2" fontSize="24px" pb="20px">
							What Type of Cookies do we Use?
						</Heading>

						<Text
							color={colorMode === 'light' ? 'gray' : 'darkGray'}
							maxW="750px"
							fontSize="16px"
							lineHeight="30px"
						>
							<Text
								as="span"
								color={colorMode === 'light' ? 'black' : 'darkGray'}
								fontWeight={700}
							>
								Temporary cookies:
							</Text>{' '}
							This is also known as '
							<Text
								as="span"
								color={colorMode === 'light' ? 'black' : 'darkGray'}
								fontWeight={700}
							>
								session cookies
							</Text>{' '}
							'; it helps our sites and platforms to recognize users, temporary
							memorize their online activities and the information provided when
							they navigate through a website. Session cookies only retain
							information about your activities for as long as you are on the
							website. Once the web browser is closed, the cookies are
							automatically deleted.
							<br />
							<Text
								as="span"
								color={colorMode === 'light' ? 'black' : 'darkGray'}
								fontWeight={700}
							>
								Permanent cookies:
							</Text>{' '}
							This is also known as '
							<Text
								as="span"
								color={colorMode === 'light' ? 'black' : 'darkGray'}
								fontWeight={700}
							>
								persistent cookies
							</Text>{' '}
							' or ‘first-party cookies’; they remain in operation even after
							the web browser has closed. It helps our sites or platforms
							remember your information, preferences and settings such as
							language selection and internal bookmarks for when you revisit in
							the future.
						</Text>
					</Stack>

					<Stack>
						<Heading as="h2" fontSize="24px" pb="20px">
							Why We Use Cookies?
						</Heading>

						<Text
							color={colorMode === 'light' ? 'gray' : 'darkGray'}
							maxW="750px"
							fontSize="16px"
							lineHeight="30px"
						>
							Cookies store helpful information to enhance users' experiences on
							our site, and possibly to improve our ability to reconnect with
							you later. They are used to remember you and your preferences such
							as your preferred language, device settings, browsing activities
							and other useful information, help you navigate between pages
							efficiently, and generally improve your experience in using our
							services.
							<br />
							We use these cookies are used are as follows:
							<br />
							<UnorderedList
								color={colorMode === 'light' ? 'black' : 'darkGray'}
								pl="30px"
							>
								<ListItem>
									<b>Authentication:</b> We use cookies and similar technologies
									to recognize you when you visit our site. If you’re signed
									into our website or mobile app, it helps us show you the right
									information and personalize your experience in line with your
									settings. Cookies also enables us to identify you and verify
									your account.
								</ListItem>
								<ListItem>
									<b>Security:</b> We use cookies to make your interactions with
									our services and platforms faster and more secure. For
									example, we use cookies to enable and support our security
									features, keep your account safe and to help us detect
									malicious activity and violations of our terms and conditions
									of use.
								</ListItem>
								<ListItem>
									<b>Preferences, features and services:</b> We use cookies to
									enable the functionality of our Services, such as helping you
									to fill out forms on our Services easily and providing you
									with features, insights and customized content. We also use it
									to remember information about your activities on our platforms
									and your preferences.
								</ListItem>
								<ListItem>
									<b>Performance, Analytics and Research:</b> Cookies help us
									learn more about how well our services perform in different
									locations. We use cookies to determine and measure performance
									and to learn whether you have interacted with our websites,
									content or emails and provide analytics based on those
									interactions.
								</ListItem>
							</UnorderedList>
						</Text>
					</Stack>

					<Stack>
						<Heading as="h2" fontSize="24px" pb="20px">
							Where we place cookies
						</Heading>

						<Text
							color={colorMode === 'light' ? 'gray' : 'darkGray'}
							maxW="750px"
							fontSize="16px"
							lineHeight="30px"
						>
							We set cookies in a number of different locations across our
							services. These locations may include:
							<br />
							<UnorderedList
								color={colorMode === 'light' ? 'black' : 'darkGray'}
								pl="30px"
							>
								<ListItem>
									Our website
									<Link to="www.phasecurve.com" target="_blank">
										{' '}
										www.phasecurve.com
									</Link>
								</ListItem>
								<ListItem>
									E-mails (We may use cookies in some of our emails to enable us
									to understand how you interact with our emails. It also helps
									us to improve our future email communications with you.
									Depending on your email or browser settings, cookies in our
									emails may be automatically accepted.)
								</ListItem>
							</UnorderedList>
						</Text>
					</Stack>

					<Stack>
						<Heading as="h2" fontSize="24px" pb="20px">
							Controlling Cookies
						</Heading>

						<Text
							color={colorMode === 'light' ? 'gray' : 'darkGray'}
							maxW="750px"
							fontSize="16px"
							lineHeight="30px"
						>
							We will always ask for your consent to accept the use of cookies
							on your device. You can freely decline our cookies when you see
							our 'accept cookies' request or through your browser settings.
							Cookies are optional unless they are required to prevent fraud or
							ensure the security of Platforms we control. You may also
							configure your specific browser or email settings to automatically
							reject cookies. You may also erase cookies stored on your device
							through your browser settings or by deleting the folder or file
							where they are stored on your device.
							<br />
							<br />
							Declining our Cookies may affect your ability to fully utilize of
							our Platform and services. Without cookies you may not have access
							to certain features on the site, including access to certain
							personalized content.
							<br />
							<br />
							Phasecurve may revise this Cookies Policy to reflect changes in
							the law, our data collection and use practices, the features of
							our site, or advancements in technology. If we make any material
							changes, we will notify you by email or through a prominent notice
							on our sites/platforms prior to such change.
						</Text>
					</Stack>

					<Stack>
						<Heading as="h2" fontSize="24px">
							Data Retention
						</Heading>
						<Heading as="h2" fontSize="24px" pb="20px">
							Introduction
						</Heading>

						<Text
							color={colorMode === 'light' ? 'gray' : 'darkGray'}
							maxW="750px"
							fontSize="16px"
							lineHeight="30px"
						>
							This Data Retention Policy (the 'Policy') sets out how Phasecurve
							retains Records (defined below) necessary for its business
							operations, contractual obligations, legal, audit and regulatory
							compliance, and when we may destroy such Records, whilst promoting
							orderly and efficient data management. This ensures compliance
							with the data protection laws and the duty of confidentiality to
							which we are bound. We retain your information for as long as
							necessary for the purpose(s) for which it was collected. The
							period of data storage and retention is subject to legal,
							regulatory, administrative and operational requirements.
							<br />
							<br />
							Records include Information, data, documents (contractual,
							personal), correspondence, policies, confidential information,
							etc. regardless of media; created, received, and maintained by
							Phasecurve as evidence, and in compliance with regulatory
							requirements, legal obligations, business practices, legal
							policies, contractual obligations, financial transactions,
							administrative activities, business decisions or other actions.
							Records provide information about, and evidence of Phasecurve’s
							transactions, customers, employment and activities, and may be
							created, received or maintained in hard copy or electronic format.
						</Text>
					</Stack>

					<Stack>
						<Heading as="h2" fontSize="24px">
							Scope of this Policy
						</Heading>

						<Text
							color={colorMode === 'light' ? 'gray' : 'darkGray'}
							maxW="750px"
							fontSize="16px"
							lineHeight="30px"
						>
							The main objective of this Policy is to ensure that complete and
							accurate Records are identified, retained, stored, protected and
							subsequently destroyed or archived appropriately under such terms
							and within such periods set out in this Policy.
							<br />
							<br />
							This Policy applies to all our Platforms, and all related sites,
							applications, services and tools regardless of how they are
							accessed or used. It also covers all Records stored on
							company-owned, leased, and otherwise company-provided systems and
							media, regardless of location, including our servers, third-party
							servers; computer systems (desktops or laptops), mobile devices,
							physical records stored at our offices or other locations.
						</Text>
					</Stack>

					<Stack>
						<Heading as="h2" fontSize="24px">
							Policy Administrator
						</Heading>

						<Text
							color={colorMode === 'light' ? 'gray' : 'darkGray'}
							maxW="750px"
							fontSize="16px"
							lineHeight="30px"
						>
							Phasecurve’s Data Compliance Officer (DCO) shall oversee the
							administration, implementation and the procedure of this Policy.
							The DCO work with the company’s management and legal & compliance
							department and shall ensure that the retention policy is followed
							to the latter. The DCO has the authority to review the data
							retention procedures; monitor compliance; make modifications to
							the data retention schedule when necessary to ensure it complies
							with the relevant laws, encompasses new developments and contains
							the appropriate records and document.
						</Text>
					</Stack>

					<Stack>
						<Heading as="h2" fontSize="24px" pb="20px">
							Security measures
						</Heading>

						<Text
							color={colorMode === 'light' ? 'gray' : 'darkGray'}
							maxW="750px"
							fontSize="16px"
							lineHeight="30px"
						>
							In addition to other existing data protection measures being
							implemented by Phasecurve, we will ensure that the following
							measures are in place to protect the security of retained Records:
							<UnorderedList
								color={colorMode === 'light' ? 'black' : 'darkGray'}
								pl="30px"
							>
								<ListItem>
									All Records received and retained through emails must be
									encrypted and/or password protected and will only be
									transmitted and stored over secure networks.
								</ListItem>
								<ListItem>
									All Records retained physically (in hardcopy form) will be
									stored in a secure container, file or folder, marked
									“confidential” and kept in a safe location.
								</ListItem>
								<ListItem>
									Records whether physical or electronic will be stored in
									secure folders and will not be left unattended.
								</ListItem>
								<ListItem>
									Computers used to retain Records will always be password
									protected, properly maintained, and protected from virus or
									unauthorized access.
								</ListItem>
								<ListItem>
									Retained Records will not be stored in the personal devices of
									employees of the company
								</ListItem>
								<ListItem>
									All Records stored electronically will be backed up
									periodically with backups stored onsite at our offices or
									offsite at a data centre.
								</ListItem>
								<ListItem>
									All electronic copies of Records will be stored securely using
									passwords and encryption as appropriate.
								</ListItem>
								<ListItem>
									Records will be retained in a secure location, with limited
									access to authorised personnel only.
								</ListItem>
								<ListItem>
									All passwords used to protect Records will be changed
									periodically and must be secure
								</ListItem>
								<ListItem>
									All Records retained during their specified periods will be
									traceable and retrievable.
								</ListItem>
							</UnorderedList>
						</Text>
					</Stack>

					<Stack>
						<Heading as="h2" fontSize="24px" pb="20px">
							Retention Period and Procedures
						</Heading>

						<Text
							color={colorMode === 'light' ? 'gray' : 'darkGray'}
							maxW="750px"
							fontSize="16px"
							lineHeight="30px"
						>
							For all Records obtained, used and stored by Phasecurve, we will
							conduct periodic reviews of the Records retained, confirming their
							purpose, continued validity, accuracy and requirement to retain
							such Records. Records will be kept for as long as they are needed
							to meet the operational or contractual needs of Phasecurve,
							together with legal and regulatory requirements. Phasecurve may
							transfer paper-based Records to an alternative media format in
							instances of long retention periods. As a general principle,
							Phasecurve will only need to retain one copy of the final version
							of a Record. Draft documents may be destroyed immediately unless
							they contain vital information which were not otherwise contained
							the final document.
							<br />
							<br />
							The retention periods for Records provided under this Policy takes
							into consideration: the requirements of Phasecurve for such
							Records;
							<UnorderedList
								color={colorMode === 'light' ? 'black' : 'darkGray'}
								pl="30px"
							>
								<ListItem>
									The type, sensitivity and importance of such Record;
									contractual obligations;
								</ListItem>
								<ListItem>
									The purpose of processing such record; lawful basis for
									processing the records;
								</ListItem>
								<ListItem>The requirements of the law.</ListItem>
							</UnorderedList>
							Where it is not possible to define a statutory or legal retention
							period in line with the applicable data protection/privacy laws,
							we will identify the criteria by which the period can be
							determined (such as industry standards) and update this Policy as
							may be necessary.
						</Text>
					</Stack>

					<Stack>
						<Heading as="h2" fontSize="24px" pb="20px">
							Destruction of Records
						</Heading>

						<Text
							color={colorMode === 'light' ? 'gray' : 'darkGray'}
							maxW="750px"
							fontSize="16px"
							lineHeight="30px"
						>
							Destruction of Records is a critical component of a data retention
							policy. Once the retention period has elapsed, the Records are
							either reviewed, archived, returned, anonymized or confidentially
							destroyed depending on their purpose, importance and legal
							requirements. When a Record has reached its designated destruction
							date, the DCO shall refer to the retention register to determine
							the next action to be taken. Not all data or records are expected
							to be deleted upon expiration; sometimes it is sufficient to
							anonymise the data, return the Record or to archive Records for a
							further period.
							<br />
							<br />
							Records will not be kept after the retention period unless:
							<UnorderedList
								color={colorMode === 'light' ? 'black' : 'darkGray'}
								pl="30px"
							>
								<ListItem>
									The Record is the subject of a pending litigation,
									administrative or regulatory proceedings. In these
									circumstances, destruction will be delayed until such
									regulatory proceedings have been completed, at which time a
									new retention period will be created.
								</ListItem>
								<ListItem>
									The Record has long-term value for Phasecurve’s statutory or
									regulatory obligations.
								</ListItem>
								<ListItem>
									Phasecurve has the discretion to determine if such Record will
									be permanently stored, provided that no legal, regulatory or
									contractual breach will occur by the permanent retention of
									such record.
								</ListItem>
								<ListItem>
									There is a legal or regulatory reason or order to maintain the
									Records either permanently or for a longer period.
								</ListItem>
							</UnorderedList>
							Phasecurve is committed to the secure and safe disposal of any
							Record in accordance with our contractual and legal obligations.
							Accordingly, when the Records have been retained until the end of
							their retention period, they can be destroyed in the following
							ways:
							<UnorderedList
								color={colorMode === 'light' ? 'black' : 'darkGray'}
								pl="30px"
							>
								<ListItem>
									Hardcopy of documents will be destroyed by shredding or
									burning.
								</ListItem>
								<ListItem>
									Non-sensitive information can be thrown in a normal trash bin
									and properly disposed.
								</ListItem>
								<ListItem>
									Electronic Records will be deleted /erased and cleared from
									recycle bins.
								</ListItem>
								<ListItem>
									Electronic equipment or systems containing Records will be
									destroyed. Where a specific Record is stored in one single
									media such as a CD Rom and cannot be erased, such media used
									to store the Record will be physically destroyed.
								</ListItem>
								<ListItem>
									Destruction of electronic Records should render them
									non-recoverable even using forensic data recovery techniques.
								</ListItem>
							</UnorderedList>
						</Text>
					</Stack>

					<Stack>
						<Heading as="h2" fontSize="24px">
							Governing Law
						</Heading>

						<Text
							color={colorMode === 'light' ? 'gray' : 'darkGray'}
							maxW="750px"
							fontSize="16px"
							lineHeight="30px"
						>
							<b>The laws of NIgeria,</b> e shall govern this Agreement and your
							use of our service. Your use of our service may also be subject to
							other local, state, national, or international laws.
						</Text>
					</Stack>

					<Stack>
						<Heading as="h2" fontSize="24px">
							Your Consent
						</Heading>

						<Text
							color={colorMode === 'light' ? 'gray' : 'darkGray'}
							maxW="750px"
							fontSize="16px"
							lineHeight="30px"
						>
							By using our products and/or services, registering an account, or
							making a purchase, you consent to this Privacy Policy.
						</Text>
					</Stack>

					<Stack>
						<Heading as="h2" fontSize="24px">
							Links to Other Websites
						</Heading>

						<Text
							color={colorMode === 'light' ? 'gray' : 'darkGray'}
							maxW="750px"
							fontSize="16px"
							lineHeight="30px"
						>
							Our service may contain links to other websites that are not
							operated by Us. If You click on a third party link, You will be
							directed to that third party's site. We strongly advise You to
							review the Privacy Policy of every site You visit. We have no
							control over and assume no responsibility for the content, privacy
							policies or practices of any third party sites or services.
						</Text>
					</Stack>

					<Stack>
						<Heading as="h2" fontSize="24px">
							Contact Us
						</Heading>

						<Text
							color={colorMode === 'light' ? 'gray' : 'darkGray'}
							maxW="750px"
							fontSize="16px"
							lineHeight="30px"
						>
							If you have questions regarding your data privacy rights or would
							like to submit a related data privacy right request, kindly
							contact us via the information below:
							<UnorderedList
								color={colorMode === 'light' ? 'black' : 'darkGray'}
								pl="30px"
							>
								<ListItem>
									<b> Phone:</b> +2347063290023
								</ListItem>
								<ListItem>
									<b> Email:</b> info@phasecurve.com
								</ListItem>
								<ListItem>
									<b> Address:</b> B2 Comdale Place,1 Abisogun Road, Off Palace
									Road, Oniru, Lekki Lagos.
								</ListItem>
							</UnorderedList>
						</Text>
					</Stack>

					<Stack>
						<Heading as="h2" fontSize="24px">
							Amendments
						</Heading>

						<Text
							color={colorMode === 'light' ? 'gray' : 'darkGray'}
							maxW="750px"
							fontSize="16px"
							lineHeight="30px"
						>
							Phasecurve may revise this Privacy Policy from time to time by
							posting a revised Privacy Policy on our Website. We reserve the
							right to modify this Privacy Policy at any time. Notice of the
							amendment shall be posted on our website and the revised version
							will be effective from the date of publication. You are advised to
							read and review our terms/privacy policy frequently.
						</Text>
					</Stack>

					<Box
						position="absolute"
						top="15px"
						left={0}
						display={{ base: 'none', md: 'unset' }}
					>
						{Hstribe}
					</Box>
					<Box
						display={{ base: 'none', md: 'unset' }}
						position="absolute"
						top={200}
						right={{ base: '126px', xl: '10%' }}
					>
						{colorMode === 'light' ? logoDot : logoDotWhite}
					</Box>
				</Stack>
			</Container>
		</Box>
	);
};

export default PrivacyPolicy;
