import { Box } from '@chakra-ui/react';
import Intro from 'components/community/Intro';
import { FC } from 'react';

const Community: FC = () => {
	return (
		<Box>
			<Intro />
		</Box>
	);
};

export default Community;
