import { Flex, Heading, Text, useColorMode } from '@chakra-ui/react';
import React, { FC } from 'react';

type Props = {
	text: string;
	title: string;
	textW: any;
	bgImg: any;
	color: any;
};
const SdlcCard: FC<Props> = ({ title, bgImg, text, textW, color }) => {
	const { colorMode } = useColorMode();
	return (
		<Flex
			w="100%"
			flexDir="column"
			justify={{ base: 'flex-start', sm: 'center' }}
			borderRadius="40px"
			pt={{ base: '41px', sm: '0px' }}
			pl={{ base: '28px', sm: '55px' }}
			minH={{ base: '362px', sm: '419px' }}
			fontFamily="bold"
			bgImage={bgImg}
			bgSize="cover"
			bgPos="center"
			bgRepeat="no-repeat"
		>
			<Heading
				as="h4"
				color={color}
				maxW={{ base: '175px', sm: '267px' }}
				mb="30px"
				fontWeight="700"
				fontSize={{ base: '24px', sm: '36px' }}
			>
				{title}
			</Heading>
			<Text color={colorMode === 'light' ? 'black' : 'darkGray'} maxW={textW}>
				{text}
			</Text>
		</Flex>
	);
};

export default SdlcCard;
