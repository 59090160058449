import {
	Box,
	Flex,
	Grid,
	GridItem,
	Heading,
	Stack,
	Text,
	useColorMode,
} from '@chakra-ui/react';

import React, { FC } from 'react';
import EventCard from './EventCard';
import CommonBtn from 'components/common/CommonBtn';
import { Link } from 'react-router-dom';
import { EventList } from 'data/EventList';

const ExploreEvents: FC = () => {
	const { colorMode } = useColorMode();
	return (
		<Box
			fontFamily="bold"
			pt="14px"
			px={{ base: '20px', sm: '30px', md: '103px', lg: '72px' }}
			pb={{ base: '64px', md: '74px', lg: '104px' }}
		>
			<Flex flexDir="column" alignItems="center" justify="center">
				<Grid
					display={{ base: 'none', md: 'grid' }}
					templateRows="repeat(2, 1fr)"
					templateColumns="repeat(5, 1fr)"
					gap={{ md: '20px', lg: '26px' }}
				>
					<GridItem rowSpan={2} colSpan={1}>
						<Flex flexDir="column" justify="space-between" h="100%">
							<Stack spacing="15px">
								<Heading
									fontSize="36px"
									fontWeight="700"
									lineHeight="2rem"
									maxW="161px"
								>
									Explore
									<Heading fontSize="36px" fontWeight="700" color="purple">
										{' '}
										Events
									</Heading>
								</Heading>
								<Text
									fontSize={{ base: '16px', md: '1.1rem' }}
									fontWeight="400"
									lineHeight="28px"
									maxW="195px"
									color={colorMode === 'light' ? 'gray' : 'darkGray'}
								>
									We organise workshops and tech events to accommodate many
									passionate about learning, growing and building their
									skill-set for the future.
								</Text>
							</Stack>

							<Link to="/community/events">
								{' '}
								<CommonBtn
									bg="purple"
									color="white"
									height="60px"
									minW={{ base: '141px', sm: '143px' }}
									text="Explore"
								/>
							</Link>
						</Flex>
					</GridItem>
					{EventList.slice(0, 4).map((item, index) => (
						<GridItem colSpan={2} key={index}>
							<Link to={`/community/event/${item.id}`}>
								<EventCard
									id={item.id}
									title={item.title}
									bgImg={item.bgImg}
									date={`${item.date} - ${item.title}`}
									cardTextColor={item.cardTextColor}
									titleColor={item.titleColor}
									desc={item.desc}
									descColor={item.descColor}
									linkText={'Learn More'}
								/>
							</Link>
						</GridItem>
					))}
				</Grid>

				<Flex
					display={{ base: 'flex', md: 'none' }}
					align="center"
					justify="center"
					flexDir="column"
				>
					<Heading
						fontSize={{ base: '32px', sm: '36px' }}
						pb="25px"
						textAlign="center"
						fontWeight="700"
					>
						Explore
						<Heading
							as="span"
							fontSize={{ base: '32px', sm: '36px' }}
							fontWeight="700"
							color="purple"
						>
							{' '}
							Events
						</Heading>
					</Heading>
					<Text
						fontSize={{ base: '16px', sm: '20px' }}
						fontWeight="400"
						lineHeight="30px"
						textAlign="center"
						pb="47px"
						maxW="550px"
						color={colorMode === 'light' ? 'gray' : 'darkGray'}
					>
						We organise workshops and tech events to accommodate those
						passionate about learning, growing and building their skill-set for
						the future.
					</Text>

					<Grid
						pb="37px"
						templateColumns={{ base: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}
						gap="30px"
					>
						{EventList.slice(0, 4).map((item) => (
							<GridItem w="100%" key={item.id}>
								<Link to={`/community/event/${item.id}`}>
									<EventCard
										id={item.id}
										title={item.title}
										linkText={item.linkText}
										bgImg={item.bgImg}
										date={item.date}
										titleColor={item.titleColor}
										desc={item.desc}
										cardTextColor={item.cardTextColor}
										descColor={item.descColor}
									/>
								</Link>
							</GridItem>
						))}
					</Grid>
				</Flex>
			</Flex>
		</Box>
	);
};

export default ExploreEvents;
