/* eslint-disable prettier/prettier */

import { Box, Container, Flex, Stack, useColorMode, Heading, Text, Image } from "@chakra-ui/react";
import React from "react";
// import { Fade } from 'react-reveal'
import Career from '../../assets/image/career/career-1.png';



const Overview = () => {
  const { colorMode } = useColorMode();
  return (
		<Box bgColor={colorMode === 'light' ? 'white' : '#000'}>
			<Container
				maxW="144rem"
				p={{
					base: '45px 40px 30px 40px',
					sm: '65px 50px 55px 50px',
					md: '87px 52px 95px 52px',
					lg: '101px 72px 104px 72px',
				}}
        // pr={{base: '40px', md:'40px'}}
			>
				<Stack
					fontFamily="bold"
					position="relative"
				>
					<Flex 
          pb="150px"
          justify={{base: 'center',md:'space-between'}}
          flexDir={{ base: 'column', md: 'row' }}
          >
          
						<Box 
            w={{ base: '100%', md: '50%' }}
            pb={{base:'100px', md: 0}}
            pt={{base:'50px', md: 0}}
            >
              <Image
                zIndex={5}
                borderRadius="40px"
                src={Career}
                alt="phasecurve image"
              />
             
					</Box>
      
						<Stack w={{ base: '100%', md: '50%' }} >
            
							<Heading
								fontFamily="bold"
                textAlign="center"
								fontWeight="700"
								fontSize={{base: '30px', sm: '36px', md: '40px'}}
								pb={{ base: '27px', md: '35px' }}
								color={colorMode === 'light' ? 'black' : 'white'}
							>
						
                 We are always on the look 
                 out for new <Heading 
                 as="span"
                 fontFamily="bold"
                 fontWeight="700"
                 fontSize={{base: '30px', sm: '36px', md: '40px'}}
                 color={colorMode === 'light' ? 'purple' : 'white'}
                >
                  Talent
                </Heading>
							</Heading>
							
							<Text
								fontFamily="bold"
                fontSize={{base: '20px', md: '24px'}}
								textAlign="justify"
                // maxW={{base: '450px', sm: '850px', md: 0}}
								color={colorMode === 'light' ? 'gray' : 'white'}
							>
								Join a high performing multifunctional team of technology
                professionals working across a variety of industries and challenging engagements.
							</Text>
						</Stack>
         
					</Flex>
					
          <Heading
            fontFamily="bold"
            fontWeight="700"
            textAlign="center"
            fontSize={{base: '26px', sm: '30px', md: '36px'}}
            pb={{ base: '57px', md: '105px' }}
            color={colorMode === 'light' ? 'black' : 'white'}
          >
            Benefits of working for Phasecurve
          </Heading>
					
							
            <Flex 
            flexDir={{ base: 'column', md: 'row' }}
            >
            <Box pr={{md:'30px'}}>
                <Text
                  fontFamily="bold"
                  lineHeight="24px"
                  fontSize={{base:'20px', md: '24px'}}
                  fontWeight="700"
                  pb='10px'
                  color={colorMode === 'light' ? 'black' : 'white'}
                >
                  Flexible Working
                </Text>
                <Text
                  fontFamily="bold"
                  fontSize={{ base: '14px', sm: '16px', md: '20px' }}
                  textAlign="justify"
                  pb='30px'
                  color={colorMode === 'light' ? 'gray' : 'white'}
                >
                  We offer a variety of flexible working options to our employees based in the UK. 
                  We also welcome remote working applications 
                  from outside the UK for our software and platform engineering roles.
                </Text>
                <Text
                  fontFamily="bold"
                  lineHeight="24px"
                  fontSize={{base:'20px', md: '24px'}}
                  fontWeight="700"
                  pb='10px'
                  color={colorMode === 'light' ? 'black' : 'white'}
                >
                  Autonomy
                </Text>
                <Text
                  fontFamily="bold"
                  fontSize={{ base: '14px', sm: '16px', md: '20px' }}
                  textAlign="justify"
                  pb='30px'
                  color={colorMode === 'light' ? 'gray' : 'white'}
                >
                  We prioritise flexibility in how our employees manage their time. 
                  We understand that everyone has unique schedules and responsibilities outside of work, 
                  and we strive to accommodate those needs.
                </Text>
                <Text
                  fontFamily="bold"
                  lineHeight="24px"
                  fontSize={{base:'20px', md: '24px'}}
                  fontWeight="700"
                  pb='10px'
                  color={colorMode === 'light' ? 'black' : 'white'}
                >
                  Comprehensive Benefits Package
                </Text>
                <Text
                  fontFamily="bold"
                  fontSize={{ base: '14px', sm: '16px', md: '20px' }}
                  textAlign="justify"
                  pb='30px'
                  color={colorMode === 'light' ? 'gray' : 'white'}
                >
                  We also provide a comprehensive benefits package that includes health insurance, 
                  dental and vision coverage, retirement plans, paid time off, and parental leave. 
                  A strong benefits package demonstrates the company's commitment to employee welfare.
                </Text>
               
            </Box>
              <Box>
                <Text
                  fontFamily="bold"
                  lineHeight="24px"
                  fontSize={{base:'20px', md: '24px'}}
                  fontWeight="700"
                  pb='10px'
                  color={colorMode === 'light' ? 'black' : 'white'}
                >
                  Personal Development
                </Text>
                <Text
                  fontFamily="bold"
                  fontSize={{ base: '14px', sm: '16px', md: '20px' }}
                  textAlign="justify"
                  pb='30px'
                  color={colorMode === 'light' ? 'gray' : 'white'}
                >
                  We offer professional development opportunities to help employees enhance their skills 
                  and advance in their careers. 
                  This may include access to training programs, workshops, conferences, and mentorship programs.
                </Text>
                <Text
                  fontFamily="bold"
                  lineHeight="24px"
                  fontSize={{base:'20px', md: '24px'}}
                  fontWeight="700"
                  pb='10px'
                  color={colorMode === 'light' ? 'black' : 'white'}
                >
                  Collaborative and Innovative Work Environment
                </Text>
                <Text
                  fontFamily="bold"
                  fontSize={{ base: '14px', sm: '16px', md: '20px' }}
                  textAlign="justify"
                  pb='30px'
                  color={colorMode === 'light' ? 'gray' : 'white'}
                >
                  We foster a culture of collaboration, creativity, and innovation. 
                  They may provide open workspaces, dedicated areas for brainstorming and idea sharing, 
                  and encourage employees to contribute to projects outside their immediate roles.
                </Text>
                <Text
                  fontFamily="bold"
                  lineHeight="24px"
                  fontSize={{base:'20px', md: '24px'}}
                  fontWeight="700"
                  pb='10px'
                  color={colorMode === 'light' ? 'black' : 'white'}
                >
                  Recognition and Rewards
                </Text>
                <Text
                  fontFamily="bold"
                  fontSize={{ base: '14px', sm: '16px', md: '20px' }}
                  textAlign="justify"
                  pb='30px'
                  color={colorMode === 'light' ? 'gray' : 'white'}
                >
                  We understand that recognising and rewarding employee contributions is important 
                  for morale and motivation. So we have recognition programs in place, 
                  such as employee of the month/year awards, bonuses for exceptional performance, 
                  or team-building activities to celebrate achievements.
                </Text>
              </Box>
            </Flex>
				</Stack>
			</Container>
		</Box>
	);
}

export default Overview;