/* eslint-disable prettier/prettier */
import { FC } from 'react';
import {
	Box,
	Container,
	Flex,
	HStack,
	Icon,
	Stack,
	Text,
	useColorMode,
} from '@chakra-ui/react';
import { phasecurve, phasecurveLight } from 'assets/svgs/header';
import { Link } from 'react-router-dom';
import { IoLogoTwitter, IoLogoInstagram, IoLogoLinkedin } from 'react-icons/io';
import { MdOutlineFacebook } from 'react-icons/md';
import { bottomPattern, bottomWhitePattern } from 'assets/svgs/home';

export const Footer: FC = () => {
	const { colorMode } = useColorMode();
	return (
		<Box bgColor={colorMode === 'light' ? 'white' : '#000'}>
			<Container
				maxW="1440px"
				p={{
					base: '80px 20px 50px',
					sm: '90px 50px 50px',
					md: '128px 52px 128px 52px',
					lg: '128px 76px 128px 73px',
				}}
			>
				<Stack spacing={{ base: '50px', md: '110px' }}>
					<Flex
						justify={{ base: 'flex-start', sm: 'space-between' }}
						flexWrap="wrap"
					>
						<Box
							mb={{ base: '50px', md: '0px' }}
							w={{ base: '100%', md: '25%' }}
						>
							{colorMode === 'light' ? (
								<Box>
									<Link to="/">{phasecurve}</Link>
								</Box>
							) : (
								<Box>
									<Link to="/">{phasecurveLight}</Link>
								</Box>
							)}
						</Box>
						<Flex
							w={{ base: '100%', md: '25%' }}
							flexDir="column"
							pb={{ base: '30px', md: '0px' }}
						>
							<Text pb="10px" fontWeight={700} mb="10px">
								Sitemap
							</Text>
							<Flex gap={{ base: '25%', sm: '50%', md: '20%', lg: '25%' }}>
								<Stack spacing="10px">
									<Text
										color={colorMode === 'light' ? 'black' : 'darkGray'}
										transition="0.2s ease-in-out"
										_hover={{
											color: '#94A3B8',
										}}
									>
										<Link to="/">Home </Link>
									</Text>
									<Text
										transition="0.2s ease-in-out"
										_hover={{
											color: '#94A3B8',
										}}
										color={colorMode === 'light' ? 'black' : 'darkGray'}
									>
										<Link to="/about">About Us </Link>
									</Text>
									<Text
										transition="0.2s ease-in-out"
										_hover={{
											color: '#94A3B8',
										}}
										color={colorMode === 'light' ? 'black' : 'darkGray'}
									>
										<Link to="/programme">Programme</Link>
									</Text>
									<Text
										transition="0.2s ease-in-out"
										_hover={{
											color: '#94A3B8',
										}}
										color={colorMode === 'light' ? 'black' : 'darkGray'}
									>
										<Link to="/services"> Services</Link>
									</Text>

									<Text
										transition="0.2s ease-in-out"
										_hover={{
											color: '#94A3B8',
										}}
										color={colorMode === 'light' ? 'black' : 'darkGray'}
									>
										<Link to="/contact"> Contact Us</Link>
									</Text>
								</Stack>

								<Stack spacing="10px">
									<Text
										transition="0.2s ease-in-out"
										_hover={{
											color: '#94A3B8',
										}}
										color={colorMode === 'light' ? 'black' : 'darkGray'}
									>
										<Link to="/our-team"> Our Team</Link>
									</Text>
									<Text
										transition="0.2s ease-in-out"
										_hover={{
											color: '#94A3B8',
										}}
										color={colorMode === 'light' ? 'black' : 'darkGray'}
									>
										<Link to="/contact/faqs"> FAQs</Link>
									</Text>
									<Text
										transition="0.2s ease-in-out"
										_hover={{
											color: '#94A3B8',
										}}
										color={colorMode === 'light' ? 'black' : 'darkGray'}
									>
										<Link to="/careers">Careers</Link>
									</Text>
									<Text
										transition="0.2s ease-in-out"
										_hover={{
											color: '#94A3B8',
										}}
										color={colorMode === 'light' ? 'black' : 'darkGray'}
									>
										<Link to="/portfolio"> Portfolio</Link>
									</Text>
									<Text
										transition="0.2s ease-in-out"
										_hover={{
											color: '#94A3B8',
										}}
										color={colorMode === 'light' ? 'black' : 'darkGray'}
										visibility="hidden"
									>
										<Link to="/community/events">Events</Link>
									</Text>

									<Text
										transition="0.2s ease-in-out"
										_hover={{
											color: '#94A3B8',
										}}
										color={colorMode === 'light' ? 'black' : 'darkGray'}
										visibility="hidden"
									>
										<Link to="/community/blog"> Blog</Link>
									</Text>
								</Stack>
							</Flex>
						</Flex>

						<Stack
							spacing="10px"
							pb={{ base: '30px', md: '0px' }}
							mt={{ base: '20px', md: '0px' }}
							w={{ base: '100%', md: '25%' }}
						>
							<Text fontWeight={700} mb="10px">
								Services
							</Text>
							<Text color={colorMode === 'light' ? 'black' : 'darkGray'}>
								Software Training
							</Text>
							<Text color={colorMode === 'light' ? 'black' : 'darkGray'}>
								IT Consultation
							</Text>
							<Text color={colorMode === 'light' ? 'black' : 'darkGray'}>
								Software Development
							</Text>

							<Text color={colorMode === 'light' ? 'black' : 'darkGray'}>
								UI/UX Design{' '}
							</Text>
						</Stack>

						<Stack
							spacing="10px"
							mt={{ base: '20px', md: '0px' }}
							w={{ base: '100%', md: '25%' }}
						>
							<Text fontWeight={700} mb="10px">
								Offices
							</Text>
							<Text
								pb={{ base: '20px', md: '35px' }}
								lineHeight="22px"
								color={colorMode === 'light' ? 'black' : 'darkGray'}
							>
								Head Office: B2, 28 Abisogun Road, Oniru,
								<br /> Victoria Island, Lagos Nigeria
								<br />
								<br />
								1(b) Ridgeway, Papworth Everard, Cambridge, Cambridgeshire. CB23
								3RW UK
							</Text>
							<HStack
								spacing="16px"
								fontSize="24px"
								color={colorMode === 'light' ? 'black' : 'white'}
							>
								<Link
									to="https://www.facebook.com/Phasecurve-101743435951109"
									target="_blank"
								>
									<Icon
										as={MdOutlineFacebook}
										transition="0.5s ease"
										_hover={{ cursor: 'pointer', transform: 'scale(1.2)' }}
									/>
								</Link>
								<Link to="https://twitter.com/phasecurve" target="_blank">
									<Icon
										as={IoLogoTwitter}
										transition="0.5s ease"
										_hover={{ cursor: 'pointer', transform: 'scale(1.2)' }}
									/>
								</Link>
								<Link
									to="https://www.instagram.com/phasecurve/"
									target="_blank"
								>
									<Icon
										as={IoLogoInstagram}
										transition="0.5s ease"
										_hover={{ cursor: 'pointer', transform: 'scale(1.2)' }}
									/>
								</Link>
								<Link
									to="https://www.linkedin.com/company/phasecurve/"
									target="_blank"
								>
									<Icon
										as={IoLogoLinkedin}
										transition="0.5s ease"
										_hover={{ cursor: 'pointer', transform: 'scale(1.2)' }}
									/>
								</Link>
							</HStack>
						</Stack>
					</Flex>
					<Flex
						justify="space-between"
						// flexWrap={{ base: "wrap", md: "nowrap" }}
						flexDir={{ base: 'column', md: 'row' }}
						fontFamily="bold"
					>
						<HStack
							spacing={{ base: '30px', md: '24px', lg: '44px' }}
							mb={{ base: '50px', md: '0px' }}
							fontSize={{ base: '14px', sm: '18px' }}
						>
							<Link to="mailto:info@phasecurve.com" target="_blank">
								<Text borderBottom="1px solid #6C4CDF" pb="10px">
									info@phasecurve.com
								</Text>
							</Link>
							<Text borderBottom="1px solid #6C4CDF" pb="10px">
								+234 809 422 1930
							</Text>
						</HStack>
						<Flex
							flexDir={{ base: 'column', sm: 'row' }}
							align={{ base: 'flex-start', sm: 'center' }}
							gap={{ base: '15px', sm: '25px' }}
							color={colorMode === 'light' ? '#94A3B8' : 'darkGray'}
							fontSize="14px"
							// visibility="hidden"
							textAlign="center"
						>
							<Text>Legal Terms of Service</Text>
							<Link to="/privacy-policy">
								<Text _hover={{ textDecoration: 'underline' }}>
									Terms & Conditions Privacy Policy
								</Text>
							</Link>
							<Text>© 2022 Phasecurve</Text>
						</Flex>
					</Flex>
				</Stack>
				<Box
					pos="absolute"
					bottom={0}
					right={0}
					display={colorMode === 'light' ? 'unset' : 'none'}
				>
					{bottomPattern}
				</Box>
				<Box
					pos="absolute"
					bottom={0}
					right={0}
					display={colorMode === 'light' ? 'none' : 'unset'}
				>
					{bottomWhitePattern}
				</Box>
			</Container>
		</Box>
	);
};
