/* eslint-disable prettier/prettier */
import { Box } from '@chakra-ui/react';
import Intro from 'components/portfolio/Intro';
import PortfolioComp from 'components/portfolio/Portfolio';

import { FC } from 'react';

const Portfolio: FC = () => {
	return (
		<Box>
			<Intro/>
			<PortfolioComp/>
			
		</Box>
	);
};

export default Portfolio;
