/* eslint-disable prettier/prettier */
import { stribe } from 'assets/svgs/service';
import {
	Box,
	Heading,
	Stack,
	Input,
	Container,
	useColorMode,
	Textarea,
	HStack,
	Select
} from '@chakra-ui/react';
import CustomInput from 'components/common/CustomInput';
import CustomBtn from 'components/common/CommonBtn';


const CareersForm = () => {
	const { colorMode } = useColorMode();
	return (
		<Box bgColor={colorMode === 'light' ? 'white' : '#000'}>
			<Container
				maxW="1440px"
				p={{
					base: '48px 20px 74px 20px',
					sm: '58px 50px 74px 50px',
					md: '78px 103px 74px 103px',
					lg: '78px 182px 74px 186px',
				}}
				pos="relative"
			>
				<form >
              <Stack spacing="24px">
                <Heading 
								fontFamily="bold"
								lineHeight="1.5"
								fontWeight="700"
								fontSize="36px"
								pb={{ base: '27px', md: '15px' }}
								color={colorMode === 'light' ? 'black' : 'white'}
								>
                  Application Form
                </Heading>

								<HStack spacing={{ base: '12px', sm: '20px', md: '30px', lg:'30px' }} >
									<Box w="100%">
										<CustomInput
											width={{ base: '100%', md: '400px', lg: '100%' }}
											padding="20px 15px"
											height="63px"
											name="firstName"
											type="text"
											placeholder={'First Name'}
										/>
									</Box>
									<Box w="100%">
										<CustomInput
											width={{ base: '100%', md: '400px', lg: '100%' }}
											padding="20px 15px"
											height="63px"
											name="lastName"
											type="text"
											placeholder={'Last Name'}
										/>
									</Box>
								</HStack>

                <CustomInput
									type="text"
									placeholder="Email Address"
									name="email" 
									height='63px' 
									padding={''}
                />

                <CustomInput
									type="number"
									placeholder="Phone Number"
									name="phone number" 
									height={'63px'} 
									padding={''}
                />

                <CustomInput
									placeholder="Location"
									name="location" 
									height={'63px'} 
									padding={''} 
									type='text'
              	/>
							

								<Select
								 	placeholder='Role'
									height={'63px'} 
								>
								 		<option value='Senior frontend Developer - C# / Typescript - Permanent'>Senior frontend Developer - C# / Typescript - Permanent</option>
  									<option value='Senior frontend Developer - HTML/CSS- Contract'>Senior frontend Developer - HTML/CSS- Contract</option>
  									<option value='Senior Backend Developer - Javascript / Kotlin - Permanent'>Senior Backend Developer - Javascript / Kotlin - Permanent</option>
  									<option value='Lead Human Resoure - Permanent'>Lead Human Resoure - Permanent</option>
  									<option value='UI Designer - Figma - Permanent'>UI Designer - Figma - Permanent</option>
								</Select>

								<CustomInput 
									height={'63px'} 
									padding={'15px'} 
									type={'url'} 
									placeholder={'LinkedIn Url'}
       						pattern="https://.*" 
									size="30"
       						required
								/>

								<label htmlFor="file">Choose CV/Resume</label>
								<Input 
									type="file"
									border="none"
									id="file" 
									name="file"
									accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
								/>

								<label htmlFor="message">Send us a message</label>
                <Textarea
									id="message"
									_hover={{ border: '2px solid #6C4CDF' }}
									focusBorderColor="#6C4CDF"
									border="1px solid #94A3B8" 
									required
                />
								
                <CustomBtn
                  bg="purple"
									color="white"
									height="60px"
									width={{ base: '167px', sm: '168px' }}
									text="Submit"
                  alignSelf="flex-end"
                />
              </Stack>
							</form>
					<Box pos="absolute" top={{ base: -50, sm: 10, md: 100 }} left={0}>
							{stribe}
					</Box>
			</Container>
		</Box>
	);
};

export default CareersForm;
