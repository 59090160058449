import {
	Box,
	Container,
	Flex,
	Heading,
	VStack,
	useColorMode,
} from '@chakra-ui/react';
import { FC } from 'react';

import ValuesCard from './ValuesCard';
import { valueStribe } from 'assets/svgs/about';
import { ValueList } from 'data/ValueList';

const OurValues: FC = () => {
	const { colorMode } = useColorMode();
	return (
		<Box bgColor={colorMode === 'light' ? 'white' : '#000'}>
			<Container
				maxW="1440px"
				pt={{ base: '38px', sm: '99px' }}
				pb={{ base: '33px', sm: '40px', md: '83px' }}
				px={{ base: '21px', sm: '50px', md: '103px', lg: '5%' }}
				pos="relative"
			>
				<Flex flexDir="column" align="center" fontFamily="bold">
					<VStack
						spacing={{ base: '28px', md: '48px' }}
						textAlign="center"
						pb={{ base: '64px', sm: '74px', md: '108px' }}
					>
						<Heading
							as="h2"
							fontSize={{ base: '14px', sm: '16px' }}
							fontWeight="700"
							letterSpacing="4px"
							lineHeight="16.85px"
							maxW="216px"
							color="#6C4CDF"
						>
							OUR VALUES
						</Heading>

						<Heading
							fontSize={{ base: '32px', md: '40px', lg: '48px' }}
							color={colorMode === 'light' ? '#000' : 'white'}
							maxW={{ base: '384px', sm: '550px', md: '814px' }}
							fontWeight="700"
							textAlign="center"
							lineHeight={{ base: '40px', sm: '44px', md: '60.54px' }}
						>
							Elevating every aspect of our work through integrity, empathy and
							quality.
						</Heading>
					</VStack>
					<Flex
						justify="center"
						flexWrap={{ base: 'wrap', md: 'nowrap' }}
						zIndex={5}
						gap={{ base: '2%', md: '3%' }}
					>
						{ValueList.map((item, idx) => (
							<Box key={item.id} mb={{ base: '30px', md: '0px' }}>
								<ValuesCard
									icon={item.icon}
									title={item.title}
									text={item.text}
									h={{ base: '450px', lg: item.h }}
									isActive={idx === item.id}
								/>
							</Box>
						))}
					</Flex>
					<Box
						display={{ base: 'none', lg: 'unset' }}
						pos="absolute"
						left="-10%"
						bottom="8.5%"
					>
						{valueStribe}
					</Box>
				</Flex>
			</Container>
		</Box>
	);
};

export default OurValues;
