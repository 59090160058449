import { clientDot, clientDotWhite } from 'assets/svgs/home';
import React, { FC } from 'react';
import Slider from 'react-slick';
import {
	Box,
	Container,
	Flex,
	Heading,
	HStack,
	Text,
	useColorMode,
	VStack,
} from '@chakra-ui/react';
import { Flip } from 'react-reveal';
import ClientsCard from './ClientsCard';
import cazoo from '../../assets/image/home/cazoo.webp';
import janePlan from '../../assets/image/home/jane-plan.webp';
import magicSub from '../../assets/image/home/magic-sub.webp';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Client: FC = () => {
	const { colorMode } = useColorMode();
	const settings = {
		dots: true,
		infinite: false,
		speed: 2000,
		slidesToShow: 1,
		slidesToScroll: 1,
		initialSlide: 0,
	};

	return (
		<Box bgColor={colorMode === 'light' ? 'white' : '#000'}>
			<Container
				maxW="1440px"
				p={{
					base: '78px 20px 31px',
					md: '154px 72px 77px',
				}}
			>
				<Flex flexDir="column" align="center" fontFamily="bold" pos="relative">
					<VStack
						spacing={{ base: '24px', sm: '38px' }}
						textAlign="center"
						pb={{ base: '49px', sm: '65px', md: '78px' }}
					>
						<Flip left duration={2500}>
							<Heading
								as="h2"
								fontSize={{ base: '32px', sm: '40px', md: '48px' }}
								fontWeight="700"
								lineHeight="50.54px"
								maxW="624px"
							>
								Meet Our Clients
							</Heading>
						</Flip>
						<Text
							fontSize={{ base: '16px', sm: '20px' }}
							color={colorMode === 'light' ? 'gray' : 'darkGray'}
							maxW={{ base: '360px', sm: '550px' }}
						>
							We have established our presence with excellence by working with
							top brands across continents.
						</Text>
						<Box
							pos="absolute"
							top={30}
							right={50}
							display={{ base: 'none', md: 'unset' }}
						>
							{colorMode === 'light' ? clientDot : clientDotWhite}
						</Box>
					</VStack>

					<HStack
						display={{ base: 'none', md: 'flex' }}
						spacing={{ base: '0px', md: '20px', lg: '32px' }}
					>
						<ClientsCard
							text={
								'Magic Subscriptions is an early stage startup specialising in subscription Saas.'
							}
							title={'Magic Subscriptions'}
							logoText={'Software design and development'}
							bgColor={colorMode === 'light' ? '#FAF8FF' : 'darkBg'}
							logo={magicSub}
						/>
						<ClientsCard
							text={
								'Jane Plan offer meal delivery to help people follow a complete nutritious plan to lose weight.'
							}
							title={'Jane Plan'}
							logoText={'Design, development and support'}
							bgColor={colorMode === 'light' ? '#EEFAFF' : 'darkBg'}
							logo={janePlan}
						/>
						<ClientsCard
							text={
								"Cazoo is the fastest company in the UK to achieve 'unicorn' status. They help people buy, finance, or rent used cars."
							}
							title={'Cazoo'}
							logoText={'Software engineer training and technical coaching'}
							bgColor={colorMode === 'light' ? '#EBFFF3' : 'darkBg'}
							logo={cazoo}
						/>
					</HStack>
				</Flex>
				<Box display={{ base: 'block', md: 'none' }} w="100%">
					<Slider {...settings}>
						<Box pb="31px">
							<ClientsCard
								text={
									'Magic Subscriptions is an early stage startup specialising in subscription Saas.'
								}
								title={'Magic Subscriptions'}
								logoText={'Software design and development'}
								bgColor={colorMode === 'light' ? '#FAF8FF' : 'darkBg'}
								logo={magicSub}
							/>
						</Box>
						<Box pb="31px">
							<ClientsCard
								text={
									'Jane Plan offer meal delivery to help people follow a complete nutritious plan to lose weight.'
								}
								title={'Jane Plan'}
								logoText={'Design, development and support'}
								bgColor={colorMode === 'light' ? '#EEFAFF' : 'darkBg'}
								logo={janePlan}
							/>
						</Box>
						<Box pb="31px">
							<ClientsCard
								text={
									"Cazoo is the fastest company in the UK to achieve 'unicorn' status. They help people buy, finance, or rent used cars."
								}
								title={'Cazoo'}
								logoText={'Software engineer training and technical coaching'}
								bgColor={colorMode === 'light' ? '#EBFFF3' : 'darkBg'}
								logo={cazoo}
							/>
						</Box>
					</Slider>
				</Box>
			</Container>
		</Box>
	);
};
export default Client;
