import React, { FC } from 'react';
import {
	Box,
	Flex,
	Heading,
	Stack,
	Image,
	Text,
	Container,
	useColorMode,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import heroImg from '../../assets/image/home/hero-img.webp';
import { stribe } from './../../assets/svgs/home';
import CustomBtn from './../common/CommonBtn';

const RefineIdeas: FC = () => {
	const { colorMode } = useColorMode();
	return (
		<Box>
			<Container
				maxW="1440px"
				p={{
					base: '45px 20px 30px',
					sm: '65px 50px 55px',
					md: '87px 52px 95px',
					lg: '101px 72px 104px',
				}}
			>
				<Flex
					fontFamily="bold"
					// align="center"
					flexDir={{ base: 'column', md: 'row' }}
					justify="space-between"
					position="relative"
				>
					<Stack w={{ base: '100%', md: '50%' }} mb={{ base: '53px', md: 0 }}>
						<Heading
							as="h2"
							mb={{ base: '37px', md: '39px' }}
							lineHeight={{ base: '48px', sm: '67px', lg: '75px' }}
							fontWeight={700}
							fontSize={{ base: '34px', sm: '56px' }}
						>
							Transform Your Ideas Into {''}
							<Heading
								as="span"
								fontWeight={700}
								fontSize={{ base: '34px', sm: '56px' }}
								lineHeight={{ base: '48px', sm: '67px', lg: '75px' }}
								color="purple"
							>
								Innovative Technology Solutions {''}
							</Heading>
							With Our Team
						</Heading>
						<Text
							color={colorMode === 'light' ? 'gray' : 'white'}
							fontSize={{ base: '16px', sm: '20px' }}
							maxW="550px"
							pb={{ base: '38px', md: '43px' }}
						>
							Whether as an individual or a corporation, we have the expertise
							and experience to help you achieve your goals.
							<br /> Contact us today to learn more.
						</Text>
						<Link to="/contact">
							<CustomBtn
								bg="purple"
								color="white"
								height="60px"
								width={{ base: '167px', sm: '168px' }}
								text="Contact Us"
							/>
						</Link>
					</Stack>

					<Box w={{ base: '100%', md: '50%' }}>
						<Image zIndex={5} src={heroImg} alt="phasecurve image" />
					</Box>
					<Box position="absolute" top="20px" left={0}>
						{stribe}
					</Box>
				</Flex>
			</Container>
		</Box>
	);
};

export default RefineIdeas;
