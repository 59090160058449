/* eslint-disable prettier/prettier */
import {
	Box,
	Heading,
	useColorMode,
	Container,
	// Flex,
	Grid,
	GridItem,
} from '@chakra-ui/react';
import PortfolioCard from 'components/portfolio/PortfolioCard';
import { PortfolioList } from 'data/Portfolios';
import React from 'react';

const Portfolio = () => {
	const { colorMode } = useColorMode();

	return (
		<section>
			<Box
				// bgImage={colorMode === 'light' ? hero : 'none'}
				// bgPos="bottom"
				bgColor={colorMode === 'light' ? 'none' : '#000'}
				w="full"
				h="full"
			>
				<Container
					maxW="1440px"
					p={{
						base: '45px 20px 30px',
						sm: '65px 50px 35px',
						md: '87px 52px 75px',
						lg: '86px 72px 84px',
					}}
				>
					<Heading
						as="h2"
						maxW={'754px'}
						mb={{ base: '37px', md: '59px' }}
						mt={{ base: '0', md: '10px' }}
						lineHeight={{ base: '48px', sm: '67px', lg: '75px' }}
						fontWeight={700}
						fontSize={{ base: '34px', sm: '56px' }}
						letterSpacing={'1px'}
					>
						Some of our previous{' '}
						<Heading
							as="span"
							fontWeight={700}
							fontSize={{ base: '34px', sm: '56px' }}
							lineHeight={{ base: '48px', sm: '67px', lg: '75px' }}
							color="purple"
						>
							projects{' '}
						</Heading>
					</Heading>

					<Grid
						templateColumns={{
							base: 'repeat(1, 1fr)',
							sm: 'repeat(2, 1fr)',
						}}
						gap={{ base: '30px', lg: '48px' }}
						// pb="100px"
					>
						{PortfolioList.map((item) => (
							<GridItem w="100%" key={item.id}>
								<PortfolioCard
									title={item.name}
									img={item.img}
									desc={item.Description}
									bg={item.bg}
								/>
							</GridItem>
						))}
					</Grid>
				</Container>
			</Box>
		</section>
	);
};

export default Portfolio;
