import { useState, FC } from 'react';
import {
	Box,
	Container,
	Flex,
	Grid,
	GridItem,
	Heading,
	Image,
	useColorMode,
} from '@chakra-ui/react';
import ReachOut from 'components/about/ReachOut';
import CustomBtn from 'components/common/CommonBtn';
import CustomInput from 'components/common/CustomInputB';
import { EnrolStribe, LinkedIn, githuIcon } from 'assets/svgs/programme';
import enrolImg from '../../assets/image/programme/enrol.webp';
import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomSelectField from 'components/common/CustomSelect';
import api from 'lib/axios';
import toast from 'components/common/toast';
import { Helmet } from 'react-helmet';

interface EnrolProgrammeFormInputs {
	firstName: string;
	lastName: string;
	email: string;
	experience: string;
	phone: string;
	githubUrl: string;
	linkedinUrl: string;
}

const schema = object().shape({
	firstName: string().required('Your First Name is Required'),
	lastName: string().required('Your Last Name is Required'),
	email: string().email().required('Email is Required'),
	experience: string().required('Years of Experince is Required'),
	phone: string().min(11).max(14).required('Phone Number is Required'),
	githubUrl: string().url().required('Github is Required'),
	linkedinUrl: string().url().required('Linkedin is Required'),
});

const EnrolProgramme: FC = () => {
	const { colorMode } = useColorMode();
	const [loading, setLoading] = useState(false);

	const { handleSubmit, control, reset } = useForm<EnrolProgrammeFormInputs>({
		resolver: yupResolver(schema),
		defaultValues: {
			firstName: '',
			lastName: '',
			email: '',
			experience: '',
			phone: '',
			githubUrl: '',
			linkedinUrl: '',
		},
	});

	const onSubmithandler = (data: EnrolProgrammeFormInputs) => {
		setLoading(true);
		const payload = {
			FirstName: data.firstName,
			LastName: data.lastName,
			EmailAddress: data.email,
			GitHubUrl: data.githubUrl,
			PhoneNumber: data.phone,
			LinkedInUrl: data.linkedinUrl,
			YearsOfExperience: data.experience,
		};

		api
			.post('/programme', payload)
			.then(() => {
				toast('Form submitted successfully').success();
				setLoading(false);
				reset();
			})
			.catch(() => {
				toast('Something went wrong').error();
				setLoading(false);
			});
	};

	const yearOfExperienceOptions = [
		{
			label: '1-3 Years',
			value: '1-3 Years',
		},
		{
			label: '4 Years',
			value: '4 Years',
		},
		{
			label: '5 Years',
			value: '5 Years',
		},
		{
			label: '6 Years',
			value: '6 Years',
		},
		{
			label: '7 Years+',
			value: '7 Years+',
		},
	];

	return (
		<Box
			bgColor={colorMode === 'light' ? 'white' : '#000'}
			pt="39.205px"
			pb="0"
		>
			<Helmet>
				<title>Phasecurve</title>
				<meta name="description" content="TechElite Application Form" />
				<meta name="keywords" content="TechElite Application Form" />
			</Helmet>
			<Container
				maxW="1440px"
				px={{ base: '20px', sm: '50px', md: '103px', lg: '182px' }}
				pb={{ base: '40px', sm: '50px', md: '91px', lg: '114px' }}
			>
				<form onSubmit={handleSubmit(onSubmithandler)} noValidate>
					<Heading
						color="purple"
						fontWeight="700"
						fontSize={{ base: '36px', md: '48px' }}
						lineHeight="50.54px"
						pt="50px"
					>
						TechElite
					</Heading>

					<Heading
						fontWeight="700"
						fontSize={{ base: '36px', md: '48px' }}
						lineHeight="50.54px"
						color="#000"
						pb={{ base: '30px', md: '80px' }}
					>
						Application Form
					</Heading>
					<Box position="absolute">{EnrolStribe}</Box>
					<Flex
						justifyContent={{ base: 'unset', md: 'space-between' }}
						display={{ base: 'revert', md: 'flex' }}
					>
						<Grid templateColumns="repeat(2, 1fr)" gap={{ base: '3', sm: '6' }}>
							<GridItem w="100%" h="73px">
								<CustomInput
									w={{ base: '100%', md: '302px' }}
									control={control}
									name="firstName"
									type={'text'}
									placeholder={'First Name'}
								/>
							</GridItem>
							<GridItem w="100%" h="73px">
								<CustomInput
									w={{ base: '100%', md: '302px' }}
									control={control}
									name="lastName"
									type={'text'}
									placeholder={'Last Name'}
								/>
							</GridItem>
							<GridItem w="100%" h="73px">
								<CustomInput
									w={{ base: '100%', md: '302px' }}
									control={control}
									name="email"
									type="text"
									placeholder={'Email Address'}
								/>
							</GridItem>
							<GridItem w="100%" h="73px">
								<CustomSelectField
									w={{ base: '100%', md: '302px' }}
									control={control}
									label="Years of Experience"
									options={yearOfExperienceOptions}
									name={'experience'}
								/>
							</GridItem>
							<GridItem w="100%" h="73px">
								<CustomInput
									w={{ base: '100%', md: '302px' }}
									control={control}
									name="phone"
									type="text"
									placeholder={'Phone number'}
								/>
							</GridItem>
							<GridItem w="100%" h="73px">
								<CustomInput
									w={{ base: '100%', md: '302px' }}
									control={control}
									name="githubUrl"
									type="text"
									icon={githuIcon}
									placeholder={'Github URL'}
								/>
							</GridItem>
							<GridItem w="100%" h="73px">
								<CustomInput
									w={{ base: '100%', md: '302px' }}
									control={control}
									name="linkedinUrl"
									type="url"
									icon={LinkedIn}
									placeholder={'Linkedin URL'}
								/>
							</GridItem>
						</Grid>
						<Box
							display={{ base: 'none', md: 'unset' }}
							pos="relative"
							top="-200px"
						>
							<Image src={enrolImg} />
						</Box>
					</Flex>
					<Box mt={{ base: '20px', sm: '20px' }}>
						<CustomBtn
							text={'Submit'}
							width={'138px'}
							height={'60px'}
							color={'white'}
							bg={'purple'}
							type="submit"
							isLoading={loading}
						/>
					</Box>
				</form>
			</Container>
			<ReachOut />
		</Box>
	);
};

export default EnrolProgramme;
