import { createStandaloneToast } from '@chakra-ui/react';
const { toast: chakraToast } = createStandaloneToast();

const toast = (message: string) => {
	return {
		success: () =>
			chakraToast({ description: message, status: 'success', position: 'top' }),
		error: () =>
			chakraToast({ description: message, status: 'error', position: 'top' }),
		info: () =>
			chakraToast({ description: message, status: 'info', position: 'top' }),
	};
};

export default toast;
