import React, { FC } from 'react';
import {
	Input,
	Text,
	useColorMode,
	InputProps,
	InputGroup,
	InputRightElement,
	FormErrorMessage,
	FormControl,
} from '@chakra-ui/react';
import { Controller } from 'react-hook-form';

type Props = {
	control: any;
	name: string;
	loading?: boolean;
	readOnly?: boolean;
	icon?: React.ReactElement | any;
	required?: boolean;
	disabled?: boolean;
} & InputProps;
const CustomInput: FC<Props> = ({
	placeholder,
	required,
	disabled,
	readOnly,
	icon,
	control,
	name,
	...rest
}) => {
	const { colorMode } = useColorMode();

	return (
		<Controller
			control={control}
			name={name}
			rules={{ required: 'This is required' }}
			render={({ field, fieldState: { invalid, error } }) => {
				return (
					<FormControl
						pos="relative"
						zIndex={5}
						isRequired={required}
						isDisabled={disabled}
						isInvalid={invalid}
						isReadOnly={readOnly}
					>
						<InputGroup>
							<Input
								placeholder={placeholder}
								_placeholder={{ color: '#94A3B8', fontSize: '16px' }}
								fontSize="14px"
								borderRadius="10px"
								width={'302px'}
								height={'73px'}
								padding={'20px 15px'}
								_hover={{ border: '2px solid #6C4CDF' }}
								focusBorderColor="#6C4CDF"
								border="1px solid #94A3B8"
								transition="0.7 ease-in-out"
								{...field}
								{...rest}
							/>
							{icon && (
								<InputRightElement
									right="5px"
									top="50%"
									transform="translateY(-50%)"
									filter={
										field.value
											? 'unset'
											: 'opacity(.6) brightness(1) hue-rotate(302deg) saturate(.3)'
									}
								>
									{icon}
								</InputRightElement>
							)}
						</InputGroup>

						<Text
							display={field.value ? 'inline-block' : 'none'}
							p="1px 3px"
							borderRadius="5px"
							fontSize="12px"
							color={colorMode === 'light' ? 'gray' : 'darkGray'}
							bg={colorMode === 'light' ? 'white' : 'black'}
							pos="absolute"
							zIndex={5}
							top="-10px"
							left={3}
						>
							{placeholder}
						</Text>

						{error && (
							<FormErrorMessage mt="1.5" color="red.600" fontSize="sm">
								{error.message}
							</FormErrorMessage>
						)}
					</FormControl>
				);
			}}
		/>
	);
};
export default CustomInput;
