import { Box } from '@chakra-ui/react';
import ReachOut from 'components/about/ReachOut';
import Intro from 'components/services/Intro';
import SignUp from 'components/services/SignUp';
import { FC } from 'react';
import Offer from './../components/services/Offer';
import { Helmet } from 'react-helmet';
// import OurProject from 'components/services/OurProject';

const Services: FC = () => {
	return (
		<Box>
			<Helmet>
				<title>Phasecurve</title>
				<meta name="description" content="We offer quality software services" />
				<meta
					name="keywords"
					content="Software development, Website creation, Software training, bespoke engineering course"
				/>
			</Helmet>

			<Intro />
			<Offer />
			<SignUp />
			{/* <OurProject /> */}
			<ReachOut />
		</Box>
	);
};

export default Services;
