import React, { FC } from 'react';
import {
	Flex,
	Heading,
	Text,
	Image,
	useColorMode,
	Container,
	Box,
} from '@chakra-ui/react';
import contantImg from '../../assets/image/contact/contact_img.webp';
import hero from '../../assets/image/home/hero.png';

const Intro: FC = () => {
	const { colorMode } = useColorMode();
	return (
		<Box
			bgImage={colorMode === 'light' ? hero : 'none'}
			bgColor={colorMode === 'light' ? 'none' : '#000'}
			bgPos={{ base: 'bottom' }}
			w="full"
			h="full"
		>
			<Container
				maxW="1440px"
				p={{ base: '81px 20px 2px', sm: '81px 50px 2px', md: '124px 72px 2px' }}
			>
				<Flex flexDir="column" fontFamily="bold" align="center">
					<Heading
						as="h2"
						fontSize={{ base: '40px', md: '48px' }}
						lineHeight={{ base: '48px', md: '50px', lg: '60px' }}
						fontWeight="700"
						pb="42px"
						textAlign="center"
						maxW="806px"
					>
						Ready to Take Your Business to the Next Level?
					</Heading>
					<Text
						textAlign="center"
						pb={{ base: '68px', md: '61px' }}
						maxW="684px"
						color={colorMode === 'light' ? 'gray' : 'darkGray'}
					>
						We're always here to help. Contact us today to schedule a
						consultation. We'll discuss your unique needs and develop a custom
						solution that meets your requirements. Don't wait - let us help you
						transform your business with our services.
					</Text>

					<Image
						src={contantImg}
						alt="contact banner"
						borderRadius={{ base: '30px', md: '40px' }}
						objectFit="cover"
						h={{ base: '208px', sm: '276px', md: '316px' }}
					/>
				</Flex>
			</Container>
		</Box>
	);
};

export default Intro;
