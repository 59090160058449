export const phasecurve = (
	<svg
		width="196"
		height="37"
		viewBox="0 0 196 37"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M194.036 24.2888C194.041 28.0952 193.031 31.8341 191.109 35.1199C190.696 35.6247 190.257 36.1076 189.8 36.5831C191.05 33.8087 191.695 30.7997 191.691 27.7565C191.691 24.9355 191.135 22.142 190.055 19.5358C188.976 16.9296 187.393 14.5616 185.398 12.567C183.403 10.5724 181.035 8.99025 178.429 7.91102C175.822 6.83179 173.029 6.27652 170.208 6.277C167.365 6.27333 164.55 6.83558 161.927 7.93085C159.304 9.02611 156.925 10.6325 154.929 12.6565C155.238 11.9719 155.584 11.3041 155.964 10.6556C157.977 8.20205 160.509 6.22609 163.379 4.87044C166.248 3.5148 169.383 2.81325 172.556 2.8166C178.251 2.81757 183.713 5.0799 187.741 9.10628C191.769 13.1327 194.033 18.5937 194.036 24.2888Z"
			fill="#7ED8FF"
		/>
		<path
			d="M191.109 35.1273C193.031 31.8414 194.041 28.1024 194.036 24.296C194.036 18.599 191.773 13.1353 187.745 9.10655C183.717 5.07779 178.253 2.81391 172.556 2.81294C169.383 2.80959 166.248 3.51114 163.379 4.86678C160.509 6.22242 157.976 8.19839 155.964 10.652C157.856 7.40913 160.565 4.71896 163.821 2.84991C167.077 0.980864 170.766 -0.00177508 174.52 2.40717e-06C177.341 2.36626e-06 180.135 0.555689 182.741 1.63536C185.347 2.71503 187.715 4.29751 189.71 6.29245C191.705 8.28739 193.287 10.6558 194.366 13.2622C195.445 15.8686 196 18.6621 196 21.4831C196.007 26.4617 194.278 31.287 191.109 35.1273Z"
			fill="#6C4CDF"
		/>
		<path
			d="M10.2642 30.1671H4.1042V36.5905H0V14.822C3.42018 14.822 6.84404 14.7927 10.2642 14.7927C20.8979 14.7745 20.9271 30.1671 10.2642 30.1671ZM4.1042 26.4031H10.2642C15.517 26.4031 15.4878 18.6922 10.2642 18.6922H4.1042V26.4031Z"
			fill="#170041"
		/>
		<path
			d="M24.5339 14.8037V23.3267C25.0825 22.5804 25.806 21.9803 26.6409 21.5791C27.4758 21.1779 28.3965 20.9879 29.3221 21.0258C33.675 21.0258 35.6027 23.9779 35.6027 28.4881V36.5904H31.8095V28.5429C31.8095 25.7446 30.3463 24.5631 28.3271 24.5631C26.0884 24.5631 24.5339 26.4616 24.5339 28.7625V36.5904H20.7405V14.822L24.5339 14.8037Z"
			fill="#170041"
		/>
		<path
			d="M50.9625 20.7772H54.6204V36.1039H51.01L50.8235 33.8653C49.9529 35.6687 47.5569 36.5392 45.8486 36.5722C41.3091 36.6014 37.9512 33.8031 37.9512 28.4259C37.9512 23.1402 41.4627 20.3785 45.9401 20.4004C47.9922 20.4004 49.9638 21.3661 50.8235 22.8915L50.9625 20.7772ZM41.7591 28.4259C41.7591 31.3523 43.7783 33.0898 46.2986 33.0898C52.2684 33.0898 52.2684 23.7913 46.2986 23.7913C43.7783 23.7877 41.7591 25.5032 41.7591 28.4259Z"
			fill="#170041"
		/>
		<path
			d="M67.8146 24.7862C66.7172 23.762 65.4809 23.4182 64.0213 23.4182C62.218 23.4182 61.223 23.9778 61.223 24.9435C61.223 25.9092 62.1228 26.4982 64.0835 26.6225C66.9733 26.8091 70.6422 27.4602 70.6422 31.5351C70.6422 34.2384 68.4474 36.5721 64.0579 36.5721C61.6326 36.5721 59.2075 36.1661 56.9688 33.836L58.8343 31.1292C59.9317 32.3436 62.4118 33.2434 64.1201 33.2763C65.5504 33.3056 66.8891 32.5447 66.8891 31.4473C66.8891 30.3902 66.0186 29.9549 63.8421 29.8306C60.9487 29.6147 57.4992 28.5576 57.4992 25.0752C57.4992 21.5307 61.1571 20.287 63.9664 20.287C66.3587 20.287 68.1621 20.7515 69.9362 22.3061L67.8146 24.7862Z"
			fill="#170041"
		/>
		<path
			d="M76.301 29.8562C76.5497 31.751 78.1995 33.119 80.8734 33.119C82.2708 33.119 84.1071 32.5923 84.9777 31.6888L87.4029 34.0847C85.7861 35.7637 83.1414 36.5721 80.8186 36.5721C75.5329 36.5721 72.4053 33.3056 72.4053 28.393C72.4053 23.7291 75.5768 20.3711 80.5516 20.3711C85.6727 20.3711 88.888 23.5426 88.2954 29.8562H76.301ZM84.6338 26.714C84.3851 24.724 82.8305 23.7291 80.654 23.7291C78.6019 23.7291 76.9229 24.724 76.3633 26.714H84.6338Z"
			fill="#170041"
		/>
		<path
			d="M104.346 34.1469C103.585 34.9409 102.667 35.5669 101.649 35.9845C100.632 36.4022 99.5382 36.6023 98.4388 36.5721C93.9944 36.5721 90.2926 33.8982 90.2926 28.4551C90.2926 23.0121 93.9944 20.3419 98.4388 20.3419C99.4693 20.3066 100.496 20.4905 101.45 20.8814C102.404 21.2724 103.264 21.8615 103.973 22.6098L101.581 25.13C100.737 24.3625 99.6416 23.9305 98.5011 23.9157C95.9405 23.9157 94.0859 25.7812 94.0859 28.4551C94.0859 31.3815 96.0758 32.9325 98.4388 32.9325C99.0553 32.956 99.6702 32.8554 100.247 32.6368C100.824 32.4181 101.351 32.0858 101.797 31.6595L104.346 34.1469Z"
			fill="#170041"
		/>
		<path
			d="M109.903 20.993V29.0148C109.903 31.3486 111.176 33.119 113.601 33.119C115.935 33.119 117.519 31.162 117.519 28.8283V20.993H121.283V36.3563H117.892L117.643 34.2749C116.059 35.8295 114.596 36.5758 112.452 36.5758C108.795 36.5758 106.11 33.8067 106.11 29.0514V21.0039L109.903 20.993Z"
			fill="#170041"
		/>
		<path
			d="M128.163 21.2271L128.445 23.0012C129.626 21.1028 131.21 20.8247 132.765 20.8247C134.218 20.803 135.627 21.3248 136.716 22.2879L135.004 25.58C134.636 25.2421 134.204 24.9803 133.735 24.8096C133.265 24.6388 132.767 24.5625 132.267 24.5851C130.278 24.5851 128.445 25.6423 128.445 28.4735V36.5904H124.652V21.2271H128.163Z"
			fill="#170041"
		/>
		<path
			d="M154.998 21.1503L148.469 36.5721H144.364L137.802 21.1503H141.936L144.05 26.2165L146.417 32.6838L148.747 26.2787L150.861 21.1576L154.998 21.1503Z"
			fill="#170041"
		/>
		<path
			d="M160.064 29.8562C160.313 31.751 161.959 33.119 164.637 33.119C166.034 33.119 167.87 32.5923 168.741 31.6888L171.166 34.0847C169.549 35.7637 166.905 36.5721 164.582 36.5721C159.292 36.5721 156.169 33.3056 156.169 28.393C156.169 23.7291 159.34 20.3711 164.315 20.3711C169.436 20.3711 172.648 23.5426 172.059 29.8562H160.064ZM168.397 26.714C168.148 24.724 166.594 23.7291 164.417 23.7291C162.365 23.7291 160.686 24.724 160.127 26.714H168.397Z"
			fill="#170041"
		/>
	</svg>
);

export const phasecurveLight = (
	<svg
		width="196"
		height="37"
		viewBox="0 0 196 37"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M194.036 24.2886C194.041 28.095 193.031 31.8339 191.109 35.1197C190.696 35.6245 190.257 36.1074 189.8 36.5829C191.05 33.8085 191.695 30.7995 191.691 27.7563C191.691 24.9353 191.135 22.1419 190.055 19.5356C188.976 16.9294 187.393 14.5614 185.398 12.5668C183.403 10.5722 181.035 8.99007 178.429 7.91084C175.822 6.83161 173.029 6.27634 170.208 6.27682C167.365 6.27315 164.55 6.8354 161.927 7.93066C159.304 9.02593 156.925 10.6324 154.929 12.6563C155.238 11.9718 155.584 11.3039 155.964 10.6555C157.977 8.20187 160.509 6.2259 163.379 4.87026C166.248 3.51462 169.383 2.81307 172.556 2.81642C178.251 2.81739 183.713 5.07971 187.741 9.1061C191.769 13.1325 194.033 18.5935 194.036 24.2886Z"
			fill="#7ED8FF"
		/>
		<path
			d="M191.109 35.1273C193.031 31.8414 194.041 28.1024 194.036 24.296C194.036 18.599 191.773 13.1353 187.745 9.10655C183.717 5.07779 178.253 2.81391 172.556 2.81294C169.383 2.80959 166.248 3.51114 163.379 4.86678C160.509 6.22242 157.977 8.19839 155.964 10.652C157.856 7.40913 160.565 4.71896 163.821 2.84991C167.077 0.980864 170.766 -0.00177508 174.52 2.40717e-06C177.342 2.36626e-06 180.135 0.555689 182.741 1.63536C185.347 2.71503 187.715 4.29751 189.71 6.29245C191.705 8.28739 193.287 10.6558 194.366 13.2622C195.445 15.8686 196 18.6621 196 21.4831C196.007 26.4617 194.278 31.287 191.109 35.1273Z"
			fill="#6C4CDF"
		/>
		<path
			d="M10.2642 30.1668H4.1042V36.5902H0V14.8218C3.42018 14.8218 6.84404 14.7925 10.2642 14.7925C20.8979 14.7742 20.9271 30.1668 10.2642 30.1668ZM4.1042 26.4028H10.2642C15.517 26.4028 15.4878 18.6919 10.2642 18.6919H4.1042V26.4028Z"
			fill="white"
		/>
		<path
			d="M24.5339 14.8037V23.3267C25.0825 22.5804 25.806 21.9803 26.6409 21.5791C27.4758 21.1779 28.3965 20.9879 29.3221 21.0259C33.675 21.0259 35.6027 23.9779 35.6027 28.4881V36.5904H31.8095V28.543C31.8095 25.7446 30.3463 24.5632 28.3271 24.5632C26.0884 24.5632 24.5339 26.4616 24.5339 28.7625V36.5904H20.7405V14.822L24.5339 14.8037Z"
			fill="white"
		/>
		<path
			d="M50.9625 20.7773H54.6204V36.104H51.01L50.8235 33.8654C49.9529 35.6688 47.5569 36.5394 45.8486 36.5723C41.3091 36.6015 37.9512 33.8032 37.9512 28.426C37.9512 23.1403 41.4627 20.3786 45.9401 20.4005C47.9922 20.4005 49.9638 21.3662 50.8235 22.8916L50.9625 20.7773ZM41.7591 28.426C41.7591 31.3524 43.7783 33.0899 46.2986 33.0899C52.2684 33.0899 52.2684 23.7914 46.2986 23.7914C43.7783 23.7878 41.7591 25.5033 41.7591 28.426Z"
			fill="white"
		/>
		<path
			d="M67.8146 24.7864C66.7172 23.7622 65.4809 23.4183 64.0213 23.4183C62.218 23.4183 61.223 23.978 61.223 24.9437C61.223 25.9094 62.1228 26.4983 64.0835 26.6227C66.9733 26.8092 70.6422 27.4603 70.6422 31.5353C70.6422 34.2385 68.4474 36.5723 64.0579 36.5723C61.6326 36.5723 59.2075 36.1663 56.9688 33.8361L58.8343 31.1293C59.9317 32.3437 62.4118 33.2436 64.1201 33.2765C65.5504 33.3057 66.8891 32.5449 66.8891 31.4475C66.8891 30.3904 66.0186 29.9551 63.8421 29.8307C60.9487 29.6149 57.4992 28.5578 57.4992 25.0754C57.4992 21.5308 61.1571 20.2871 63.9664 20.2871C66.3587 20.2871 68.1621 20.7517 69.9362 22.3063L67.8146 24.7864Z"
			fill="white"
		/>
		<path
			d="M76.301 29.8562C76.5497 31.751 78.1995 33.1191 80.8734 33.1191C82.2708 33.1191 84.1071 32.5924 84.9777 31.6888L87.4029 34.0847C85.7861 35.7637 83.1414 36.5721 80.8186 36.5721C75.5329 36.5721 72.4053 33.3056 72.4053 28.393C72.4053 23.7292 75.5768 20.3711 80.5516 20.3711C85.6727 20.3711 88.888 23.5426 88.2954 29.8562H76.301ZM84.6338 26.714C84.3851 24.7241 82.8305 23.7292 80.654 23.7292C78.6019 23.7292 76.9229 24.7241 76.3633 26.714H84.6338Z"
			fill="white"
		/>
		<path
			d="M104.346 34.1467C103.585 34.9407 102.667 35.5667 101.649 35.9844C100.632 36.402 99.5382 36.6021 98.4388 36.5719C93.9944 36.5719 90.2926 33.898 90.2926 28.455C90.2926 23.0119 93.9944 20.3417 98.4388 20.3417C99.4693 20.3064 100.496 20.4903 101.45 20.8813C102.404 21.2722 103.264 21.8613 103.973 22.6096L101.581 25.1299C100.737 24.3623 99.6416 23.9303 98.5011 23.9155C95.9405 23.9155 94.0859 25.781 94.0859 28.455C94.0859 31.3813 96.0758 32.9323 98.4388 32.9323C99.0553 32.9558 99.6702 32.8553 100.247 32.6366C100.824 32.4179 101.351 32.0856 101.797 31.6593L104.346 34.1467Z"
			fill="white"
		/>
		<path
			d="M109.903 20.9932V29.015C109.903 31.3488 111.176 33.1192 113.601 33.1192C115.935 33.1192 117.519 31.1622 117.519 28.8285V20.9932H121.283V36.3565H117.892L117.643 34.2751C116.059 35.8297 114.596 36.576 112.452 36.576C108.795 36.576 106.11 33.8069 106.11 29.0515V21.0041L109.903 20.9932Z"
			fill="white"
		/>
		<path
			d="M128.163 21.2273L128.445 23.0014C129.626 21.1029 131.21 20.8249 132.765 20.8249C134.218 20.8032 135.627 21.325 136.716 22.288L135.004 25.5802C134.636 25.2423 134.204 24.9805 133.735 24.8098C133.265 24.639 132.767 24.5627 132.267 24.5852C130.278 24.5852 128.445 25.6424 128.445 28.4737V36.5906H124.652V21.2273H128.163Z"
			fill="white"
		/>
		<path
			d="M154.998 21.1504L148.469 36.5723H144.364L137.802 21.1504H141.936L144.05 26.2166L146.417 32.6839L148.747 26.2788L150.861 21.1577L154.998 21.1504Z"
			fill="white"
		/>
		<path
			d="M160.064 29.8562C160.313 31.751 161.959 33.1191 164.637 33.1191C166.034 33.1191 167.87 32.5924 168.741 31.6888L171.166 34.0847C169.549 35.7637 166.905 36.5721 164.582 36.5721C159.292 36.5721 156.169 33.3056 156.169 28.393C156.169 23.7292 159.34 20.3711 164.315 20.3711C169.436 20.3711 172.648 23.5426 172.059 29.8562H160.064ZM168.397 26.714C168.148 24.7241 166.594 23.7292 164.417 23.7292C162.365 23.7292 160.686 24.7241 160.127 26.714H168.397Z"
			fill="white"
		/>
	</svg>
);
