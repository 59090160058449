import { Box } from '@chakra-ui/react';
import Faqs from 'components/contact/Faqs';
import { FC } from 'react';
import Intro from '../components/contact/Intro';
import ContactForm from './../components/contact/ContactForm';
const Contact: FC = () => {
	return (
		<Box>
			<Intro />
			<ContactForm />
			<Faqs />
		</Box>
	);
};

export default Contact;
