import { FC } from 'react';

import {
	imgDot,
	vStribe,
	logoDot,
	logoDotWhite,
	halfLogoDot,
} from './../../assets/svgs/about';
import techWriter from '../../assets/image/about/tech-writer.webp';
import halfTechWriter from '../../assets/image/about/halfTechWriter.webp';
import halfImgDot from '../../assets/image/about/halfImgDot.webp';
import {
	Box,
	Container,
	Flex,
	Heading,
	Image,
	Text,
	useColorMode,
} from '@chakra-ui/react';

const Intro: FC = () => {
	const { colorMode } = useColorMode();
	return (
		<Box bgColor={colorMode === 'light' ? 'white' : '#000'} position="relative">
			<Container
				maxW="1440px"
				px={{ base: '20px', sm: '50px', md: '72px' }}
				pt={{ base: '89px', sm: '105px', md: '125px' }}
				pb="220px"
			>
				<Flex
					flexDir="column"
					align="center"
					justify="center"
					fontFamily="bold"
				>
					<Text
						as="p"
						pb={{ base: '23px', md: '43px', lg: '64px' }}
						fontWeight="700"
						letterSpacing="4px"
						color={colorMode === 'light' ? '#94A3B8' : 'darkGray'}
					>
						ABOUT US
					</Text>

					<Heading
						textAlign="center"
						maxW="956px"
						as="h2"
						fontSize={{ base: '40px', sm: '48px', md: '56px', lg: '64px' }}
						lineHeight={{ base: '50px', md: '60px', lg: '67.39px' }}
						fontWeight="700"
					>
						We{' '}
						<Heading
							as="span"
							color={'purple'}
							fontSize={{ base: '40px', sm: '48px', md: '56px', lg: '64px' }}
							lineHeight="67.39px"
							fontWeight="700"
						>
							specialise{' '}
						</Heading>
						in providing both digital solutions and IT training to support
						individuals and organisations.
					</Heading>
					<Box
						display={{ base: 'none', md: 'unset' }}
						position="absolute"
						top="0%"
						left="36%"
					>
						{vStribe}
					</Box>
					<Box
						display={{ base: 'none', lg: 'unset' }}
						position="absolute"
						bottom={50}
						left={{ base: '25px', lg: '0%', xl: '7%' }}
					>
						{imgDot}
					</Box>
					<Box
						display={{ base: 'unset', lg: 'none' }}
						position="absolute"
						bottom={50}
						left={0}
					>
						<Image src={halfImgDot} />
					</Box>
					<Box
						display={{ base: 'none', lg: 'unset' }}
						position="absolute"
						top={63}
						right={{ base: '48px', lg: '1%', xl: '9%' }}
					>
						<Image src={techWriter} />
					</Box>
					<Box
						display={{ base: 'unset', lg: 'none' }}
						position="absolute"
						top={41}
						right={0}
					>
						<Image src={halfTechWriter} />
					</Box>
					<Box
						display={{ base: 'none', lg: 'unset' }}
						position="absolute"
						bottom={150}
						right={{ base: '96px', md: '-5%', lg: '3%', xl: '10%' }}
					>
						{colorMode === 'light' ? logoDot : logoDotWhite}
					</Box>
					<Box
						display={{ base: 'unset', lg: 'none' }}
						position="absolute"
						bottom={90}
						right={0}
					>
						{halfLogoDot}
					</Box>
				</Flex>
			</Container>
		</Box>
	);
};

export default Intro;
