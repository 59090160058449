import { Box } from '@chakra-ui/react';
import Intro from 'components/about/Intro';
import Teams from 'components/about/Teams';
import WhoWeAre from 'components/about/WhoWeAre';
import MissionVision from 'components/about/MissionVision';
import { FC } from 'react';
import ReachOut from 'components/about/ReachOut';
import OurValues from './../components/about/OurValues';
import { Helmet } from 'react-helmet';
const About: FC = () => {
	return (
		<Box>
			<Helmet>
				<meta
					name="description"
					content="We specialise in providing both digital solutions and IT training to support individuals and organisations."
				/>
				<meta
					name="keywords"
					content="Building innovative and sustainable solutions,transform the experience of young talents,invests in the personal growth of people in the organisation and in the community."
				/>
			</Helmet>
			<Intro />
			<WhoWeAre />
			<MissionVision />
			<OurValues />
			<Teams />
			<ReachOut />
		</Box>
	);
};

export default About;
