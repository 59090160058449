/* eslint-disable prettier/prettier */

import { Container , Box, useColorMode, Text, Heading, VStack} from '@chakra-ui/react'

import React from 'react'

const NoVacancy = () => {
  const { colorMode } = useColorMode();

  return (
    <Box bgColor={colorMode === 'light' ? 'white' : '#000'}>
    <Container
      maxW="144rem"
      p={{
        base: '45px 40px 30px 40px',
        sm: '65px 50px 55px 50px',
        md: '87px 52px 95px 52px',
        lg: '101px 72px 104px 72px',
      }}
      pb={{
        base: 0, md: 0
      }}
    >
      <VStack
      justifyContent="center"
      alignItems="center"
      >
      <Heading
						maxW="840px"
						pb="25px"
						textAlign="center"
						lineHeight={{ base: '30px', md: '44px' }}
						fontWeight="700"
						fontSize={{ base: '30px', md: '40px' }}
					>
						
						No current vacancies
					</Heading>

          <Text 
          pb="30px"
          fontWeight="500"
          textAlign="center"
          fontSize={{ base:'16px', md: '20px' }}
          lineHeight={{ base: '24px', md: '32px' }}
          >
            Subscribe to our newsletter to stay updated with latest jobs
            </Text>
      </VStack>
    </Container>
    </Box>
  )
}

export default NoVacancy
