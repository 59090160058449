import { Box } from '@chakra-ui/react';
import ReachOut from 'components/about/ReachOut';
import Intro from 'components/programme/Intro';
import Overview from 'components/programme/Overview';
// import Testimonial from 'components/programme/Testimonial';
import { FC } from 'react';
import { Helmet } from 'react-helmet';

const Programme: FC = () => {
	return (
		<Box>
			<Helmet>
				<title>TechElite Programme</title>
				<meta
					name="description"
					content="An Advanced Software Engineering Programme"
				/>
				<meta
					name="keywords"
					content="TechElite, Software engineering programme"
				/>
			</Helmet>
			<Intro />
			<Overview />
			{/* <Testimonial /> */}
			<ReachOut />
		</Box>
	);
};

export default Programme;
