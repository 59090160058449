// /* eslint-disable import/no-anonymous-default-export */
export default {
	styles: {
		global: {
			html: {
				fontSize: '62.5%',
			},
			body: {
				margin: '0',
				bg: 'white',
				color: 'black',
				fontSize: '1.4rem',
				fontFamily: 'bold',
			},
			a: {
				_hover: {
					textDecoration: 'none',
				},
			},
		},
	},
};
