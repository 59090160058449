import React, { FC } from 'react';
import { Fade, Bounce } from 'react-reveal';
import {
	Box,
	Container,
	Flex,
	Grid,
	GridItem,
	Heading,
	Stack,
	Text,
	useColorMode,
	VStack,
} from '@chakra-ui/react';
import modernTech from '../../assets/image/home/modern-tech.webp';
import darkModernTech from '../../assets/image/home/darkmode/modern-tech.webp';
import darkReliable from '../../assets/image/home/darkmode/reliable.webp';
import darkCommunity from '../../assets/image/home/darkmode/community-support.webp';
import community from '../../assets/image/home/community-support.webp';
import reliable from '../../assets/image/home/reliable.webp';
import SdlcCard from './SdlcCard';

const Sdlc: FC = () => {
	const { colorMode } = useColorMode();
	return (
		<Box bgColor={colorMode === 'light' ? 'white' : '#000'}>
			<Container
				maxW="1440px"
				p={{
					base: '104px 20px 63px',
					sm: '104px 45px 100px',
					md: '101px 72px 156px',
				}}
			>
				<Flex flexDir="column" align="center" fontFamily="bold">
					<VStack
						spacing="38px"
						textAlign="center"
						pb={{ base: '70px', md: '78px' }}
					>
						<Bounce duration={3500}>
							<Heading
								as="h2"
								fontSize={{ base: '32px', sm: '40px', md: '48px' }}
								fontWeight="700"
								color="purple"
								lineHeight={{ base: '40px', md: '50.54px' }}
								maxW="624px"
							>
								Conceptualising Ideas {''}
								<Heading
									as="span"
									fontSize={{ base: '32px', sm: '40px', md: '48px' }}
									fontWeight="700"
									color={colorMode === 'light' ? 'black' : 'white'}
								>
									and Harnessing Opportunities
								</Heading>
							</Heading>
						</Bounce>
						<Text
							fontSize={{ base: '16px', sm: '20px' }}
							color={colorMode === 'light' ? 'gray' : 'darkGray'}
							maxW="822px"
						>
							Phasecurve helps with building your ideas from ground up. We
							provide support through the full SDLC to support the development
							of your products.
						</Text>
					</VStack>

					<Stack spacing="28px" w="100%">
						<Flex
							w="100%"
							flexDir="column"
							justify={{ base: 'flex-start', sm: 'center' }}
							pt={{ base: '45px', sm: '0px' }}
							zIndex={3}
							borderRadius="40px"
							px={{ base: '28px', sm: '55px' }}
							minHeight={{ base: '361px', sm: '419px' }}
							fontFamily="bold"
							bgImage={colorMode === 'light' ? modernTech : darkModernTech}
							bgSize="cover"
							bgPos="center"
							bgRepeat="no-repeat"
						>
							<Heading
								as="h4"
								color={colorMode === 'light' ? 'black' : '#6B46C1'}
								maxW="267px"
								mb="30px"
								fontWeight="700"
								fontSize={{ base: '24px', sm: '36px' }}
							>
								Modern Technology
							</Heading>
							<Text
								fontSize={{ base: '16px', sm: '20px' }}
								color={colorMode === 'light' ? 'black' : 'darkGray'}
								maxW={{ base: '303px', sm: '399px' }}
							>
								With our well-equipped library of knowledge, we ensure to use
								the appropriate technologies when building your products.
							</Text>
						</Flex>
						<Grid
							templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
							gap="32px"
						>
							<Fade duration={2500} left>
								<GridItem w="100%">
									<SdlcCard
										text={
											'We give no excuses when we are tasked with your project as our team of diligent professionals meet all agreed timelines.'
										}
										title={'Secure & Reliable'}
										bgImg={colorMode === 'light' ? reliable : darkReliable}
										textW={{ base: '303px', sm: '225px' }}
										color={colorMode === 'light' ? 'black' : '#2F855A'}
									/>
								</GridItem>
							</Fade>
							<Fade duration={2500} right>
								<GridItem w="100%">
									<SdlcCard
										text={
											'We are big believers in giving back to the community. A portion of our revenue goes back to the community. We provide learning resources and conduct training to help people learn.'
										}
										title={'Community Support'}
										bgImg={colorMode === 'light' ? community : darkCommunity}
										textW={'240px'}
										color={colorMode === 'light' ? 'black' : '#2B6CB0'}
									/>
								</GridItem>
							</Fade>
						</Grid>
					</Stack>
				</Flex>
			</Container>
		</Box>
	);
};

export default Sdlc;
