import { FC } from 'react';
import { Heading, Stack, Text, Flex } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

type Props = {
	id: string;
	title: string;
	titleColor: string;
	desc: string;
	descColor: string;
	linkText: string;
	cardTextColor: string;
	bgImg: any;
	date: string;
};

const ExploreCard: FC<Props> = ({
	id,
	linkText,
	title,
	titleColor,
	desc,
	descColor,
	cardTextColor,
	date,
	bgImg,
}) => {
	return (
		<Flex
			flexDir="column"
			justify="space-between"
			maxW={{ base: '100%', md: '412px' }}
			p="27px 24px 21px"
			borderRadius="20px"
			transition="0.5s ease"
			_hover={{
				transform: 'scale(1.03)',
				boxShadow: '0px 4px 50px rgba(0, 0, 0, 0.15)',
			}}
			minH="249px"
			fontFamily="bold"
			bgImage={bgImg}
			bgPos="right"
			bgSize="cover"
			bgRepeat="no-repeat"
			color={cardTextColor}
		>
			<Stack spacing="13px">
				<Text fontSize="0.8rem">{date}</Text>

				<Heading
					fontSize="20px"
					fontWeight="700"
					maxW="243px"
					color={titleColor}
				>
					{`${title}: `}
					<Heading as="span" fontSize="20px" fontWeight="700" color={descColor}>
						{desc}
					</Heading>
				</Heading>
			</Stack>
			<Link to={`/community/event/${id}`}>
				<Text fontSize="14px" _hover={{ textDecoration: 'underline' }}>
					{linkText}
				</Text>
			</Link>
		</Flex>
	);
};

export default ExploreCard;
