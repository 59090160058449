import React, { FC, useState } from 'react';
import {
	Flex,
	Text,
	useColorMode,
	Container,
	Box,
	Stack,
	HStack,
} from '@chakra-ui/react';
import CustomBtn from 'components/common/CommonBtn';
import CustomInput from 'components/common/CustomInputB';
import CustomSelectField from 'components/common/CustomSelect';
import { object, string } from 'yup';
import countries from 'data/Countries';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import api from 'lib/axios';
import toast from 'components/common/toast';

interface ContactFormInputs {
	FullName: string;
	EmailAddress: string;
	Company: string;
	HowWeCanHelp: string;
	Country: string;
}

const schema = object().shape({
	FullName: string().required('Your Fullname is Required'),
	EmailAddress: string().email().required('Email is Required'),
	Company: string().required('Company is Required'),
	HowWeCanHelp: string().required('Message is Required'),
	Country: string().required('Country is Required'),
});

const ContactForm: FC = () => {
	const { colorMode } = useColorMode();
	const [loading, setLoading] = useState(false);

	const { handleSubmit, control, reset } = useForm<ContactFormInputs>({
		resolver: yupResolver(schema),
		defaultValues: {
			FullName: '',
			EmailAddress: '',
			Company: '',
			HowWeCanHelp: '',
			Country: '',
		},
	});

	const onSubmitHandler = (data: ContactFormInputs) => {
		// console.log(process.env.REACT_APP_API_BASE_URL);
		setLoading(true);
		api
			.post('/contact', data)
			.then(() => {
				toast('Message sent successfully').success();
				setLoading(false);
				reset();
			})
			.catch(() => {
				toast('Something went wrong').error();
				setLoading(false);
			});
	};

	return (
		<Box bgColor={colorMode === 'light' ? 'white' : '#000'}>
			<Container
				maxW="1440px"
				p={{
					base: '41px 20px 83px',
					sm: '61px 50px 83px',
					md: '111px 103px 74px',
					lg: '111px 182px 74px',
				}}
			>
				<form onSubmit={handleSubmit(onSubmitHandler)}>
					<Flex
						fontFamily="bold"
						flexDir={{ base: 'column', md: 'row' }}
						gap={5}
						justify="space-between"
					>
						<Stack spacing="40px" pb={{ base: '55px', md: '0' }}>
							<Text
								fontWeight="700"
								fontSize={{ base: '24px', sm: '28px', md: '32px' }}
								color={colorMode === 'light' ? '#CBD5E1' : 'darkGray'}
								lineHeight={{ base: '28px', sm: '36px' }}
							>
								Email:
								<br />
								<Text
									as="span"
									fontWeight="700"
									fontSize={{ base: '24px', sm: '1.4rem' }}
									color={colorMode === 'light' ? 'black' : 'white'}
								>
									info@phasecurve.com
								</Text>
							</Text>

							<Text
								fontWeight="700"
								fontSize={{ base: '24px', sm: '28px', md: '32px' }}
								color={colorMode === 'light' ? '#CBD5E1' : 'darkGray'}
								lineHeight={{ base: '28px', sm: '36px' }}
							>
								Phone:
								<br />
								<Text
									as="span"
									fontWeight="700"
									fontSize={{ base: '1rem', sm: '1.4rem' }}
									color={colorMode === 'light' ? 'black' : 'white'}
								>
									+234 809 422 1930
								</Text>
							</Text>
						</Stack>

						<Stack spacing="27px">
							<HStack spacing="20px">
								<Box w="100%">
									<CustomInput
										control={control}
										name="FullName"
										type="text"
										w={{ base: '100%', md: '259px' }}
										height={'63px'}
										placeholder={'Full Name'}
									/>
								</Box>
								<Box w="100%">
									<CustomInput
										control={control}
										name="EmailAddress"
										type="email"
										w={{ base: '100%', md: '259px' }}
										height={'63px'}
										placeholder={'Email Address'}
									/>
								</Box>
							</HStack>
							<HStack spacing="20px">
								<Box w="100%">
									<CustomInput
										control={control}
										name="Company"
										type="text"
										w={{ base: '100%', md: '259px' }}
										height={'63px'}
										placeholder={'Company'}
									/>
								</Box>
								<Box w="100%">
									<CustomSelectField
										control={control}
										name="Country"
										w={{ base: '100%', md: '259px' }}
										height={'63px'}
										label="Country"
										options={countries}
										placeholder="Select Country"
									/>
								</Box>
							</HStack>
							<Box pb="13px" w="100%">
								<CustomInput
									control={control}
									name="HowWeCanHelp"
									type="text"
									w="100%"
									maxW={{ base: '100%', md: '624px' }}
									height={'63px'}
									placeholder={'How can we help you?'}
								/>
							</Box>

							<Box>
								<CustomBtn
									text={'Get In touch'}
									width={{ base: '171px', sm: '175px' }}
									height={'60px'}
									color={'white'}
									bg={'purple'}
									isLoading={loading}
									type="submit"
								/>
							</Box>
						</Stack>
					</Flex>
				</form>
			</Container>
		</Box>
	);
};

export default ContactForm;
