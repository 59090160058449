/* eslint-disable prettier/prettier */

import { Box, Container, Flex, Heading, Stack, Text, useColorMode, Image } from "@chakra-ui/react";
import CustomBtn from "components/common/CommonBtn";
import React from "react";
import { Link } from 'react-router-dom';
import Career from '../../assets/image/career/career2.jpg';
import { stribe } from "assets/svgs/service";

const Intro = () => {
  const { colorMode } = useColorMode();
  return (
		<Box bgColor={colorMode === 'light' ? 'white' : '#000'}>
			<Container
				maxW="144rem"
				p={{
					base: '45px 40px 30px 40px',
					sm: '65px 50px 55px 50px',
					md: '87px 52px 95px 52px',
					lg: '101px 72px 104px 72px',
				}}
        pb={{
          base: 0, md: 0
        }}
			>
				<Flex
					fontFamily="bold"
					// align="center"
					flexDir={{ base: 'column', md: 'row' }}
					justify="space-between"
					position="relative"
				>
					<Stack w={{ base: '100%', sm:'100%', md: '50%' }} mb={{ base: '53px', md: 0 }} mt="-1.3rem">
						<Heading
							as="h2"
							mb={{ base: '37px', md: '39px' }}
							lineHeight={{ base: '48px', sm: '67px', lg: '75px' }}
							fontWeight={700}
							fontSize={{ base: '34px', sm: '56px' }}
						>
							Be a part of the 
							<Heading
								as="span"
								fontWeight={700}
								fontSize={{ base: '34px', sm: '56px' }}
								lineHeight={{ base: '48px', sm: '67px', lg: '75px' }}
								color="purple"
							>
								<br/>Phasecurve Team
							</Heading>
						</Heading>
						<Text
							color={colorMode === 'light' ? 'gray' : 'white'}
							fontSize={{ base: '16px', sm: '20px' }}
							maxW={{base: '100%', md:'550px'}}
							pb={{ base: '38px', md: '33px' }}
						>
							Join the Phasecurve pioneer community, forge new frontiers in
							technology, and leave your mark on the industry. Step into the
							future with visionary innovators, leading the way in technological
							advancements. Discover limitless possibilities as a key member of
							our esteemed pioneer cohort.
						</Text>
						<Link to="/careersform">
							<CustomBtn
								bg="purple"
								color="white"
								height="60px"
								width={{ base: '167px', sm: '168px' }}
								text="Join Us"
							/>
						</Link>
					</Stack>

					<Box w={{ base: '100%', md: '50%' }} >
						<Image
							zIndex={5}
							borderRadius="40px"
							src={Career}
							alt="phasecurve image"
						/>
					</Box>
        
					<Box pos="absolute" top={{ base: -50, sm: 10, md: 100 }} left={0}>
						{stribe}
					</Box>
				</Flex>
			</Container>
		</Box>
	);
}

export default Intro;