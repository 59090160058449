import React, { FC, useState, useEffect } from 'react';
import {
	Flex,
	useColorMode,
	Container,
	Box,
	Stack,
	HStack,
	Heading,
	Image,
	Checkbox,
} from '@chakra-ui/react';
import CustomBtn from 'components/common/CommonBtn';
import TrainingEclipse from 'assets/image/services/training-eclipse.webp';
import { serviceDot, stribe } from './../../assets/svgs/service';
import CustomInput from 'components/common/CustomInputB';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import api from 'lib/axios';
import toast from 'components/common/toast';
import { useSearchParams, useNavigate } from 'react-router-dom';

type CourseSignUpT = {
	firstName: string;
	lastName: string;
	emailAddress: string;
	company: string;
	phoneNumber: string;
};

const schema = object().shape({
	firstName: string().required('Your First Name is Required'),
	lastName: string().required('Your Last Name is Required'),
	emailAddress: string().email().required('Email is Required'),
	company: string().required('Company is Required'),
	phoneNumber: string().min(11).max(14).required('Phone Number is Required'),
});

const SignUpTraining: FC = () => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const { colorMode } = useColorMode();
	const [loading, setLoading] = useState(false);
	const [languagesCheckbox, setLanguagesCheckbox] = useState<any>({
		java: false,
		javaScript: false,
		cSharp: false,
		typeScript: false,
		python: false,
		fSharp: false,
	});
	const [languageNotSelected, setLanguageNotSelected] = useState(false);

	const handleCheckbox = (e: any) => {
		if (languageNotSelected) setLanguageNotSelected(false);

		setLanguagesCheckbox((prevValues: any) => ({
			...prevValues,
			[e.target.name]: e.target.checked,
		}));
	};

	const { handleSubmit, control, reset } = useForm<CourseSignUpT>({
		resolver: yupResolver(schema),
		defaultValues: {
			firstName: '',
			lastName: '',
			emailAddress: '',
			company: '',
			phoneNumber: '',
		},
	});

	const getLanguagesAsString = () => {
		const filtered = Object.keys(languagesCheckbox).filter(function (key) {
			return languagesCheckbox[key];
		});

		return filtered.toString();
	};

	const onSubmithandler = (data: CourseSignUpT) => {
		if (!Object.values(languagesCheckbox).some((item) => item)) {
			setLanguageNotSelected(true);
			return;
		}

		setLoading(true);

		api
			.post('/corporatetraining', {
				...data,
				languages: getLanguagesAsString(),
				duration: searchParams.get('duration'),
			})
			.then(() => {
				toast('Form submitted successfully').success();
				setLoading(false);
				reset();
				setLanguageNotSelected(false);
				setLanguagesCheckbox({
					java: false,
					javaScript: false,
					cSharp: false,
					typeScript: false,
					python: false,
					fSharp: false,
				});
			})
			.catch(() => {
				toast('Something went wrong').error();
				setLoading(false);
			});
	};

	useEffect(() => {
		if (!searchParams.get('duration')) {
			navigate('/services');
		}
	}, []);

	return (
		<Box bgColor={colorMode === 'light' ? 'white' : '#000'}>
			<Container
				maxW="1440px"
				p={{
					base: '48px 20px 74px 20px',
					sm: '58px 50px 74px 50px',
					md: '78px 103px 74px 103px',
					lg: '78px 182px 74px 186px',
				}}
				pos="relative"
			>
				<form onSubmit={handleSubmit(onSubmithandler)} noValidate>
					<Flex fontFamily="bold" flexDir="column" pos="relative">
						<Heading
							fontWeight="700"
							fontSize={{ base: '40px', sm: '48px' }}
							lineHeight={{ base: '50.54px', lg: '60px' }}
							maxW="269px"
							pb={{ base: '104px', md: '80px' }}
						>
							Phasecurve Training
						</Heading>

						<Stack spacing="29px" maxW="874px" pb="61px">
							<HStack spacing={{ base: '12px', sm: '20px', md: '29px' }}>
								<Box w="100%">
									<CustomInput
										width={{ base: '100%', md: '423px' }}
										padding="20px 15px"
										height="63px"
										control={control}
										name="firstName"
										type="text"
										placeholder={'First Name'}
									/>
								</Box>
								<Box w="100%">
									<CustomInput
										width={{ base: '100%', md: '423px' }}
										padding="20px 15px"
										height="63px"
										control={control}
										name="lastName"
										type="text"
										placeholder={'Last Name'}
									/>
								</Box>
							</HStack>
							<Box w="100%">
								<CustomInput
									width={{ base: '100%', md: '874px' }}
									padding="20px 15px"
									height="63px"
									control={control}
									name="emailAddress"
									type="email"
									placeholder={'Email Address'}
								/>
							</Box>
							<HStack spacing={{ base: '12px', sm: '20px', md: '29px' }}>
								<Box w="100%">
									<CustomInput
										width={{ base: '100%', md: '423px' }}
										padding="20px 15px"
										height="63px"
										control={control}
										name="company"
										type="text"
										placeholder={'Company'}
									/>
								</Box>
								<Box w="100%">
									<CustomInput
										width={{ base: '100%', md: '423px' }}
										padding="20px 15px"
										height="63px"
										control={control}
										name="phoneNumber"
										type="tel"
										placeholder={'Phone Number'}
									/>
								</Box>
							</HStack>
						</Stack>

						<Flex flexWrap="wrap" gap="38px" maxW="699px" pb="67px">
							<Checkbox
								isInvalid={languageNotSelected}
								border="#94A3B8"
								name="java"
								isChecked={languagesCheckbox.java}
								onChange={handleCheckbox}
							>
								Java
							</Checkbox>
							<Checkbox
								isInvalid={languageNotSelected}
								border="#94A3B8"
								name="javaScript"
								isChecked={languagesCheckbox.javaScript}
								onChange={handleCheckbox}
							>
								JavaScript
							</Checkbox>
							<Checkbox
								isInvalid={languageNotSelected}
								border="#94A3B8"
								name="cSharp"
								isChecked={languagesCheckbox.cSharp}
								onChange={handleCheckbox}
							>
								C#
							</Checkbox>
							<Checkbox
								isInvalid={languageNotSelected}
								border="#94A3B8"
								name="typeScript"
								isChecked={languagesCheckbox.typeScript}
								onChange={handleCheckbox}
							>
								TypeScript
							</Checkbox>
							<Checkbox
								isInvalid={languageNotSelected}
								border="#94A3B8"
								name="python"
								isChecked={languagesCheckbox.python}
								onChange={handleCheckbox}
							>
								Python
							</Checkbox>
							<Checkbox
								isInvalid={languageNotSelected}
								border="#94A3B8"
								name="fSharp"
								isChecked={languagesCheckbox.fSharp}
								onChange={handleCheckbox}
							>
								F#
							</Checkbox>
							{languageNotSelected && (
								<Box
									style={{
										fontSize: '0.875rem',
										color: '#C53030',
										position: 'absolute',
										bottom: '105px',
									}}
								>
									Please select language(s) of choice
								</Box>
							)}
						</Flex>
						<CustomBtn
							text={'Get In touch'}
							width="fit-content"
							maxW={{ base: '171px', sm: '175px' }}
							height={'60px'}
							color={'white'}
							bg={'purple'}
							type="submit"
							isLoading={loading}
						/>
					</Flex>
				</form>

				<Box
					display={{ base: 'none', md: 'block' }}
					pos="absolute"
					top={{ base: '15%', md: '50%' }}
					left={35}
				>
					{serviceDot}
				</Box>
			</Container>
			<Box
				pos="absolute"
				top={{ base: 130, md: 150, lg: 200 }}
				zIndex={3}
				right={0}
			>
				<Image
					src={TrainingEclipse}
					w="100%"
					maxW={{ base: '150px', md: '170px', lg: '194px' }}
				/>
			</Box>
			<Box pos="absolute" top={{ base: -50, sm: 10, md: 100 }} left={0}>
				{stribe}
			</Box>
		</Box>
	);
};

export default SignUpTraining;
