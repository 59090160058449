import React, { FC } from 'react';
import {
	Text,
	Heading,
	Flex,
	Stack,
	HStack,
	Button,
	Icon,
} from '@chakra-ui/react';
import { HiCheck } from 'react-icons/hi';
import { RxCaretRight } from 'react-icons/rx';
import { Link } from 'react-router-dom';

type Props = {
	title: string;
	trainingColor: string;
	height: string;
	bgColor?: string;
	bgGradient?: string;
	hrsColor: string;
	courseColor: string;
	iconColor: string;
	btnBg: string;
	btnColor: string;
	borderRadius: string;
	duration: string;
};

const TrainingCard: FC<Props> = ({
	title,
	hrsColor,
	btnBg,
	btnColor,
	trainingColor,
	bgColor,
	courseColor,
	bgGradient,
	iconColor,
	height,
	borderRadius,
	duration,
}) => {
	return (
		<Flex
			flexDir="column"
			align="center"
			justify="center"
			p=" 40px 35px"
			w={{ base: '100%', md: '303px', lg: '338px' }}
			maxW={{ base: '408px', md: '338px' }}
			h={height}
			borderRadius={borderRadius}
			border="1px solid #E2E8F0"
			fontFamily="bold"
			transition="0.2s ease"
			_hover={{
				transform: 'scale(1.02)',
				boxShadow: '0px 4px 50px rgba(0, 0, 0, 0.15)',
			}}
			bgColor={bgColor}
			bgGradient={bgGradient}
		>
			<Text
				maxW="200px"
				lineHeight="25px"
				textAlign="center"
				pb="10px"
				fontSize="20px"
				color={trainingColor}
			>
				Corporate Engineering Training
			</Text>

			<Stack spacing="5px" color={hrsColor} pb="40px">
				<Heading fontSize="48px" fontWeight="700">
					{title}
				</Heading>
				<Text fontSize="14px" letterSpacing="1px" textAlign="center">
					COURSE
				</Text>
			</Stack>

			<Stack spacing="5px" pb="45px" fontSize="20px">
				<HStack spacing="35px">
					<Icon as={HiCheck} color={iconColor} />
					<Text color={courseColor}> Java </Text>
				</HStack>
				<HStack spacing="35px">
					<Icon as={HiCheck} color={iconColor} />
					<Text color={courseColor}> C#</Text>
				</HStack>
				<HStack spacing="35px">
					<Icon as={HiCheck} color={iconColor} />
					<Text color={courseColor}> JavaScript </Text>
				</HStack>
				<HStack spacing="35px">
					<Icon as={HiCheck} color={iconColor} />
					<Text color={courseColor}> TypeScript</Text>
				</HStack>
				<HStack spacing="35px">
					<Icon as={HiCheck} color={iconColor} />
					<Text color={courseColor}> Python</Text>
				</HStack>
				<HStack spacing="35px">
					<Icon as={HiCheck} color={iconColor} />
					<Text color={courseColor}> F#</Text>
				</HStack>
			</Stack>

			<Link to={`/services/sign-up?duration=${duration}`}>
				<Button
					bg={btnBg}
					borderRadius="100px"
					fontSize={{ base: '12px', md: '14px', lg: '16px' }}
					fontFamily="light"
					fontWeight="700"
					color={btnColor}
					w="142px"
					h="60px"
					_hover={{
						cursor: 'pointer',
						boxShadow: '0px 92px 40px -59px rgba(108, 76, 223, 0.15)',
						transform: 'scale(1.03)',
					}}
				>
					<HStack spacing="10px">
						<Text>Sign Up</Text>
						<Icon as={RxCaretRight} boxSize={5} />
					</HStack>
				</Button>
			</Link>
		</Flex>
	);
};

export default TrainingCard;
