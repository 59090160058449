import { Box } from '@chakra-ui/react';
import Intro from 'components/home/Intro';
import { FC } from 'react';
import Newsletter from './../components/home/Newsletter';
import Client from './../components/home/Clients';
import Sdlc from './../components/home/Sdlc';
import Wuc from './../components/home/Wuc';
const Home: FC = () => {
	return (
		<Box>
			<Intro />
			<Sdlc />
			<Wuc />
			<Client />
			<Newsletter />
		</Box>
	);
};

export default Home;
