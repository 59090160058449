import React, { FC } from 'react';
import serviceBanner from '../../assets/image/services/service-banner.webp';
import {
	Box,
	Container,
	Flex,
	Heading,
	Image,
	Stack,
	Text,
	useColorMode,
} from '@chakra-ui/react';
import { stribe } from 'assets/svgs/service';
import { logoDotWhite, logoDot } from 'assets/svgs/about';

const Intro: FC = () => {
	const { colorMode } = useColorMode();
	return (
		<Box bgColor={colorMode === 'light' ? 'white' : '#000'} position="relative">
			<Container
				maxW="1440px"
				px={{ base: '20px', sm: '50px', md: '69px' }}
				pt={{ base: '61px', md: '91px' }}
				pb={{ base: '32px', md: '57px' }}
			>
				<Flex flexDir="column" fontFamily="bold">
					<Stack
						spacing={{ base: '25px', sm: '36px', md: '48px' }}
						pb={{ base: '97px', md: '118px' }}
						px={{ base: '0', md: '35px', lg: '113px' }}
					>
						<Text
							as="p"
							fontWeight="700"
							letterSpacing="4px"
							color={colorMode === 'light' ? '#94A3B8' : 'darkGray'}
						>
							OUR SERVICES
						</Text>

						<Heading
							maxW="689px"
							as="h2"
							fontSize={{ base: '36px', sm: '40px', md: '48px' }}
							lineHeight={{ base: '48.6px', md: '50.54px', lg: '60px' }}
							fontWeight="700"
						>
							<Heading
								as="span"
								color={'purple'}
								fontSize={{ base: '36px', sm: '40px', md: '48px' }}
								lineHeight={{ base: '48.6px', md: '50.54px', lg: '60px' }}
								fontWeight="700"
							>
								Phasecurve{' '}
							</Heading>
							delivers sustainable software solutions that improve development
							team performance
						</Heading>

						<Box
							display={{ base: 'none', md: 'unset' }}
							pos="absolute"
							top={117}
							right={{ md: '3%', lg: '10%' }}
						>
							{colorMode === 'light' ? logoDot : logoDotWhite}
						</Box>
					</Stack>
					<Image
						src={serviceBanner}
						alt="service-img"
						objectFit="cover"
						h={{ base: '208px', sm: '296px', md: '458px' }}
						borderRadius={{ base: '30px', md: '40px' }}
					/>
				</Flex>
			</Container>
			<Box pos="absolute" top={0} left={0}>
				{stribe}
			</Box>
		</Box>
	);
};

export default Intro;
