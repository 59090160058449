import React, { FC } from 'react';
import {
	Box,
	Container,
	Heading,
	Stack,
	VStack,
	Text,
	HStack,
	Image,
	Flex,
	useColorMode,
} from '@chakra-ui/react';
import project from 'assets/image/services/project.webp';
import project1 from 'assets/image/services/project1.webp';
import project2 from 'assets/image/services/project2.webp';
import hero from 'assets/image/home/hero.png';
import { Stribe } from 'assets/svgs/community';

const SingleProject: FC = () => {
	const { colorMode } = useColorMode();
	return (
		<Box bgColor={colorMode === 'light' ? '#fff' : '#000'} fontFamily="bold">
			<Container
				maxW="1440px"
				pt={{ base: '44px', md: '68px' }}
				pb={{ base: '62px', md: '76px' }}
				px={{ base: '20px', sm: '50px', md: '72px' }}
				bgImage={colorMode === 'light' ? hero : 'none'}
				bgRepeat="no-repeat"
			>
				<Stack>
					<VStack
						justify="flex-start"
						alignItems="start"
						px={{ base: '0', md: '31px', lg: '110px' }}
					>
						<Heading
							fontWeight="700"
							fontSize={{ base: '24px', sm: '40px', md: '48px' }}
							pb={{ base: '18px', md: '31px' }}
						>
							Project Name Here
						</Heading>
						<Text
							fontWeight="400"
							fontSize={{ base: '16px', md: '20px' }}
							lineHeight={{ base: '24px', md: '30px' }}
							maxW={{ base: '370px', sm: '679px', md: '854px' }}
							pb={{ base: '38px', md: '31px' }}
							color={colorMode === 'light' ? '#64748B' : 'darkgray'}
						>
							Bushwick post-ironic af fixie, wayfarers kombucha direct trade air
							plant meditation palo santo asymmetrical salvia blue bottle. 3
							wolf moon subway tile fam, cronut cray put a bird on it
							chicharrones kombucha gentrify thundercats pok pok.
						</Text>
						<HStack>
							<Text
								fontWeight="700"
								fontSize={{ base: '16px', md: '20px' }}
								lineHeight={{ base: '24px', md: '30px' }}
								pr={{ base: '47px', md: '36px' }}
							>
								Client
							</Text>
							<Text
								fontWeight="400"
								fontSize={{ base: '16px', md: '20px' }}
								lineHeight={{ base: '24px', md: '30px' }}
								color={colorMode === 'light' ? '#64748B' : 'darkgray'}
							>
								John Doe Company
							</Text>
						</HStack>
						<HStack>
							<Text
								fontWeight="700"
								fontSize={{ base: '16px', md: '20px' }}
								lineHeight={{ base: '24px', md: '30px' }}
								pr="50px"
							>
								Year
							</Text>
							<Text
								fontWeight="400"
								fontSize={{ base: '16px', md: '20px' }}
								lineHeight={{ base: '24px', md: '30px' }}
								color={colorMode === 'light' ? '#64748B' : 'darkgray'}
							>
								January 2022
							</Text>
						</HStack>
					</VStack>
					<Box
						pt={{ base: '73px', md: '105px' }}
						pb={{ base: '42px', md: '105px' }}
						w="100%"
						maxW={{ base: '609px', sm: '847px', md: '1299px' }}
						minH={{ base: '270px', md: '416px' }}
						borderRadius="40px"
					>
						<Image src={project} alt="Our project" />
					</Box>
					<VStack
						px={{ base: '0', md: '31px', lg: '110px' }}
						maxW={{ base: '377px', sm: '679px', md: '859px', lg: '100%' }}
					>
						<Text
							fontWeight="400"
							fontSize={{ base: '16px', md: '20px' }}
							pb={{ base: '44px', md: '62px' }}
							lineHeight={{ base: '24px', md: '30px' }}
							color={colorMode === 'light' ? '#64748B' : 'darkgray'}
						>
							Bushwick post-ironic af fixie, wayfarers kombucha direct trade air
							plant meditation palo santo asymmetrical salvia blue bottle. 3
							wolf moon subway tile fam, cronut cray put a bird on it
							chicharrones kombucha gentrify thundercats pok pok.
						</Text>
						<Text
							fontWeight="400"
							fontSize={{ base: '16px', md: '20px' }}
							pb={{ base: '44px', md: '62px' }}
							lineHeight={{ base: '24px', md: '30px' }}
							color={colorMode === 'light' ? '#64748B' : 'darkgray'}
						>
							Bushwick post-ironic af fixie, wayfarers kombucha direct trade air
							plant meditation palo santo asymmetrical salvia blue bottle. 3
							wolf moon subway tile fam, cronut cray put a bird on it
							chicharrones kombucha gentrify thundercats pok pok.
						</Text>
					</VStack>
					<Flex
						flexDir={{ base: 'column', sm: 'row' }}
						px={{ base: '0', md: '31px', lg: '110px' }}
						gap={{ base: '27px', md: '41px' }}
						pt={{ base: '56px', md: '76px' }}
						pb={{ base: '44px', md: '81px' }}
					>
						<Box>
							<Image src={project1} alt="project one" />
						</Box>
						<Box>
							<Image src={project2} alt="project two" />
						</Box>
					</Flex>
					<Text
						fontWeight="400"
						fontSize={{ base: '16px', md: '20px' }}
						pb={{ base: '93px', md: '151px' }}
						px={{ base: '0', md: '31px', lg: '110px' }}
						lineHeight={{ base: '24px', md: '30px' }}
						maxW={{ base: '370px', sm: '679px', md: '859px', lg: '100%' }}
						color={colorMode === 'light' ? '#64748B' : 'darkgray'}
					>
						Bushwick post-ironic af fixie, wayfarers kombucha direct trade air
						plant meditation palo santo asymmetrical salvia blue bottle. 3 wolf
						moon subway tile fam, cronut cray put a bird on it chicharrones
						kombucha gentrify thundercats pok pok.
					</Text>
					<HStack justifyContent="space-between" pb={{ base: '0', md: '76px' }}>
						<Heading
							fontWeight="700"
							fontSize={{ base: '24px', md: '40px' }}
							lineHeight={{ base: '25px', md: '42.12px' }}
						>
							PREVIOUS
						</Heading>
						<Heading
							fontWeight="700"
							fontSize={{ base: '24px', md: '40px' }}
							lineHeight={{ base: '25px', md: '42.12px' }}
						>
							NEXT
						</Heading>
					</HStack>
					<Box
						display={{ base: 'none', md: 'unset' }}
						position="absolute"
						top="95px"
						left={0}
					>
						{Stribe}
					</Box>
				</Stack>
			</Container>
		</Box>
	);
};

export default SingleProject;
