import React, { FC } from 'react';
import {
	Flex,
	Heading,
	useColorMode,
	Container,
	Box,
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
} from '@chakra-ui/react';

const Faqs: FC = () => {
	const { colorMode } = useColorMode();
	return (
		<Box bgColor={colorMode === 'light' ? 'white' : '#000'}>
			<Container
				id="faqs"
				p={{
					base: '45px 20px 77px 20px',
					sm: '35px 50px 87px 50px',
					md: '73px 103px 200px 103px',
					lg: '73px 182px 220px 168px',
				}}
				maxW="1440px"
			>
				<Flex flexDir="column" fontFamily="bold" align="center" w="full">
					<Heading
						as="h2"
						fontSize={{ base: '32px', sm: '40px', md: '48px' }}
						lineHeight={{ base: '40px', md: '50.54px', lg: '60px' }}
						fontWeight="700"
						pb="83px"
						textAlign="center"
						w={{ base: '100%', md: '684px' }}
					>
						FAQs <br /> (Frequently Asked Questions)
					</Heading>

					<Box w={{ sm: '620px', md: '1000px', lg: '1090px' }}>
						<Accordion defaultIndex={[0]} w="100%">
							<AccordionItem borderTop={0}>
								<Heading as="h2">
									<AccordionButton py="25px">
										<Box as="span" flex="1" textAlign="left" fontWeight="700">
											How do I enrol for a training?
										</Box>
										<AccordionIcon />
									</AccordionButton>
								</Heading>
								<AccordionPanel
									pb={4}
									fontSize="14px"
									color={colorMode === 'light' ? 'gray' : 'darkGray'}
								>
									Navigate to the Programme page through the navigation or
									footer links, then click on the 'Enrol Now' button.
								</AccordionPanel>
							</AccordionItem>

							<AccordionItem borderTop="1px solid #CBD5E1">
								<Heading as="h2">
									<AccordionButton py="25px">
										<Box as="span" flex="1" textAlign="left" fontWeight="700">
											How do I see a new blog post?
										</Box>
										<AccordionIcon />
									</AccordionButton>
								</Heading>
								<AccordionPanel
									pb={4}
									fontSize="14px"
									color={colorMode === 'light' ? 'gray' : 'darkGray'}
								>
									Go to our communities section of our website, our blogs are
									updated quarterly.
								</AccordionPanel>
							</AccordionItem>

							<AccordionItem borderTop="1px solid #CBD5E1">
								<Heading as="h2">
									<AccordionButton py="25px">
										<Box as="span" flex="1" textAlign="left" fontWeight="700">
											Do I need to login before I can enrol for a training?
										</Box>
										<AccordionIcon />
									</AccordionButton>
								</Heading>
								<AccordionPanel
									pb={4}
									fontSize="14px"
									color={colorMode === 'light' ? 'gray' : 'darkGray'}
								>
									No, you can enrol directly as a first time user.
								</AccordionPanel>
							</AccordionItem>

							<AccordionItem borderTop="1px solid #CBD5E1">
								<Heading as="h2">
									<AccordionButton py="25px">
										<Box as="span" flex="1" textAlign="left" fontWeight="700">
											How much do your service cost?
										</Box>
										<AccordionIcon />
									</AccordionButton>
								</Heading>
								<AccordionPanel
									pb={4}
									fontSize="14px"
									color={colorMode === 'light' ? 'gray' : 'darkGray'}
								>
									Our pricing varies depending on the scope of the project. We
									provide transparent pricing and will work with you to find a
									solution that fits your budget.
								</AccordionPanel>
							</AccordionItem>

							<AccordionItem borderTop="1px solid #CBD5E1">
								<Heading as="h2">
									<AccordionButton py="25px">
										<Box as="span" flex="1" textAlign="left" fontWeight="700">
											How long does a project take?
										</Box>
										<AccordionIcon />
									</AccordionButton>
								</Heading>
								<AccordionPanel
									pb={4}
									fontSize="14px"
									color={colorMode === 'light' ? 'gray' : 'darkGray'}
								>
									The timeline for a project varies depending on the scope and
									complexity of the project. We provide estimated timelines
									during the consultation phase and keep you updated on progress
									throughout the project.
								</AccordionPanel>
							</AccordionItem>

							<AccordionItem borderTop="1px solid #CBD5E1">
								<Heading as="h2">
									<AccordionButton py="25px">
										<Box as="span" flex="1" textAlign="left" fontWeight="700">
											Do you offer ongoing support?
										</Box>
										<AccordionIcon />
									</AccordionButton>
								</Heading>
								<AccordionPanel
									pb={4}
									fontSize="14px"
									color={colorMode === 'light' ? 'gray' : 'darkGray'}
								>
									Yes, we offer ongoing support for all of our services. We're
									committed to ensuring that our clients are satisfied with our
									work long after the project is completed.
								</AccordionPanel>
							</AccordionItem>

							<AccordionItem
								borderTop="1px solid #CBD5E1"
								borderBottom="1px solid #CBD5E1"
							>
								<Heading as="h2">
									<AccordionButton py="25px">
										<Box as="span" flex="1" textAlign="left" fontWeight="700">
											Do you offer any guarantees?
										</Box>
										<AccordionIcon />
									</AccordionButton>
								</Heading>
								<AccordionPanel
									pb={4}
									fontSize="14px"
									color={colorMode === 'light' ? 'gray' : 'darkGray'}
								>
									Yes, we offer a satisfaction guarantee. If you're unsatisfied
									with our work, we'll work with you to make it right.
								</AccordionPanel>
							</AccordionItem>
						</Accordion>
					</Box>
				</Flex>
			</Container>
		</Box>
	);
};

export default Faqs;
