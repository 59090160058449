import { FC } from 'react';
import {
	Stack,
	Text,
	Box,
	Flex,
	Icon,
	Container,
	Image,
	useColorMode,
} from '@chakra-ui/react';
import CustomBtn from 'components/common/CommonBtn';
import { Hstribe } from 'assets/svgs/programme';
import { BiCalendar } from 'react-icons/bi';
import { MdOutlineModelTraining, MdOutlineMail } from 'react-icons/md';
import { logoDot, logoDotWhite } from 'assets/svgs/about';
import avatar from '../../assets/image/programme/mark.webp';
import { Link } from 'react-router-dom';

const Intro: FC = () => {
	const { colorMode } = useColorMode();
	return (
		<Box bgColor={colorMode === 'light' ? 'white' : '#000'} position="relative">
			<Container
				maxW="1440px"
				px={{ base: '20px', sm: '50px', md: '69px' }}
				pt={{ base: '45px', sm: '55px', md: '78px' }}
				pb="20px"
			>
				<Stack
					spacing={{ base: '20px', sm: '30px' }}
					px={{ base: '0', md: '35px', lg: '113px' }}
				>
					<Text
						as="span"
						maxW="742px"
						color="#6C4CDF"
						lineHeight={{ base: '48px', md: '50px', lg: '60px' }}
						fontWeight="700"
						fontSize={{ base: '36px', sm: '40px', md: '48px' }}
					>
						TechElite:{' '}
						<Text as="span" display={{ base: 'unset', sm: 'none' }}>
							<br />
						</Text>
						<Text
							as="span"
							color={colorMode === 'light' ? 'black' : 'white'}
							lineHeight={{ base: '44px', md: '50px', lg: '60px' }}
							fontWeight="700"
							fontSize={{ base: '36px', sm: '40px', md: '48px' }}
						>
							An Advanced Software Engineering Programme
						</Text>
					</Text>

					<Text
						fontFamily="bold"
						pb={{ base: '15px', sm: '0' }}
						fontWeight="400"
						fontSize={{ base: '20px', sm: '24px' }}
						color={colorMode === 'light' ? 'black' : 'white'}
					>
						Learn, Collaborate, Upgrade
					</Text>
					<Flex
						justify="flex-start"
						align="flex-start"
						flexWrap="wrap"
						gap="10px"
					>
						<Stack
							py="20px"
							px="20px"
							borderRadius="20px"
							direction="row"
							justify="flex-start"
							fontSize={{ base: '16px', sm: '20px' }}
							align="center"
							background={colorMode === 'light' ? '#F1F5F9' : 'darkBg'}
						>
							<Box color={colorMode === 'light' ? 'black' : 'white'}>
								<Icon as={BiCalendar} />
							</Box>
							<Text
								fontFamily="bold"
								// lineHeight="1.05"
								fontWeight="400"
								color={colorMode === 'light' ? 'black' : 'white'}
							>
								5 Months
							</Text>
						</Stack>
						<Stack
							py="20px"
							px="20px"
							borderRadius="20px"
							direction="row"
							fontSize={{ base: '16px', sm: '20px' }}
							justify="flex-start"
							align="center"
							background={colorMode === 'light' ? '#F1F5F9' : 'darkBg'}
						>
							<Box color={colorMode === 'light' ? 'black' : 'white'}>
								<Icon as={MdOutlineModelTraining} />
							</Box>
							<Text
								fontFamily="bold"
								lineHeight="1.05"
								fontWeight="400"
								color={colorMode === 'light' ? 'black' : 'white'}
							>
								Physical/Virtual
							</Text>
						</Stack>
						<Stack
							py="20px"
							px="20px"
							borderRadius="20px"
							direction="row"
							fontSize={{ base: '16px', sm: '20px' }}
							justify="flex-start"
							align="center"
							background={colorMode === 'light' ? '#F1F5F9' : 'darkBg'}
						>
							<Box color={colorMode === 'light' ? 'black' : 'white'}>
								<Icon as={MdOutlineMail} />
							</Box>
							<Text
								fontFamily="bold"
								lineHeight="1.05"
								fontWeight="400"
								color={colorMode === 'light' ? 'black' : 'white'}
							>
								info@phasecurve.com
							</Text>
						</Stack>
					</Flex>
					<Stack
						direction="row"
						justify="flex-start"
						align="center"
						spacing="10px"
						pt={{ base: '15px', sm: '0' }}
						pb={{ base: '25px', sm: '0' }}
					>
						<Box>
							<Image src={avatar} w="40px" alt="mark" />
						</Box>
						<Text
							fontFamily="bold"
							fontWeight="400"
							fontSize="16px"
							color={colorMode === 'light' ? 'black' : 'white'}
						>
							Mark Gray
						</Text>
					</Stack>
					<Link to="enrol_programme">
						<CustomBtn
							bg="purple"
							color="white"
							height="60px"
							width={{ base: '165px', sm: '167px' }}
							text="Enrol Now"
						/>
					</Link>
					<Box
						position="absolute"
						top="15px"
						left={0}
						display={{ base: 'none', md: 'unset' }}
					>
						{Hstribe}
					</Box>
					<Box
						display={{ base: 'none', md: 'unset' }}
						position="absolute"
						bottom={200}
						right={{ base: '126px', xl: '10%' }}
					>
						{colorMode === 'light' ? logoDot : logoDotWhite}
					</Box>
				</Stack>
			</Container>
		</Box>
	);
};

export default Intro;
