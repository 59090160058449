import { FC } from 'react';
import mark from '../../assets/image/about/mark.webp';
import {
	Box,
	Container,
	Flex,
	Heading,
	Stack,
	Image,
	Text,
	useColorMode,
} from '@chakra-ui/react';

const WhoWeAre: FC = () => {
	const { colorMode } = useColorMode();
	return (
		<Box bgColor={colorMode === 'light' ? 'white' : '#000'}>
			<Container
				maxW="1440px"
				px={{ base: '21px', sm: '50px', md: '72px' }}
				pb="93px"
			>
				<Flex
					flexDir="column"
					align="center"
					justify="center"
					fontFamily="bold"
				>
					<Flex
						px={{ base: '27px', sm: '48px', md: '72px', lg: '114px' }}
						pt={{ base: '50px', md: '70px', lg: '100px' }}
						minH={{ base: '605px', md: '644px' }}
						flexDir={{ base: 'column', md: 'row' }}
						bgColor="purple"
						zIndex={3}
						borderRadius="40px"
						color="white"
					>
						<Heading
							maxW={{ base: '289px', sm: '405px', md: '388px' }}
							mb={{ base: '35px', md: '0px' }}
							fontSize={{ base: '24px', sm: '36px', md: '44px', lg: '48px' }}
							fontWeight="700"
							lineHeight={{ base: '29px', sm: '48px', md: '60.54px' }}
						>
							Building innovative and sustainable solutions
						</Heading>
						<Stack spacing="23px">
							<Text
								fontWeight="700"
								letterSpacing="2px"
								fontSize={{ base: '14px', md: '16px' }}
							>
								WHO WE ARE
							</Text>
							<Text
								maxW="651px"
								fontSize={{ base: '16px', md: '18px', lg: '20px' }}
								lineHeight={{ base: '24px', sm: '30px' }}
							>
								We specialise in providing both digital solutions and IT
								training to support individuals and organisations. Our digital
								solutions are designed to meet the ever-evolving needs of the
								industry, while our training ensures that individuals have the
								necessary skills and knowledge to utilise technology in their
								daily lives with a commitment to excellence and innovation, we
								strive to set the pace and raise the bar for IT services in the
								industry.
							</Text>
						</Stack>
					</Flex>

					<Flex
						flexDir="column"
						align="center"
						px={{ base: '15px', sm: '20px', md: '72px', lg: '113px' }}
						mx={{ base: '30px', sm: '57px' }}
						mt={{ base: '-20%', sm: '-15%' }}
						pt={{ base: '50px', md: '43px' }}
						minH={{ base: '281px', md: '311px' }}
						bgColor="sky"
						zIndex={3}
						borderRadius="40px"
						color="black"
					>
						<Text
							fontSize={{ base: '16px', md: '28px', lg: '36px' }}
							lineHeight={{ base: '30px', md: '43.67px' }}
							textAlign="center"
							fontWeight="200"
							mb={{ base: '22px', lg: '34px' }}
						>
							<b>
								“Our mission is to make lives better. We want to provide real
								jobs that boosts people’s self esteem and have an enjoyable
								career.”
							</b>
						</Text>
						<Stack spacing="26px" mb="-50px">
							<Text fontSize={{ base: '16px', md: '20px' }} color="navy">
								<b> Mark Gray</b>
							</Text>
							<Image w="104px" src={mark} alt="Mark Gray" />
						</Stack>
					</Flex>
				</Flex>
			</Container>
		</Box>
	);
};

export default WhoWeAre;
