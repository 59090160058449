import { FC } from 'react';
import Mission from '../../assets/image/about/mission.jpg';
import Vision from '../../assets/image/about/vision.webp';
import {
	Box,
	Container,
	Heading,
	Flex,
	Image,
	Text,
	useColorMode,
} from '@chakra-ui/react';
const MissionVision: FC = () => {
	const { colorMode } = useColorMode();
	return (
		<Box bgColor={colorMode === 'light' ? 'white' : '#000'}>
			<Container
				maxW="1440px"
				px={{ base: '20px', sm: '50px', md: '103px', lg: '182px' }}
				pt={{ base: '85px', md: '93px' }}
				pb={{ base: '38px', md: '99px' }}
			>
				<Flex flexDir="column">
					<Flex
						justifyContent="space-between"
						flexDir={{ base: 'column', sm: 'row' }}
						gap={{ base: '0', sm: '20px', md: '40px', lg: '60px' }}
					>
						<Box w={{ base: '100%', sm: '50%' }} pb={{ base: '58px', sm: '0' }}>
							<Heading
								fontSize={{ base: '36px', sm: '34px', md: '48px' }}
								fontWeight="700"
								lineHeight={{ base: '44px', md: '50.54px' }}
								maxWidth={{ base: '100%', sm: '486px' }}
								pb={{ base: '85px', md: '115px' }}
								textAlign={{ base: 'center', sm: 'unset' }}
							>
								{' '}
								Join us in bringing your
								<Heading
									as="span"
									color="purple"
									fontSize={{ base: '36px', sm: '34px', md: '48px' }}
									fontWeight="700"
									px="10px"
								>
									ideas to life
								</Heading>
								and making a difference.
							</Heading>

							<Heading
								fontSize={{ base: '28px', md: '32px' }}
								fontWeight="700"
								pb="23px"
								textAlign={{ base: 'center', sm: 'unset' }}
							>
								Mission
							</Heading>
							<Text
								fontWeight="400"
								fontSize={{ base: '16px', md: '20px' }}
								color={colorMode === 'light' ? 'gray' : 'darkGray'}
								lineHeight={{ base: '28px', md: '30px' }}
								maxWidth="498px"
								textAlign={{ base: 'center', sm: 'unset' }}
								pb="71px"
							>
								We are on a mission to transform the experience of young
								talents; to hire them as apprentices each year, taking them from
								“zero to hero” developers.
							</Text>
							<Box maxW={{ base: '100%', sm: '508px' }}>
								<Image
									src={Mission}
									borderRadius="40px"
									w="100%"
									objectFit="cover"
								/>
							</Box>
						</Box>

						<Box w={{ base: '100%', sm: '50%' }}>
							<Heading
								fontSize={{ base: '28px', md: '32px' }}
								fontWeight="700"
								textAlign={{ base: 'center', sm: 'unset' }}
								pb="23px"
							>
								Vision
							</Heading>
							<Text
								fontWeight="400"
								fontSize={{ base: '16px', md: '20px' }}
								color={colorMode === 'light' ? 'gray' : 'darkGray'}
								lineHeight={{ base: '28px', md: '30px' }}
								maxWidth="498px"
								pb="50px"
								textAlign={{ base: 'center', sm: 'unset' }}
							>
								We desire to be an organisation that invests in the personal
								growth of people in the organisation and in the community.
							</Text>

							<Box maxW={{ base: '100%', sm: '508px' }}>
								<Image
									src={Vision}
									w="100%"
									maxH="621px"
									borderRadius="40px"
									objectFit="cover"
								/>
							</Box>
						</Box>
					</Flex>
				</Flex>
			</Container>
		</Box>
	);
};

export default MissionVision;
