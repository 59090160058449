import {
	Box,
	Container,
	VStack,
	Text,
	Heading,
	Grid,
	GridItem,
	useColorMode,
	Flex,
} from '@chakra-ui/react';
// import singleEvent from '../../../assets/image/events/singleEventsCard.webp';
import { FC, useState, useEffect } from 'react';
import { EventList } from 'data/EventList';
import EventCard from '../EventCard';
import { Link, useParams } from 'react-router-dom';
import Nopage from './../../../pages/Nopage';
// eslint-disable-next-line import/no-extraneous-dependencies

const SingleEvent: FC = () => {
	const { colorMode } = useColorMode();
	const { id } = useParams();
	const [event, setEvent] = useState<any | null>(null);

	useEffect(() => {
		const evenT = EventList.find((item) => item.id === id);

		if (evenT) {
			setEvent(evenT);
		}
	}, [id]);

	return (
		<Box
			bgColor={colorMode === 'light' ? '#fff' : '#000'}
			position="relative"
			fontFamily="bold"
		>
			<Container
				maxW="1440px"
				px={{ base: '20px', sm: '50px', md: '72px' }}
				pt="40.41px"
				pb={{ base: '82px', md: '119px' }}
			>
				{event ? (
					<VStack>
						<Flex
							flexDir="column"
							justify="center"
							bgImage={event.bgImg}
							bgPos={{
								base: 'right 35% top 45%',
								sm: 'center top 85%',
								md: 'center top 95%',
								lg: 'right 35% top 95%',
							}}
							bgSize="cover"
							bgRepeat="no-repeat"
							borderRadius="20px"
							w="100%"
							minH="286px"
							pl={{ base: '30px', md: '78px' }}
							// maxW="1296px"
							boxShadow="20px"
						>
							<Text
								fontWeight="400"
								fontSize="14px"
								pb="13px"
								color={event.cardTextColor}
							>
								{`${event.date} - ${event.title}`}
							</Text>
							<Heading
								maxW={{ base: '232px', sm: '360px', md: '450px' }}
								fontWeight="700"
								fontSize={{ base: '20px', sm: '28px', md: '36px' }}
								lineHeight={{ base: '40px', sm: '46px', md: '53.8px' }}
								color={event.cardTextColor}
							>
								<Heading
									as="span"
									color={event.titleColor}
									fontWeight="700"
									fontSize={{ base: '20px', sm: '28px', md: '36px' }}
								>
									{`${event.title}: `}
								</Heading>
								{event.desc}
							</Heading>
						</Flex>
						<Box
							px={{ base: '0', md: '31px', lg: '110px' }}
							pb={{ base: '91px', md: '119px' }}
							pt={{ base: '35px', sm: '40px', md: '72px' }}
						>
							<Text
								fontWeight="400"
								fontSize={{ base: '16px', md: '20px' }}
								color={colorMode === 'light' ? '#000' : 'darkgray'}
							>
								<Box
									dangerouslySetInnerHTML={{
										__html: event.summary1,
									}}
								></Box>
								<br />
								<Box
									dangerouslySetInnerHTML={{
										__html: event.summary2,
									}}
								></Box>
								<br />
								<Box
									dangerouslySetInnerHTML={{
										__html: event.summary3,
									}}
								></Box>
								<br />
								<Box
									dangerouslySetInnerHTML={{
										__html: event.summary4,
									}}
								></Box>{' '}
							</Text>
						</Box>
					</VStack>
				) : (
					<Nopage />
				)}

				<Box>
					<Heading
						fontWeight="700"
						fontSize={{ base: '32px', md: '36px' }}
						pb={{ base: '45px', md: '72px' }}
						textAlign="center"
					>
						Events you might like
					</Heading>

					<Grid
						templateColumns={{
							base: 'repeat(1, 1fr)',
							sm: 'repeat(2, 1fr)',
							md: 'repeat(3, 1fr)',
						}}
						gap="30px"
					>
						{EventList.slice(0, 3).map((item) => (
							<GridItem w="100%" key={item.id}>
								<Link to={`/community/event/${item.id}`}>
									<EventCard
										id={item.id}
										title={item.title}
										titleColor={item.titleColor}
										desc={item.desc}
										descColor={item.descColor}
										linkText={item.linkText}
										cardTextColor={item.cardTextColor}
										bgImg={item.bgImg}
										date={item.date}
									/>
								</Link>
							</GridItem>
						))}
					</Grid>
				</Box>
			</Container>
		</Box>
	);
};

export default SingleEvent;
