/* eslint-disable prettier/prettier */

import { Box, Flex, Heading, Text, Container,  Input, useColorMode } from '@chakra-ui/react'
import React from 'react'

import NewsletterImg from '../../assets/image/home/newsletter-img.webp'
import darkNewsletterImg from '../../assets/image/home/darkmode/dark-newsletter.webp'

// import { starBox } from 'assets/svgs/careers';
import { Link } from 'react-router-dom';
import CommonBtn from 'components/common/CommonBtn';

const Newsletter = () => {
  const {colorMode} = useColorMode() 
  return (

    <Box bgColor={colorMode === 'light' ? 'none' : '#000'}>
			<Container
				maxW="1440px"
				px={{ base: '30px', sm: '50px', md: '72px' }}
				pt={{ base: '31px', md: '77px' }}
			>
				<Flex
					flexDir="column"
					justify="center"
					align="center"
					borderRadius="40px"
					minHeight={{ base: '447px', md: '591px' }}
					textAlign="center"
					fontFamily="bold"
					px="20px"
					bgImage={colorMode === 'light' ? NewsletterImg : darkNewsletterImg}
					bgSize="cover"
					bgPos="center"
					bgRepeat="no-repeat"
				>
					<Heading
						maxW="840px"
						pb="30px"
						textAlign="center"
						lineHeight={{ base: '30px', md: '44px' }}
						color="white"
						fontWeight="700"
						fontSize={{ base: '24px', md: '40px' }}
					>
						
						Sign up for job alerts.
					</Heading>

          <Text 
          color="#fff" 
          pb="30px"
          fontWeight="500"
          textAlign="center"
          fontSize={{ base: '14px', sm:'16px', md: '20px' }}
          lineHeight={{ base: '24px', md: '32px' }}
          >
            Join our Talent Community, and get job alerts from
            <Text as="span" color="#7ED8FF">
              <br /> Phasecurve Technologies
            </Text>{' '}
            delivered to our inbox.
          </Text>

					<Flex
						flexDir={{ base: 'column', md: 'row' }}
						justify="center"
						align="center"
					>
						<Flex direction="column" position="relative">
							<Input
								color="black"
								h="60px"
								focusBorderColor="#6c4cdf"
								fontWeight="700"
								fontSize={{ base: '16px', md: '20px' }}
								borderRadius="100px"
								maxW="286px"
								bgColor="white"
								placeholder="Email Address"
								_placeholder={{ color: 'black', fontWeight: '700' }}
								size="lg"
								type="email"
								
							></Input>
						</Flex>
						<Box
							pt={{ base: '15px', md: '0px' }}
							pl={{ base: '0px', md: '7px' }}
						>
              <Link to="mailto:info@phasecurve.com">
							<CommonBtn
								text={'Sign Up'}
								width={{ base: '286px', md: '166px' }}
								height={'60px'}
								color={'white'}
								bg={'#6c4cdf'}
								
							/>
              </Link>
						</Box>
					</Flex>
         
				</Flex>
			</Container>
		</Box>
  )
}

export default Newsletter;