import {
	Input,
	Text,
	useColorMode,
	InputProps,
	FormControl,
	InputGroup,
	InputRightElement,
	FormErrorMessage,
} from '@chakra-ui/react';
import React, { FC, useState } from 'react';

type Props = {
	height: string;
	padding: string;
	type: string;
	placeholder: string;
	loading?: boolean;
	error?: string;
	readOnly?: boolean;
	icon?: React.ReactElement | any;
	required?: boolean;
	disabled?: boolean;
} & InputProps;
const CustomInput: FC<Props> = ({
	placeholder,
	padding,
	height,
	type,
	onChange,
	required,
	disabled,
	error,
	readOnly,
	icon,
	...rest
}) => {
	const [active, setActive] = useState(false);
	const { colorMode } = useColorMode();

	const handleChange = (event: any) => {
		if (event.target.value !== '') {
			setActive(true);
		} else {
			setActive(false);
		}
		if (onChange) {
			onChange(event);
		}
	};

	return (
		<FormControl
			pos="relative"
			zIndex={5}
			isRequired={required}
			isDisabled={disabled}
			isInvalid={!!error}
			isReadOnly={readOnly}
		>
			<InputGroup>
				<Input
					placeholder={placeholder}
					_placeholder={{ color: '#94A3B8', fontSize: '16px' }}
					fontSize="14px"
					type={type}
					h={height}
					p={padding}
					borderRadius="10px"
					_hover={{ border: '2px solid #6C4CDF' }}
					focusBorderColor="#6C4CDF"
					border="1px solid #94A3B8"
					transition="0.7 ease-in-out"
					onChange={handleChange}
					{...rest}
				/>
				{icon && <InputRightElement>{icon}</InputRightElement>}
			</InputGroup>

			<Text
				display={active ? 'inline-block' : 'none'}
				p="1px 3px"
				borderRadius="5px"
				fontSize="12px"
				color={colorMode === 'light' ? 'gray' : 'darkGray'}
				bg={colorMode === 'light' ? 'white' : 'black'}
				pos="absolute"
				zIndex={5}
				top="-10px"
				left={3}
			>
				{placeholder}
			</Text>

			{error && (
				<FormErrorMessage mt="1.5" color="red.600" fontSize="sm">
					{error}
				</FormErrorMessage>
			)}
		</FormControl>
	);
};
export default CustomInput;
