import {
	Heading,
	Stack,
	Image,
	Text,
	Card,
	CardBody,
	CardFooter,
	Box,
} from '@chakra-ui/react';
import { FC, useState } from 'react';

type Props = {
	title: string;
	personName: string;
	img: string;
	alt: string;
	desc: string;
};
const TeamsCard: FC<Props> = ({ title, alt, img, personName, desc }) => {
	// const { colorMode } = useColorMode();
	const [hover, setHover] = useState(false);

	const handleMouseEnter = () => {
		setHover(true);
	};
	const handleMouseLeave = () => {
		setHover(false);
	};
	return (
		<Card
			maxW="358px"
			p="10px"
			borderTopRadius="50px"
			fontFamily="bold"
			border="0px solid none"
			transition="0.5s ease"
			boxShadow={hover ? '0px 4px 50px rgba(0, 0, 0, 0.15)' : 'none'}
			borderBottomRadius={hover ? '50px' : '0px'}
			zIndex={hover ? '5' : 'auto'}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<Box bgColor="purple" borderRadius="40px" pt="20px">
				<Image boxSize="100%" borderRadius="40px" src={img} alt={alt} />
			</Box>
			<CardBody p={0}>
				<Stack mt="26px" spacing="9px">
					<Heading as="h4" fontSize="24px" fontWeight="700" textAlign="center">
						{personName}
					</Heading>
					<Text textAlign="center">{title}</Text>
				</Stack>
			</CardBody>
			<CardFooter display={'none'} className="footer">
				<Text textAlign="center">{desc}</Text>
			</CardFooter>
		</Card>
	);
};

export default TeamsCard;
