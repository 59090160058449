import Home from './pages/Home';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import About from './pages/About';
import Programme from './pages/Programme';
import Community from './pages/Community';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Header from 'layout/Header';
import { Footer } from 'layout/Footer';
import Nopage from './pages/Nopage';
import EnrolProgramme from './components/programme/EnrolProgramme';
import SignUpTraining from './components/services/SignUpTraning';
import ScrollToTop from './components/ScrollTop';
import SingleProject from 'components/services/SingleProject';
import Teams from './components/about/TeamPage';
import Faqs from './components/contact/Faqs';
import PrivacyPolicy from 'components/PrivacyPolicy';
import { Helmet } from 'react-helmet';
import SingleEvent from 'components/community/Event/SingleEvent';
import EventPage from 'components/community/Event/EventPage';
import Careers from 'pages/Careers';
import CareersForm from 'pages/CareersForm';
import Portfolio from 'pages/Portfolio';

function App() {
	return (
		<BrowserRouter>
			<Header />
			<Helmet>
				<title>Phasecurve</title>
				<meta
					name="description"
					content="Transform Your Ideas Into Innovative Technology Solutions With Our Team"
				/>
				<meta name="keywords" content="Innovative Technology, SDLC" />
			</Helmet>
			<ScrollToTop />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/about" element={<About />} />
				<Route path="/our-team" element={<Teams />} />
				<Route path="/programme" element={<Programme />} />
				<Route path="/community" element={<Community />} />
				<Route path="/community/events" element={<EventPage />} />
				<Route path="/community/event/:id" element={<SingleEvent />} />
				<Route path="/programme/enrol_programme" element={<EnrolProgramme />} />
				<Route path="/services" element={<Services />} />
				<Route path="/services/sign-up" element={<SignUpTraining />} />
				<Route path="/services/single-project" element={<SingleProject />} />
				<Route path="/contact" element={<Contact />} />
				<Route path="/contact/faqs" element={<Faqs />} />
				<Route path="/portfolio" element={<Portfolio />} />
				<Route path="/careers" element={<Careers />} />
				<Route path="/careersform" element={<CareersForm />} />
				<Route path="/privacy-policy" element={<PrivacyPolicy />} />
				<Route path="*" element={<Nopage />} />
			</Routes>
			<Footer />
		</BrowserRouter>
	);
}

export default App;
