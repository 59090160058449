import mark from '../assets/image/about/teams/mark-crop.png';
import yomi from '../assets/image/about/teams/yomi-crop.png';
import mercy from '../assets/image/about/teams/mercy-crop.png';
import abiola from '../assets/image/about/teams/abiola-crop.png';
import fortune from '../assets/image/about/teams/fortune-crop.png';
import greg from '../assets/image/about/teams/greg-crop.png';
import segun from '../assets/image/about/teams/segun-crop.png';
import rofiat from '../assets/image/about/teams/rofiat-crop.png';
import joshua from '../assets/image/about/teams/joshua-crop.png';
import ezra from '../assets/image/about/teams/ezra-crop.png';
import martins from '../assets/image/about/teams/martins.png';
export const TeamList = [
	{
		id: 1,
		name: 'Mark Gray',
		post: 'Director',
		img: mark,
		description: 'lorem stuff',
	},
	{
		id: 2,
		name: 'Abidoye Oriyomi',
		post: 'General Manager',
		img: yomi,
		description: 'lorem stuffs',
	},
	{
		id: 3,
		name: 'Mercy Ibe',
		post: 'Content Writer',
		img: mercy,
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
	},
	{
		id: 4,
		name: 'Adeoye Abiola',
		post: 'Developer',
		img: abiola,
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
	},
	{
		id: 5,
		name: 'Ellah Fortune',
		post: 'Developer',
		img: fortune,
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
	},
	{
		id: 6,
		name: 'Gregory Otokhina',
		post: ' Developer',
		img: greg,
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
	},
	{
		id: 7,
		name: 'Abiodun Segun',
		post: 'Developer',
		img: segun,
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
	},
	{
		id: 8,
		name: 'Hassan Rofiat',
		post: 'Developer',
		img: rofiat,
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
	},
	{
		id: 9,
		name: 'Joshua Oti',
		post: 'Developer',
		img: joshua,
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
	},
	{
		id: 10,
		name: 'Ezra Ogianyo',
		post: 'Tester',
		img: ezra,
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
	},
	{
		id: 11,
		name: 'Anene Martin',
		post: 'Social Media',
		img: martins,
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
	},
];
