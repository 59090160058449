import { FC } from 'react';
import TeamsCard from './TeamsCard';
import { TeamList } from './../../data/Teams';
import {
	Box,
	Container,
	Flex,
	Grid,
	GridItem,
	Heading,
	Text,
	useColorMode,
} from '@chakra-ui/react';
import CustomBtn from 'components/common/CommonBtn';
import { Link } from 'react-router-dom';

const Teams: FC = () => {
	const { colorMode } = useColorMode();
	return (
		<Box bgColor={colorMode === 'light' ? 'white' : '#000'}>
			<Container
				maxW="1440px"
				pt={{ base: '35px', sm: '55px', md: '83px' }}
				px={{ base: '20px', sm: '50px', md: '72px' }}
				pb={{ base: '35px', sm: '55px', md: '98px' }}
			>
				<Flex
					flexDir="column"
					align="center"
					justify="center"
					fontFamily="bold"
				>
					<Text
						as="p"
						pb={{ base: '28px', sm: '35px', md: '48px' }}
						fontWeight="700"
						letterSpacing="4px"
						color={colorMode === 'light' ? '#94A3B8' : 'darkGray'}
					>
						OUR TEAM
					</Text>

					<Heading
						textAlign="center"
						maxW="855px"
						as="h2"
						pb={{ base: '30px', md: '27px' }}
						fontSize={{ base: '32px', sm: '40px', md: '48px' }}
						lineHeight={{ base: '40px', sm: '44px', md: '60.54px' }}
						fontWeight="700"
					>
						<Heading
							as="span"
							color={'purple'}
							fontSize={{ base: '32px', sm: '40px', md: '48px' }}
							lineHeight={{ base: '48px', md: '60.54px' }}
							fontWeight="700"
						>
							Meet our expert{' '}
						</Heading>
						creators, designers and world class problem solvers.
					</Heading>

					<Text
						maxW="791px"
						fontSize={{ base: '16px', md: '20px' }}
						textAlign="center"
						pb={{ base: '46px', md: '73px' }}
						color={colorMode === 'light' ? 'black' : 'darkGray'}
					>
						At Phasecurve, We pride ourselves in having a team of skilled
						experts/individuals who are passionate about technology. Our team
						includes developers, designers, project managers, marketers and
						trainers dedicated to delivering high-quality work.
					</Text>

					<Grid
						templateColumns={{
							base: 'repeat(1, 1fr)',
							sm: 'repeat(2, 1fr)',
							md: 'repeat(3, 1fr)',
						}}
						gap={{ base: 5, md: 2 }}
					>
						{TeamList.slice(0, 6).map((item) => (
							<GridItem w="100%" key={item.id}>
								<TeamsCard
									title={item.post}
									personName={item.name}
									img={item.img}
									alt={item.name}
									desc={item.description}
								/>
							</GridItem>
						))}
					</Grid>
					<Box mt="30px">
						<Link to="/our-team">
							<CustomBtn
								text={'Meet all experts'}
								height={'50px'}
								width="100px"
								_hover={{
									opacity: 0.9,
									transform: 'scale(1.04)',
								}}
								fontSize={'24px'}
								color={'purple'}
								bg={'white'}
							/>
						</Link>
					</Box>
				</Flex>
			</Container>
		</Box>
	);
};

export default Teams;
