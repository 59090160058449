import { FC, useState } from 'react';
import {
	Box,
	Container,
	Flex,
	HStack,
	Stack,
	IconButton,
	Text,
	useColorMode,
} from '@chakra-ui/react';
import { FaSun, FaMoon } from 'react-icons/fa';
import { RxHamburgerMenu } from 'react-icons/rx';
import { MdOutlineClose } from 'react-icons/md';
import { Link, NavLink } from 'react-router-dom';
import { phasecurve, phasecurveLight } from './../assets/svgs/header';

const Header: FC = () => {
	const { colorMode, toggleColorMode } = useColorMode();
	const [isMobile, setIsMobile] = useState('none');
	const closeMenu = () => setIsMobile('none');

	return (
		<Box
			as="nav"
			bg={colorMode === 'light' ? 'transparent' : '#000'}
			borderBottom={{ base: '1px solid #eded', lg: 'none' }}
		>
			<Container
				maxW="1440px"
				zIndex={3}
				p={{
					base: '35px 20px',
					sm: '40px 50px',
					md: '40px 52px',
					lg: '40px 72px',
				}}
			>
				<Flex fontFamily="bold" align="center" justify="space-between">
					{colorMode === 'light' ? (
						<Box>
							<Link to="/">{phasecurve}</Link>
						</Box>
					) : (
						<Box>
							<Link to="/">{phasecurveLight}</Link>
						</Box>
					)}
					<HStack
						spacing={{ base: '15px', md: '20px', lg: '34px' }}
						fontSize={{ md: '14px' }}
						display={{ base: 'none', md: 'flex', lg: 'flex' }}
					>
						<NavLink to="/about">
							<Text
								transition="0.2s ease-in-out"
								_hover={{
									color: 'purple',
								}}
							>
								About Us
							</Text>
						</NavLink>
						<NavLink to="/programme">
							<Text transition="0.2s ease-in-out" _hover={{ color: 'purple' }}>
								Programme
							</Text>
						</NavLink>

						<NavLink to="/services">
							<Text transition="0.2s ease-in-out" _hover={{ color: 'purple' }}>
								Our Services
							</Text>
						</NavLink>
						<NavLink to="/portfolio">
							<Text transition="0.2s ease-in-out" _hover={{ color: 'purple' }}>
								Portfolio
							</Text>
						</NavLink>
						<NavLink to="/community">
							<Text transition="0.2s ease-in-out" _hover={{ color: 'purple' }}>
								Community
							</Text>
						</NavLink>
						<NavLink to="/contact">
							<Text transition="0.2s ease-in-out" _hover={{ color: 'purple' }}>
								Contact Us
							</Text>
						</NavLink>
						<IconButton
							onClick={() => toggleColorMode()}
							icon={colorMode === 'light' ? <FaMoon /> : <FaSun />}
							aria-label="Color mode"
							isRound={true}
						/>
					</HStack>

					<Box display={{ base: 'block', md: 'none', lg: 'none' }}>
						<IconButton
							_hover={{ bgColor: '#f4f4f4', color: '#0A0A0A' }}
							onClick={() => setIsMobile('flex')}
							aria-label="hamburger menu"
							transition="0.5s ease-in-out"
							size="md"
							fontSize="28px"
							icon={<RxHamburgerMenu />}
						/>
					</Box>

					<Flex
						bgColor={colorMode === 'light' ? 'purple' : ' darkBg'}
						pos="fixed"
						top={0}
						left={0}
						h="100vh"
						w="full"
						zIndex={20}
						overflowY="auto"
						transition="0.8s ease-in-out"
						justify="center"
						flexDir="column"
						display={isMobile}
					>
						<Box
							mt="30px"
							ml="30px"
							pos="absolute"
							top={0}
							left={0}
							onClick={() => {
								if (isMobile === 'flex') closeMenu();
							}}
						>
							<Link to="/">{phasecurveLight}</Link>
						</Box>
						<Flex
							mt="30px"
							mr="30px"
							pos="absolute"
							top={0}
							right={0}
							color="black"
						>
							<IconButton
								onClick={() => setIsMobile('none')}
								icon={<MdOutlineClose />}
								transition="0.5s ease-in-out"
								size="md"
								bgColor="#f4f4f4"
								_hover={{ bgColor: '#dbd9d9' }}
								fontSize="24px"
								aria-label="close button"
								isRound={true}
							/>
						</Flex>
						<Stack
							spacing="25px"
							// alignItems="center"
							fontWeight="600"
							color="white"
							px="30px"
						>
							<Text
								onClick={() => {
									if (isMobile === 'flex') closeMenu();
								}}
								transition="0.5s ease-in-out"
								_hover={{ textDecoration: 'underline' }}
							>
								<Link to="/">Home</Link>
							</Text>
							<Text
								onClick={() => {
									if (isMobile === 'flex') closeMenu();
								}}
								transition="0.5s ease-in-out"
								_hover={{ textDecoration: 'underline' }}
							>
								<Link to="/about">About Us</Link>
							</Text>
							<Text
								onClick={() => {
									if (isMobile === 'flex') closeMenu();
								}}
								transition="0.5s ease-in-out"
								_hover={{ textDecoration: 'underline' }}
							>
								<Link to="/programme">Programme</Link>
							</Text>

							<Text
								onClick={() => {
									if (isMobile === 'flex') closeMenu();
								}}
								transition="0.5s ease-in-out"
								_hover={{ textDecoration: 'underline' }}
							>
								<Link to="/services">Our Services</Link>
							</Text>
							<Text
								onClick={() => {
									if (isMobile === 'flex') closeMenu();
								}}
								transition="0.5s ease-in-out"
								_hover={{ textDecoration: 'underline' }}
							>
								<Link to="/portfolio">Portfolio</Link>
							</Text>

							<Text
								onClick={() => {
									if (isMobile === 'flex') closeMenu();
								}}
								transition="0.5s ease-in-out"
								_hover={{ textDecoration: 'underline' }}
							>
								<Link to="/community">Community</Link>
							</Text>
							<Text
								onClick={() => {
									if (isMobile === 'flex') closeMenu();
								}}
								transition="0.5s ease-in-out"
								_hover={{ textDecoration: 'underline' }}
							>
								<Link to="/contact">Contact Us</Link>
							</Text>
							<Box>
								<IconButton
									onClick={() => toggleColorMode()}
									icon={colorMode === 'light' ? <FaMoon /> : <FaSun />}
									aria-label="Color mode"
									isRound={true}
								/>
							</Box>
						</Stack>
					</Flex>
				</Flex>
			</Container>
		</Box>
	);
};

export default Header;
