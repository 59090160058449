import Redfox from '../assets/image/portfolio/redfox.png';
import Rocason from '../assets/image/portfolio/rocason.png';
import Canine from '../assets/image/portfolio/ecoToilet.png';
import Vokes from '../assets/image/portfolio/vokes.png';

export const PortfolioList = [
	{
		id: 0,
		img: Canine,
		name: 'Canine Eco Toilets',
		bg: '#22490383',
		Description: `<p><strong style="color: #444;letter-spacing:1px;">Canine Eco Toilets</strong> - Our collaborative approach combined product design aspects to create an interactive exhibition with instructional information. The website's adaptable design improves accessibility, offering it a comprehensive platform for promoting this creative and eco-friendly solution to a worldwide audience.  <a href="https://canineecotoilets.co.uk/" target="_blank" style="color: #0000EE; text-decoration:underline">View website</a></p>`,
	},
	{
		id: 1,
		img: Rocason,
		name: 'Rocason Pharmacy',
		bg: '#2e318f8f',
		Description: `<p><strong style="color: #444;letter-spacing:1px;">Rocason Pharmacy</strong> -  This project highlights our dedication to delivering innovative solutions that align with evolving business needs, contributing to Rocason Pharmacy's digital success. <a href="https://rocasonpharmacy.com/" target="_blank" style="color: #0000EE; text-decoration:underline">View website</a></p>`,
	},

	{
		id: 2,
		img: Vokes,
		name: "Voke's Mobile Spa",
		bg: '#65432160',
		Description: `<p><strong style="color: #444;letter-spacing:1px;">Voke Mobile Spa</strong> - Our company spearheaded the development of the Voke Spa website, a project showcasing our expertise in frontend design and cloud-based application development.  <a href="https://www.vokesmobilespa.com/" target="_blank" style="color: #0000EE; text-decoration:underline">View website</a></p>`,
	},
	{
		id: 3,
		img: Redfox,
		name: 'Redfox Accounting',
		bg: '#a1151a5a',
		Description: `<p><strong style="color: #444;letter-spacing:1px;">Red Fox Accounting</strong> - This project showcases the accounting firm's services which includes tax preparation, auditing, consulting, financial planning, and more. The website details the specifics of each service, highlighting their expertise and specialisation.  <a href="https://www.redfoxaccounting.co.uk" target="_blank" style="color: #0000EE; text-decoration:underline;">View website</a></p>`,
	},
];
